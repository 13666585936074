import { FC } from 'react';
import { Box, BoxProps, Icon, Tooltip } from '@chakra-ui/react';
import { FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { AddressStatus } from '@netiva/classifieds-api';

export type VerificationStatusProps = BoxProps & {
  status?: AddressStatus;
};

export const VerificationStatus: FC<VerificationStatusProps> = ({ status, ...rest }) => {
  const { t } = useTranslation();

  if (!status || status === 'Verified') {
    return null;
  }

  return (
    <Box padding={1} {...rest}>
      {/* @ts-expect-error dynamic translation */}
      <Tooltip label={t(`myads.addressStatus.${status?.toLowerCase()}`)} placement="top" fontSize="md" hasArrow>
        <Box tabIndex={0}>
          <Icon
            as={status === 'New' ? FaExclamationTriangle : FaInfoCircle}
            boxSize={6}
            display="block"
            color="orange"
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
