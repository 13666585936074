import { FC } from 'react';
import { Box, BoxProps, Icon, Tooltip } from '@chakra-ui/react';
import { FaArrowCircleUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export type TopListingStatusProps = BoxProps & {
  isTopListed: boolean;
};

export const TopListingStatus: FC<TopListingStatusProps> = ({ isTopListed, ...rest }) => {
  const { t } = useTranslation();
  return (
    isTopListed && (
      <Box padding={1} {...rest}>
        <Tooltip label={t('myads.isTopListed')} placement="top" fontSize="md" hasArrow>
          <Box tabIndex={0}>
            <Icon as={FaArrowCircleUp} boxSize={6} display="block" color="primary" />
          </Box>
        </Tooltip>
      </Box>
    )
  );
};
