import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { AccountPublicationPublicationOptionListResponseProduct } from '@netiva/classifieds-api';

import { useCurrencyFormatter, useProductGroups } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { CardSection } from './CardSection';
import { ProductCard } from './ProductCard';

type Product = AccountPublicationPublicationOptionListResponseProduct;

export type ExtensionProductListProps = {
  products: Product[];
};

export const ExtensionProductList: FC<ExtensionProductListProps> = ({ products }) => {
  const { t } = useTranslation();
  const currencyFormatter = useCurrencyFormatter();
  const dispatch = useAppDispatch();
  const { selectedExtensionProductIds } = useAppSelector((state) => state.ad);

  const { orderedExtensionProductGroups } = useProductGroups(products);

  const handleExtensionProductClick = useCallback(
    (id: number, group?: string | null) => {
      if (!selectedExtensionProductIds.includes(id)) {
        dispatch(adActions.selectExtensionProductId(id));

        // only one selection per group is permitted; deselect all products of the same group
        if (group) {
          products
            .filter((ext) => (ext.id !== id && ext.group) === group)
            .forEach((ext) => dispatch(adActions.deselectExtensionProductId(ext.id)));
        }
      } else {
        dispatch(adActions.deselectExtensionProductId(id));
      }
    },
    [dispatch, products, selectedExtensionProductIds]
  );

  if (!products.length) {
    return null;
  }

  return (
    <Box mt={8}>
      <Heading size="md" my={4}>
        {t('ad.steps.publishing.extensionProducts')}
      </Heading>
      {orderedExtensionProductGroups.map(([group, products]) => (
        <Fragment key={group}>
          <VStack align="stretch" py={4}>
            <Heading size="sm">{t(`ad.steps.publishing.productGroups.${group}.title`, '')}</Heading>
            <Text>{t(`ad.steps.publishing.productGroups.${group}.description`, '')}</Text>
          </VStack>
          <CardSection>
            {products.map((p) => {
              const isSelected = selectedExtensionProductIds.includes(p.id);
              return (
                <ProductCard
                  key={p.id}
                  title={p.name}
                  subTitle={currencyFormatter.format(p.price)}
                  isSelected={isSelected}
                  onClick={() => handleExtensionProductClick(p.id, p.group)}
                  width={{ base: 'full', lg: '30%' }}
                >
                  {p.description && !p.description.startsWith('#') && (
                    <Box dangerouslySetInnerHTML={{ __html: p.description }} />
                  )}
                </ProductCard>
              );
            })}
          </CardSection>
        </Fragment>
      ))}
    </Box>
  );
};
