import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex } from '@chakra-ui/react';
import { Alert, Loader } from '@netiva/classifieds-ui';

import { usePayment } from '@/hooks';
import { routes } from '@/lib/routes';
import { useAdNavigation, useAdParams } from '@/pages/Ad/hooks';
import { AdComponentType } from '@/pages/Ad/types';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { CommonStepKeys } from '@/pages/Ad/constants';

export const Payment: AdComponentType = () => {
  const { t } = useTranslation();
  const { stepId: orderId } = useAdParams();
  const navigate = useNavigate();
  const { gotoStep } = useAdNavigation();
  const dispatch = useAppDispatch();
  const { paymentProvider, selectedContractId, selectedProductId } = useAppSelector((state) => state.ad);

  const onCancelled = useCallback(() => {
    gotoStep(CommonStepKeys.review, true);
  }, [gotoStep]);

  const { alertData, order, status, isLoading, callbackState, createPayment } = usePayment({
    orderId,
    paymentProvider,
    onCancelled,
  });

  // restore selection from order
  useEffect(() => {
    if (order && !selectedContractId && !selectedProductId) {
      dispatch(adActions.setSelectedContractId(order.contractId || undefined));
      dispatch(adActions.setSelectedProductId(order.mainProductId || undefined));
      dispatch(
        adActions.setSelectedExtensionProductIds(
          order.items.map((i) => i.productId).filter((id) => id !== order.mainProductId)
        )
      );
    }
  }, [dispatch, order, selectedContractId, selectedProductId]);

  const handleReturnToList = () => {
    navigate(routes.myAds());
  };

  return (
    <Box position="relative">
      <Loader isLoading={isLoading} />

      <Alert alert={alertData} />

      <Flex marginTop={8} justify="center">
        {status === 'Complete' && (
          <Button onClick={handleReturnToList}>{t('ad.steps.payment.button.backToList')}</Button>
        )}
        {(callbackState === 'cancelled' || status === 'Cancelled' || status === 'Failed') && (
          <Button onClick={createPayment}>{t('ad.steps.payment.button.retry')}</Button>
        )}
      </Flex>
    </Box>
  );
};
