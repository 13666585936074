export const paths = {
  ACCOUNT: '/account',
  AD: '/ad/:id/:step/:stepId?',
  ADDRESSES: '/addresses',
  AUTH: '/auth/:action',
  HOME: '/',
  INVITATIONS: '/invitations',
  LANGUAGE: '/:lng/*',
  LOGIN: '/login',
  LOGINS: '/logins',
  LOGOUT: '/logout',
  MYADS: '/ads',
  NEWAD: '/ad',
  PAYMENT: '/payment',
  PAYMENT_OVERVIEW: 'overview/:contractId',
  PAYMENT_PROCESSING: 'processing/:orderId',
  PRINT_QUOTE: '/print-quote/:dataObjectId',
  PRODUCTS: '/products',
  RESET: '/reset',
} as const;
