import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  StackDivider,
  Text,
  VStack,
  useToast,
  Grid,
  GridItem,
  chakra,
} from '@chakra-ui/react';
import { accountApi } from '@netiva/classifieds-api';
import { getQueryValue, parseQueryValue } from '@netiva/classifieds-common';
import { DescriptionList, Loader, SelectableTag } from '@netiva/classifieds-ui';

import { AttributeDisplay, PrintPriceSummary } from '@/components/ui';
import { useDate } from '@/hooks';
import { routes } from '@/lib/routes';
import { isAfter } from 'date-fns';

const AsideTitle = chakra(Text, {
  baseStyle: {
    fontWeight: 'bold',
    mb: 2,
  },
});

export const PrintQuotePage: FC = memo(function PrintQuotePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const dataObjectId = parseQueryValue(params.dataObjectId, parseInt);
  const token = getQueryValue(searchParams.get('token'));
  const { formatDate } = useDate();
  const toast = useToast();
  const [selectedIssueIds, setSelectedIssueIds] = useState<number[]>([]);
  const [tosChecked, setTosChecked] = useState(false);

  const { data: quoteData, isLoading: isLoadingContract } = accountApi.useGetPrintQuote(
    dataObjectId ? { dataObjectId: dataObjectId!, token } : skipToken
  );
  const { data: issueDates, isLoading: isLoadingIssueDates } = accountApi.useGetPrintAdvertIssueDates(
    dataObjectId ? { dataObjectId: dataObjectId } : skipToken
  );
  const { data: attributes, isLoading: isLoadingAttributes } = accountApi.useGetAttributes(
    quoteData?.dataObject?.categoryId ? { categoryId: quoteData.dataObject.categoryId } : skipToken
  );
  const [acceptQuote, { isLoading: isSubmitting }] = accountApi.useAcceptPrintQuote();

  const futureIssueDates = useMemo(() => {
    const now = new Date();
    return issueDates?.dates.filter((d) => isAfter(new Date(d.closeDateTime), now));
  }, [issueDates?.dates]);

  // redirect to payment if there already are selected issue ids
  useEffect(() => {
    if (quoteData?.selectedIssueIds.length) {
      navigate(routes.paymentOverview(quoteData.contract.id, token));
    }
  }, [navigate, quoteData?.contract, quoteData?.selectedIssueIds.length, token]);

  const isActive = (issueId: number) => {
    return selectedIssueIds.includes(issueId);
  };

  const handleSelectIssueDate = (issueId: number) => {
    const newIssueIds = isActive(issueId)
      ? selectedIssueIds.filter((id) => id !== issueId)
      : [...selectedIssueIds, issueId];
    setSelectedIssueIds(newIssueIds);
  };

  const handleSubmit = async () => {
    if (!quoteData?.contract.id) {
      return;
    }

    if (selectedIssueIds.length === 0) {
      toast({
        status: 'error',
        description: t('printQuote.error.selectIssueDate'),
        isClosable: true,
        duration: 5000,
      });
      return;
    }

    if (!tosChecked) {
      toast({
        status: 'error',
        description: t('ad.steps.review.submitTosError'),
        isClosable: true,
        duration: 5000,
      });
      return;
    }
    try {
      await acceptQuote({ contractId: quoteData.contract.id, printTitleIssueIds: selectedIssueIds, token }).unwrap();
    } catch (err) {
      console.error(err);
      toast({ status: 'error', description: t('printQuote.error.submit'), isClosable: true });
      return;
    }

    navigate(routes.paymentOverview(quoteData.contract.id, token));
  };

  const isLoading = isLoadingContract || isLoadingIssueDates || isLoadingAttributes;

  return (
    <Box position="relative">
      <Loader isLoading={isLoading} />
      <Grid
        templateColumns={{
          base: '1fr',
          md: '2fr minmax(min-content, 1.1fr)',
        }}
        gap={8}
      >
        <GridItem>
          <Text mb={6} dangerouslySetInnerHTML={{ __html: t('ad.steps.issueDates.description') }} />
          <Flex wrap="wrap" gap={2}>
            {futureIssueDates?.map((date) => (
              <SelectableTag
                key={date.id}
                isSelected={isActive(date.id)}
                onClick={() => handleSelectIssueDate(date.id)}
              >
                {formatDate(date.date)}
              </SelectableTag>
            ))}
          </Flex>
        </GridItem>
        <GridItem>
          <AsideTitle>{t('preview.title')}</AsideTitle>
          <Image src={quoteData?.imageUrl || ''} maxWidth="500px" border="default" boxShadow="default" />
          <DescriptionList position="relative" mt={4}>
            {attributes?.attributes
              .filter((attribute) => attribute.key !== 'Price' && attribute.key !== 'Text')
              .map((attribute) => {
                const value = quoteData?.dataObject?.values.find((v) => v.attributeId == attribute.id);
                return value && <AttributeDisplay key={attribute.id} attribute={attribute} value={value} />;
              })}
          </DescriptionList>
          <PrintPriceSummary
            price={quoteData?.price}
            issueCount={selectedIssueIds.length}
            showMultipleIssues
            unified
            mt={4}
          />
        </GridItem>
      </Grid>

      <VStack align="stretch" divider={<StackDivider />} spacing={4} mt={8}>
        <Flex direction="column" align="flex-start" gap={4}>
          <Alert status="info" flexDirection="column">
            <AlertTitle>{t('ad.steps.review.guidelinesMessage.title')}</AlertTitle>
            <AlertDescription dangerouslySetInnerHTML={{ __html: t('ad.steps.review.guidelinesMessage.text') }} />
          </Alert>
          <Box>
            <Checkbox isChecked={tosChecked} onChange={(e) => setTosChecked(e.target.checked)} mt={4} isRequired>
              <span dangerouslySetInnerHTML={{ __html: t('ad.steps.review.tosText') }}></span>
            </Checkbox>
          </Box>
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme={tosChecked && selectedIssueIds.length ? undefined : 'neutral'}
          >
            {t('ad.steps.review.submit')}
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
});
