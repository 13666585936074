import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AdListSearchParams, FilterStatus } from '../types';

const setParam = <T>(params: URLSearchParams, name: string, value?: T) => {
  if (value) {
    params.set(name, `${value}`);
  } else {
    params.delete(name);
  }
};

export const useAdListSearchParams = (): [
  AdListSearchParams,
  (params: AdListSearchParams, replace?: boolean) => void,
] => {
  const [_searchParams, _setSearchParams] = useSearchParams();

  const searchParams = useMemo<AdListSearchParams>(
    () => ({
      pageIndex: parseInt(_searchParams.get('p') || '1') - 1,
      platform: _searchParams.get('platform') || '',
      status: (_searchParams.get('status') as FilterStatus) || 'active',
      query: _searchParams.get('q') || '',
      topListed: _searchParams.get('topListed') === 'true',
    }),
    [_searchParams]
  );

  const setSearchParams = useCallback(
    (params: AdListSearchParams, replace = false) => {
      const urlSearchParams = new URLSearchParams();
      if (params.pageIndex && params.pageIndex > 0) {
        setParam(urlSearchParams, 'p', params.pageIndex + 1);
      }
      setParam(urlSearchParams, 'q', params.query);
      setParam(urlSearchParams, 'platform', params.platform);
      setParam(urlSearchParams, 'status', params.status);
      setParam(urlSearchParams, 'topListed', params.topListed);
      _setSearchParams(urlSearchParams, { replace });
    },
    [_setSearchParams]
  );

  return [searchParams, setSearchParams];
};
