import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ParseKeys } from 'i18next';
import { Helmet } from 'react-helmet-async';

import { useAppDispatch } from '@/store';
import { globalActions } from '@/store/global';

export type PageTitleProps = {
  title?: string;
  titleRes?: ParseKeys;
  applicationTitle?: string;
  applicationTitleRes?: ParseKeys;
  separator?: string;
};

export const PageTitle: FC<PageTitleProps> = ({
  title,
  titleRes,
  applicationTitle,
  applicationTitleRes = 'application.meta.titlepostfix',
  separator = '|',
}) => {
  const { t } = useTranslation();
  const pageTitle = useMemo(() => title || t(titleRes || 'page.defaultTitle'), [t, title, titleRes]);
  const appTitle = useMemo(
    () => applicationTitle || t(applicationTitleRes || 'application.meta.titlepostfix'),
    [applicationTitle, applicationTitleRes, t]
  );
  const fullTitle = useMemo(() => `${pageTitle} ${separator || '|'} ${appTitle}`, [appTitle, pageTitle, separator]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(globalActions.setPageTitle(pageTitle));
  }, [dispatch, pageTitle]);

  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
};
