import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerContent,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { useAuth } from '@/hooks';
import { Header, Footer, Sidebar, AccountStatus } from './components';
import { useAppSelector } from '@/store';
import { LandingPageSelection } from '@/components/global';

export const DefaultLayout: FC = () => {
  const { t } = useTranslation();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isAuthenticated, logout } = useAuth();
  const { pageTitle } = useAppSelector((state) => state.global);

  // close drawer when location changes
  const location = useLocation();
  useEffect(() => {
    onDrawerClose();
  }, [location, onDrawerClose]);

  return (
    <Flex minH="100vh" flexDir="column" justify="stretch" align="stretch">
      <Header onOpenMenu={onDrawerOpen} />

      <Flex flex="1 1 auto">
        <Sidebar display={{ base: 'none', md: 'flex' }} flexShrink={0} />
        <Drawer
          placement="left"
          size="full"
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          onOverlayClick={onDrawerClose}
          autoFocus={false}
          returnFocusOnClose={false}
        >
          <DrawerContent>
            <Flex alignItems="center" margin={4} justifyContent="space-between">
              <Text fontSize="lg" fontWeight="bold">
                {t('application.title')}
              </Text>
              <Flex gap={4}>
                {isAuthenticated && (
                  <Button type="button" size="sm" flexShrink={0} onClick={logout}>
                    {t('ui.buttons.signout')}
                  </Button>
                )}
                <CloseButton display="flex" onClick={onDrawerClose} />
              </Flex>
            </Flex>
            <Sidebar borderRightWidth="1px" borderRightColor="surface" />
          </DrawerContent>
        </Drawer>

        <Box w="full" pos="relative">
          {pageTitle && (
            <>
              <Flex justifyContent="space-between" alignItems="center" mr="4">
                <Heading as="h1" p={4} size="md">
                  {pageTitle}
                </Heading>
                <LandingPageSelection pathname={location.pathname} />
              </Flex>
              <Divider />
            </>
          )}
          <AccountStatus />
          <Box padding={4}>
            <Outlet />
          </Box>
        </Box>
      </Flex>

      <Footer />
    </Flex>
  );
};
