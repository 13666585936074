import { FC } from 'react';
import { Loader } from '@netiva/classifieds-ui';

import { useAdPublishing } from '@/pages/Ad/hooks';
import { ActivePublication, ContractList, ExtensionProductList, ProductList } from './components';

export const Publishing: FC = () => {
  const { isLoading, activePublication, availableContracts, availableProducts, availableExtensionProducts } =
    useAdPublishing();

  if (isLoading) {
    return <Loader isLoading showOverlay />;
  }

  return (
    <>
      <ActivePublication publication={activePublication} />
      <ContractList contracts={availableContracts} />
      <ProductList products={availableProducts} />
      <ExtensionProductList products={availableExtensionProducts} />
    </>
  );
};
