import { chakra, Flex } from '@chakra-ui/react';

export const CardSection = chakra(Flex, {
  baseStyle: {
    gap: 8,
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: 4,
  },
});
