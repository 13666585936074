import { createStandaloneToast } from '@chakra-ui/react';

import theme from '@/theme';

const { ToastContainer, toast } = createStandaloneToast({
  theme,
  defaultOptions: { isClosable: true, duration: 3000 },
});

export { ToastContainer, toast };
