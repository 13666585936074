import { FC } from 'react';
import { Loader } from '@netiva/classifieds-ui';

import { useAppSelector } from '@/store';
import { AdAttribute, AdFormData, AdStep as StepType, AdStepItem } from '@/store/ad/types';
import { StepComponent } from './StepComponent';
import { useAdAttributes } from '../hooks';

export type StepProps = {
  step: StepType;
};

const checkConditions = (attribute: AdAttribute, attributes: AdAttribute[], formData: AdFormData) => {
  if (attribute.conditions?.length) {
    for (let i = 0; i < attribute.conditions.length; i++) {
      const condition = attribute.conditions[i];
      const other = attributes.find((x) => x.name === condition.attributeKey);
      if (!other) {
        continue;
      }

      const dataValue = formData[other.id];
      if (
        dataValue &&
        ((dataValue.value && dataValue.value !== condition.value) ||
          (dataValue.entries && !dataValue.entries.some((e) => e === Number(condition.value))))
      ) {
        return false;
      }
    }
  }
  return true;
};

export const Step: FC<StepProps> = ({ step }) => {
  const { formData } = useAppSelector((state) => state.ad);
  const { attributes, isLoading } = useAdAttributes();

  if (!step?.items) return null;

  return (
    <>
      <Loader isLoading={isLoading} />
      {step.items!.map((item, i) => {
        // check if it is an attribute
        let attribute: AdAttribute | undefined;
        if (item.attributeId || item.attributeKey) {
          if (isLoading) {
            // don't render if the attributes have not been loaded yet
            return null;
          }
          attribute =
            attributes.find((attribute) => attribute.id === item.attributeId) ||
            attributes.find((attribute) => attribute.key === item.attributeKey);
          if (!attribute) {
            console.debug(`Attribute ${item.attributeId || item.attributeKey} not found.`);
          } else if (!checkConditions(attribute, attributes, formData)) {
            return null;
          }
        }
        return <StepComponent key={i} attribute={attribute} stepItem={item as AdStepItem} />;
      })}
    </>
  );
};
