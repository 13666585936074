import { SimpleGrid } from '@chakra-ui/react';
import { FC } from 'react';

import { AdGridItem } from './components';
import { CommonAdListProps } from '../../types';

export type AdGridProps = CommonAdListProps;

export const AdGrid: FC<AdGridProps> = ({ items, onActionExecuted }) => {
  return (
    <SimpleGrid columns={[1, 2, 3, 4, 5]} gap={4}>
      {items.map((item) => (
        <AdGridItem key={item.id} item={item} onActionExecuted={onActionExecuted} />
      ))}
    </SimpleGrid>
  );
};
