import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { AccountPaymentsGetOrderDetailsResponse, accountApi } from '@netiva/classifieds-api';
import { AlertData } from '@netiva/classifieds-ui';

type CallbackState = '' | 'success' | 'cancelled' | 'failed';

type UsePaymentProps = {
  orderId: number | undefined;
  paymentProvider: string | undefined;
  onCancelled?: (order: AccountPaymentsGetOrderDetailsResponse) => void;
  token?: string;
};

export const usePayment = ({ orderId, paymentProvider, token, onCancelled }: UsePaymentProps) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  // if this parameter is set that means the user was redirected back from the payment provider
  const callbackState = useMemo<CallbackState>(() => {
    const stateParam = searchParams.get('state')?.toLowerCase() as CallbackState;
    switch (stateParam) {
      case 'success':
      case 'cancelled':
      case 'failed':
        return stateParam;
      default:
        return '';
    }
  }, [searchParams]);

  const {
    data: statusResponse,
    isLoading: isLoadingStatus,
    refetch: refetchStatus,
  } = accountApi.useGetPaymentStatus(
    orderId
      ? {
          orderId,
          token,
        }
      : skipToken
  );
  const [createPaymentApi, { isLoading: isCreating }] = accountApi.useCreatePayment();
  const { data: order, isLoading: isLoadingOrder } = accountApi.useGetOrder(
    orderId ? { id: orderId, token } : skipToken
  );

  const createPayment = useCallback(async () => {
    if (!orderId) {
      return;
    }
    const response = await createPaymentApi({
      accountPaymentsCreatePaymentRequest: {
        orderId,
        returnUrl: window.location.href,
        provider: paymentProvider,
        token,
      },
    }).unwrap();
    if (response.success && response.redirectUrl) {
      window.location.href = response.redirectUrl;
    }
  }, [createPaymentApi, orderId, paymentProvider, token]);

  // create payment
  useEffect(() => {
    if (!statusResponse || callbackState) {
      return;
    }

    if (!statusResponse.status) {
      createPayment();
      return;
    } else if (statusResponse.status === 'Created' && statusResponse.redirectUrl) {
      window.location.href = statusResponse.redirectUrl;
      return;
    }
  }, [callbackState, createPayment, statusResponse]);

  // update status polling
  useEffect(() => {
    let mounted = true;
    let timeout: NodeJS.Timeout;

    const refreshStatus = () => {
      timeout = setTimeout(async () => {
        if (mounted && statusResponse?.status === 'Created' && callbackState !== 'cancelled') {
          await refetchStatus();
          refreshStatus();
        }
      }, 2000);
    };

    refreshStatus();

    return () => {
      clearTimeout(timeout);
      mounted = false;
    };
  }, [callbackState, refetchStatus, statusResponse?.status]);

  // handle cancellation
  useEffect(() => {
    if (callbackState === 'cancelled' && order && onCancelled) {
      onCancelled(order);
    }
  }, [callbackState, onCancelled, order]);

  const isLoading = isLoadingOrder || isLoadingStatus || isCreating || !statusResponse;

  const alertData = useMemo<AlertData>(() => {
    switch (statusResponse?.status) {
      case 'Complete':
        return { status: 'success', description: t('payment.processing.status.complete') };
      case 'Failed':
        return { status: 'error', description: t('payment.processing.status.failed') };
      default:
        if (callbackState) {
          return { status: 'info', description: t('payment.processing.status.waiting') };
        }
        return { status: 'info', description: t('payment.processing.status.created') };
    }
  }, [callbackState, statusResponse?.status, t]);

  return {
    alertData,
    callbackState,
    createPayment,
    isLoading,
    order,
    status: statusResponse?.status,
  };
};
