import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SelectableCard } from '@netiva/classifieds-ui';

import { useAppDispatch, useAppSelector } from '@/store';
import { accountActions } from '@/store/account';

export const AccountSelection: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accountId, accounts, showAccountSelection } = useAppSelector((state) => state.account);
  const [selectedId, setSelectedId] = useState(accountId);

  useEffect(() => {
    if (showAccountSelection) {
      setSelectedId(accountId);
    }
  }, [accountId, showAccountSelection]);

  const handleClose = () => {
    dispatch(accountActions.hideAccountSelection());
  };

  const handleSubmit = () => {
    if (selectedId && !isNaN(selectedId)) {
      dispatch(accountActions.hideAccountSelection());
      const params = new URLSearchParams(window.location.search);
      params.set('aid', selectedId.toString());
      window.location.search = params.toString();
    }
  };

  return (
    <Modal isOpen={showAccountSelection} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('accounts.modal.header')}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text mb={4}>{t('accounts.modal.text')}</Text>
          <VStack spacing={4}>
            {accounts.map((account) => (
              <SelectableCard
                key={account.id}
                onClick={() => setSelectedId(account.id)}
                isSelected={account.id === selectedId}
              >
                <Text fontWeight="bold">{account.name}</Text>
              </SelectableCard>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSubmit}>{t('accounts.modal.selectButton')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
