import { Fragment, FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Divider, Text } from '@chakra-ui/react';

import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListProps,
  DescriptionListTerm,
} from '@netiva/classifieds-ui';

import { accountApi } from '@netiva/classifieds-api';

export type PriceSummaryProps = BoxProps & {
  productId: number | undefined;
  extensionProductIds: number[] | undefined;
  billingAddressId: number | undefined;
};

const PriceList = (props: DescriptionListProps) => <DescriptionList variant="alignRight" {...props} />;

export const PriceSummary: FC<PriceSummaryProps> = memo(function Price({
  productId,
  extensionProductIds,
  billingAddressId,
  ...rest
}: PriceSummaryProps) {
  const { t } = useTranslation();

  const { data: price, isLoading } = accountApi.useCalculatePrice({
    productId,
    extensionProductIds,
    billingAddressId,
  });

  if (!price) {
    return null;
  }

  const netAmount = price.netAmount;
  const vatPercentage = price.vatPercentage;
  const grossAmount = price.grossAmount;
  const vatAmount = price.vatAmount || price.grossAmount - price.netAmount;
  const roundingAmount = grossAmount - (netAmount + vatAmount);

  if (isLoading) {
    return null;
  }

  if (!netAmount && !grossAmount) {
    return (
      <Box {...rest}>
        <Text fontSize="md" fontWeight="bold" mb={4}>
          {t('price.title')}
        </Text>
        <Text>{t('price.noAdditionalCost')}</Text>
      </Box>
    );
  }

  return (
    <Box {...rest}>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        {t('price.title')}
      </Text>
      <PriceList marginBottom={4}>
        {price.positions &&
          price.positions.length > 0 &&
          price.positions.map((p, i) => (
            <Fragment key={i}>
              <DescriptionListTerm>{p.description}</DescriptionListTerm>
              <DescriptionListItem>
                {price.currency} {p.amount.toFixed(2)}
              </DescriptionListItem>
            </Fragment>
          ))}
        <Divider my={1} />
        <DescriptionListTerm>{t('price.netTotal')}</DescriptionListTerm>
        <DescriptionListItem>
          {price.currency} {netAmount.toFixed(2)}
        </DescriptionListItem>
        {!!vatPercentage && !!vatAmount && (
          <>
            <DescriptionListTerm variant="small">
              {t('price.vatFormat', { percent: vatPercentage.toFixed(1) })}
            </DescriptionListTerm>
            <DescriptionListItem variant="small">
              {price.currency} {vatAmount.toFixed(2)}
            </DescriptionListItem>
          </>
        )}
        {!!roundingAmount && (
          <>
            <DescriptionListTerm variant="small">{t('price.roundingDifference')}</DescriptionListTerm>
            <DescriptionListItem variant="small">
              {price.currency} {roundingAmount.toFixed(2)}
            </DescriptionListItem>
          </>
        )}
        <DescriptionListTerm>{t('price.grossTotal')}</DescriptionListTerm>
        <DescriptionListItem>
          {price.currency} {grossAmount.toFixed(2)}
        </DescriptionListItem>
      </PriceList>
    </Box>
  );
});
