import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { de, enUS, frCH, itCH } from 'date-fns/locale';

import { useTranslation } from 'react-i18next';

export const useDate = () => {
  const { i18n } = useTranslation();

  const locale = useMemo(() => {
    switch (i18n.language) {
      case 'de':
        return de;
      case 'fr':
        return frCH;
      case 'it':
        return itCH;
      default:
        return enUS;
    }
  }, [i18n.language]);

  const formatDate = useCallback(
    (dateString?: string | null, includeTime?: boolean) => {
      const formatString = includeTime ? 'PPPp' : 'PPP';
      return dateString ? format(new Date(dateString), formatString, { locale }) : '';
    },
    [locale]
  );

  const formatRange = useCallback(
    (startDate?: string | null, endDate?: string | null, separator = ' - ', includeTime?: boolean) => {
      if (startDate && endDate && startDate !== endDate) {
        return `${formatDate(startDate, includeTime)}${separator}${formatDate(endDate, includeTime)}`;
      }
      if (startDate) {
        return formatDate(startDate, includeTime);
      }
      if (endDate) {
        return formatDate(endDate, includeTime);
      }
      return '';
    },
    [formatDate]
  );

  return {
    formatDate,
    formatRange,
  };
};
