import { useMemo } from 'react';
import { accountApi } from '@netiva/classifieds-api';
import { sortByDescending } from '@netiva/classifieds-common';

import { Contract, Product } from '@/pages/Products/types';
import { useAuth } from './useAuth';

export const useContracts = () => {
  const { isAuthenticated } = useAuth();
  const { isLoading: isLoadingContracts, data: contractsData } = accountApi.useGetContracts(
    { includeExpired: true },
    { skip: !isAuthenticated }
  );
  const { isLoading: isLoadingProducts, data: productsData } = accountApi.useGetProducts(
    { includeDisabled: true },
    { skip: !isAuthenticated }
  );

  const products = useMemo(() => productsData?.items || [], [productsData?.items]);
  const productsMap = useMemo(() => new Map<number, Product>(products.map((p) => [p.id, p])), [products]);
  const contracts = useMemo<Contract[]>(
    () =>
      sortByDescending(contractsData?.items || [], (c) => c.startDate).map((c) => ({
        ...c,
        product: productsMap.get(c.productId),
      })),
    [contractsData?.items, productsMap]
  );

  return {
    products,
    contracts,
    isLoading: isLoadingContracts || isLoadingProducts,
  };
};
