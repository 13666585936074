import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, Flex } from '@chakra-ui/react';
import { AccountPaymentsGetOrderDetailsResponse } from '@netiva/classifieds-api';
import { getQueryValue, parseQueryValue } from '@netiva/classifieds-common';
import { Alert, Loader } from '@netiva/classifieds-ui';

import { usePayment } from '@/hooks';
import { routes } from '@/lib/routes';
import { useAuthToken } from '../hooks';

export const PaymentProcessingPage: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = parseQueryValue(params.orderId, parseInt);
  const paymentProvider = getQueryValue(searchParams.get('provider'));
  const token = useAuthToken();

  const handleCancelled = useCallback(
    (order: AccountPaymentsGetOrderDetailsResponse) => {
      if (order.contractId) {
        navigate(routes.paymentOverview(order.contractId, token));
      } else {
        navigate(routes.home());
      }
    },
    [navigate, token]
  );

  const { alertData, status, isLoading, callbackState, createPayment } = usePayment({
    orderId,
    paymentProvider,
    onCancelled: handleCancelled,
    token,
  });

  const handleReturn = useCallback(() => {
    navigate(routes.home());
  }, [navigate]);

  return (
    <Box position="relative">
      <Loader isLoading={isLoading} />

      <Alert alert={alertData} />

      <Flex marginTop={8} justify="center">
        {status === 'Complete' && <Button onClick={handleReturn}>{t('payment.button.back')}</Button>}
        {(callbackState === 'cancelled' || status === 'Cancelled' || status === 'Failed') && (
          <Button onClick={createPayment}>{t('payment.button.retry')}</Button>
        )}
      </Flex>
    </Box>
  );
};
