import React, { FunctionComponent } from 'react';
import { Checkbox } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';

export type CheckboxInputProps = {
  id: number;
  name: string;
};

export const CheckboxInput: FunctionComponent<CheckboxInputProps> = ({ id, name }) => {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.ad);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(adActions.updateFormData({ key: id, value: e.target.checked ? 'true' : 'false' }));
  };

  return (
    <Checkbox isChecked={formData[id]?.value === 'true'} onChange={handleOnChange}>
      {name}
    </Checkbox>
  );
};
