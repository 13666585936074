import { FC } from 'react';
import { Box, BoxProps, useMultiStyleConfig } from '@chakra-ui/react';
import { FooterComponentName } from '@/theme';

export interface FooterProps extends BoxProps {}

export const Footer: FC<FooterProps> = ({ ...rest }) => {
  const styles = useMultiStyleConfig(FooterComponentName);

  return (
    <Box sx={styles.container} {...rest}>
      &copy; {new Date().getFullYear()}
    </Box>
  );
};
