import { FunctionComponent } from 'react';

import { AdAttribute } from '@/store/ad/types';

import { CheckboxInput } from './CheckboxInput';
import { HtmlInput } from './HtmlInput';
import { SelectionInput } from './SelectionInput';
import { TextInput } from './TextInput';
import { NumberInput } from './NumberInput';

export type AttributeInputProps = {
  attribute: AdAttribute;
};

export const AttributeInput: FunctionComponent<AttributeInputProps> = ({ attribute }) => {
  if (!attribute.name || !attribute.editable) {
    return null;
  }

  const isSingleSelect = attribute.type === 'SelectSingle' || attribute.type === 'SelectSingleSearchMulti';
  const isMultiSelect =
    attribute.type === 'SelectMultiAnd' ||
    attribute.type === 'SelectMultiOr' ||
    attribute.type === 'SelectMultiSearchSingle';

  if (isSingleSelect || isMultiSelect)
    return attribute.items ? (
      <SelectionInput id={attribute.id} name={attribute.name} options={attribute.items} isMulti={isMultiSelect} />
    ) : null;

  if (attribute.type === 'HTML') {
    return attribute.name ? <HtmlInput id={attribute.id} /> : null;
  }

  if (attribute.type === 'HTMLEInserat') {
    return attribute.name ? <HtmlInput id={attribute.id} isEinserat /> : null;
  }

  if (attribute.type === 'Boolean') {
    return <CheckboxInput id={attribute.id} name={attribute.name} />;
  }

  if (attribute.type === 'Integer' || attribute.type === 'Decimal' || attribute.type === 'Price') {
    return <NumberInput attribute={attribute} />;
  }

  return <TextInput attribute={attribute} />;
};
