const Keys = {
  SelectedAccountId: 'SelectedAccountId',
};

export const getSelectedAccountId = () => {
  const value = localStorage.getItem(Keys.SelectedAccountId);
  const id = Number(value);
  if (!id || isNaN(id)) {
    return undefined;
  }
  return id;
};

export const setSelectedAccountId = (id?: number) => {
  if (id) {
    localStorage.setItem(Keys.SelectedAccountId, id.toString());
  } else {
    localStorage.removeItem(Keys.SelectedAccountId);
  }
};
