import { FC } from 'react';
import { StackDivider, VStack } from '@chakra-ui/react';

import { AdListItem } from './components';
import { CommonAdListProps } from '../../types';

export type AdListProps = CommonAdListProps;

export const AdList: FC<AdListProps> = ({ items, onActionExecuted, showPlatform, hasTopListingSlots }) => {
  return (
    <VStack align="stretch" divider={<StackDivider />} spacing={4}>
      {items.map((item) => (
        <AdListItem
          key={item.id}
          item={item}
          onActionExecuted={onActionExecuted}
          showPlatform={showPlatform}
          hasTopListingSlots={hasTopListingSlots}
        />
      ))}
    </VStack>
  );
};
