import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { DescriptionListItem, DescriptionListTerm } from '@netiva/classifieds-ui';

export type CountryProps = {
  label: string;
  value: string;
};

export const Country: FunctionComponent<CountryProps> = ({ label, value }) => {
  const { t } = useTranslation('countries');

  if (!value) {
    return null;
  }

  return (
    <>
      <DescriptionListTerm>{label}</DescriptionListTerm>
      <DescriptionListItem>
        {/* @ts-expect-error dynamic translation */}
        {t(value.toLowerCase())}
      </DescriptionListItem>
    </>
  );
};

export default Country;
