import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { chakra, Box, BoxProps, Tooltip } from '@chakra-ui/react';
import { DataObjectStatus } from '@netiva/classifieds-api';

export type StatusBadgeProps = BoxProps & {
  status: DataObjectStatus;
};

export const StatusBadge: FC<StatusBadgeProps> = ({ status, ...rest }) => {
  const { t } = useTranslation();
  const statusName = status.toString().toLowerCase();
  const label = `ad.status.${statusName}`;
  const color = useMemo(() => {
    switch (status) {
      case 'Active':
      case 'Approved':
        return 'green.400';
      case 'Draft':
        return 'yellow.400';
      case 'ToApprove':
        return 'blue.400';
      case 'Disabled':
        return 'gray.400';
      case 'Expired':
        return 'orange.400';
      default:
        return 'transparent';
    }
  }, [status]);

  return (
    <Box position="absolute" top={0} left={0} borderTopLeftRadius="default" overflow="hidden" {...rest}>
      <svg width="50" height="50" viewBox="0 0 100 100">
        {/* @ts-expect-error dynamic translation */}
        <Tooltip label={t(label)} placement="top">
          <chakra.path fill={color} d="M 0 0 L 100 0 L 0 100 Z" tabIndex={0} />
        </Tooltip>
      </svg>
    </Box>
  );
};
