import { AuthMode } from './AuthenticationProvider';
import { getAccessToken as getMsalAccessToken } from './msal';
import { getAccessToken as getOAuthAccessToken } from './oauth';

export const getAccessToken = async () => {
  switch (AuthMode) {
    case 'msal':
      return await getMsalAccessToken();
    case 'oauth':
      return getOAuthAccessToken();
    default:
      return null;
  }
};
