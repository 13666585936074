import { FC } from 'react';

import { GoogleTagManagerId } from '@/environment';
import { Helmet } from 'react-helmet-async';

export const GoogleTagManager: FC = () => {
  return (
    GoogleTagManagerId && (
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GoogleTagManagerId}');`}
        </script>
      </Helmet>
    )
  );
};
