import { FC } from 'react';
import { Text } from '@chakra-ui/react';

export type AddressLineProps = {
  content: string | null | undefined | (string | null | undefined)[];
};

export const AddressLine: FC<AddressLineProps> = ({ content }) => {
  const trimmed =
    typeof content === 'object'
      ? content
          ?.filter((x) => !!x)
          .join(' ')
          .trim()
      : content?.trim();
  if (!trimmed) {
    return null;
  }
  return (
    <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" title={trimmed}>
      {trimmed}
    </Text>
  );
};
