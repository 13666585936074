import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  CardBody,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { accountApi } from '@netiva/classifieds-api';
import { SelectableCard } from '@netiva/classifieds-ui';

import { useCurrencyFormatter, useProductGroups } from '@/hooks';
import { canUpgradeTo } from '../utils';
import { Contract, Product } from '../types';

export type UpgradeModalProps = Omit<ModalProps, 'children'> & {
  contract?: Contract;
  products: Product[];
};

export const UpgradeModal: FC<UpgradeModalProps> = ({ contract, products, isOpen, onClose, ...rest }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const currencyFormatter = useCurrencyFormatter();

  const availableProducts = useMemo(
    () => products.filter((p) => contract?.product && canUpgradeTo(contract.product, p)),
    [contract?.product, products]
  );

  const { orderedProductGroups } = useProductGroups(availableProducts);

  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [upgradeContract, { isLoading: isUpgradingContract }] = accountApi.useUpgradeContract();

  useEffect(() => {
    setSelectedProduct(undefined);
  }, [isOpen]);

  const handleSubmit = async () => {
    if (!contract || !selectedProduct) {
      return;
    }

    try {
      await upgradeContract({
        accountContractsUpgradeContractRequest: { contractId: contract.id, productId: selectedProduct.id },
      }).unwrap();
      toast({ status: 'success', description: t('products.upgradeModal.success') });
      onClose();
    } catch (err) {
      console.error(err);
      toast({ status: 'error', description: t('products.upgradeModal.error') });
    }
  };

  return (
    <Modal isCentered scrollBehavior="inside" size="xl" isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{t('products.upgradeModal.title')}</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box>
            <Text>{t('products.upgradeModal.currentProduct')}</Text>
            <Text>{contract?.product?.name}</Text>
          </Box>
          <Accordion defaultIndex={orderedProductGroups.length === 1 ? 0 : undefined} allowToggle mt={4}>
            {orderedProductGroups.map(([group, products]) => (
              <AccordionItem key={group}>
                <AccordionButton>
                  <VStack align="stretch" textAlign="start" py={1}>
                    <Heading size="md">{t(`ad.steps.publishing.productGroups.${group}.title`, '')}</Heading>
                    <Text>{t(`ad.steps.publishing.productGroups.${group}.description`, '')}</Text>
                  </VStack>
                  <AccordionIcon ml="auto" boxSize={8} />
                </AccordionButton>
                <AccordionPanel>
                  <VStack align="stretch" spacing={3}>
                    {products.map((p) => (
                      <SelectableCard
                        key={p.id}
                        isSelected={p.id === selectedProduct?.id}
                        onClick={() => setSelectedProduct(p)}
                      >
                        <CardBody p={0}>
                          <Flex justifyContent="space-between" p={2}>
                            <Text fontSize="lg" fontWeight="bold">
                              {p.name}
                            </Text>
                            <Text>{currencyFormatter.format(p.price)}</Text>
                          </Flex>
                          {p.description && !p.description.startsWith('#') && (
                            <Text dangerouslySetInnerHTML={{ __html: p.description }} />
                          )}
                        </CardBody>
                      </SelectableCard>
                    ))}
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </ModalBody>
        <ModalFooter display="block">
          <Text mb={4}>{t('products.upgradeModal.footer')}</Text>
          <Flex>
            <Button onClick={onClose} colorScheme="neutral">
              {t('ui.buttons.cancel')}
            </Button>
            <Button onClick={handleSubmit} isLoading={isUpgradingContract} isDisabled={!selectedProduct} ml="auto">
              {t('products.upgradeModal.submit')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
