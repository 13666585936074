import { FC, memo } from 'react';

import { useAppSelector } from '@/store';
import { EInseratPreview } from './components';

export const Preview: FC = memo(function Preview() {
  const { dataObjectType } = useAppSelector((state) => state.ad);

  switch (dataObjectType) {
    case 4:
      return <EInseratPreview />;
    default:
      return null;
  }
});
