import { useMemo } from 'react';
import { groupBy } from '@netiva/classifieds-common';
import { DefaultProductGroups, ExtensionProductGroups, ProductGroups, SubscriptionProductGroups } from '@/constants';

type ProductLike = {
  group?: string | null;
};

export const normalizeProductGroupName = (name: string) => {
  return name.replaceAll(':', '_')?.toLowerCase();
};

export const useProductGroups = <T extends ProductLike>(products: T[]) => {
  const groups = useMemo(
    () => groupBy(products, (p) => normalizeProductGroupName(p.group || ProductGroups.None)),
    [products]
  );
  const defaultProductGroups = useMemo(
    () =>
      DefaultProductGroups.map(normalizeProductGroupName)
        .map((g) => [g, groups.get(g) || []] as const)
        .filter((g) => g[1].length > 0),
    [groups]
  );
  const subscriptionProductGroups = useMemo(
    () =>
      SubscriptionProductGroups.map(normalizeProductGroupName)
        .map((g) => [g, groups.get(g) || []] as const)
        .filter((g) => g[1].length > 0),
    [groups]
  );
  const otherProductGroups = useMemo(
    () =>
      [...groups].filter(([g]) => {
        return (
          !DefaultProductGroups.map(normalizeProductGroupName).includes(g) &&
          !SubscriptionProductGroups.map(normalizeProductGroupName).includes(g)
        );
      }),
    [groups]
  );
  const defaultExtensionProductGroups = useMemo(
    () =>
      ExtensionProductGroups.map(normalizeProductGroupName)
        .map((g) => [g, groups.get(g) || []] as const)
        .filter((g) => g[1].length > 0),
    [groups]
  );
  const otherExtensionProductGroups = useMemo(
    () =>
      [...groups].filter(([g]) => {
        return !ExtensionProductGroups.map(normalizeProductGroupName).includes(g);
      }),
    [groups]
  );
  const orderedProductGroups = useMemo(
    () => [...defaultProductGroups, ...subscriptionProductGroups, ...otherProductGroups],
    [defaultProductGroups, otherProductGroups, subscriptionProductGroups]
  );
  const orderedExtensionProductGroups = useMemo(
    () => [...defaultExtensionProductGroups, ...otherExtensionProductGroups],
    [defaultExtensionProductGroups, otherExtensionProductGroups]
  );

  return {
    defaultProductGroups,
    subscriptionProductGroups,
    otherProductGroups,
    orderedProductGroups,
    defaultExtensionProductGroups,
    otherExtensionProductGroups,
    orderedExtensionProductGroups,
  };
};
