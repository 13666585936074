import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Box, Flex, Heading, VStack, useDisclosure } from '@chakra-ui/react';
import { AddressRec, AddressType } from '@netiva/classifieds-api';
import { Loader } from '@netiva/classifieds-ui';

import { Address, AddressTypeSection, EditAddressModal, EmptyAddressData, LogoUpload } from './components';
import { useAddresses } from './useAddresses';

export const AddressesPage: FC = memo(function AddressesPage() {
  const { t } = useTranslation();
  const { accountAddress, contactAddresses, isLoading } = useAddresses(); // billingAddresses,
  const editModalDisclosure = useDisclosure();
  const [editingAddress, setEditingAddress] = useState<AddressRec>();

  const handleEdit = (address: AddressRec) => {
    setEditingAddress(address);
    editModalDisclosure.onOpen();
  };

  const handleCreate = (type: AddressType) => {
    setEditingAddress({ ...EmptyAddressData, type });
    editModalDisclosure.onOpen();
  };

  return (
    <VStack align="stretch" spacing={4}>
      <Loader isLoading={isLoading} size="lg" showOverlay />

      <Flex gap={8} wrap="wrap" align="stretch">
        <Box width={{ base: 'full', md: 'auto' }}>
          <Heading as="h2" size="sm" mb={3}>
            {t('addresses.account.title')}
          </Heading>
          <Address address={accountAddress} onEdit={() => handleEdit(accountAddress!)} />
        </Box>

        <Box>
          <Heading as="h2" size="sm" mb={3}>
            {t('addresses.logo.title')}
          </Heading>
          <LogoUpload />
        </Box>
      </Flex>

      <Accordion allowMultiple defaultIndex={[0]}>
        <AddressTypeSection
          label={t('addresses.contact.title')}
          addresses={contactAddresses}
          onEdit={handleEdit}
          onCreate={() => handleCreate('Contact')}
        />
        {/* <AddressTypeSection
          label={t('addresses.billing.title')}
          addresses={billingAddresses}
          onEdit={handleEdit}
          onCreate={() => handleCreate('Billing')}
        /> */}
      </Accordion>

      <EditAddressModal address={editingAddress} {...editModalDisclosure} />
    </VStack>
  );
});
