import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/hooks';
import { routes } from '@/lib/routes';

// Support page that clears local storage and authentication state.
export const ResetPage: FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const reset = async () => {
      localStorage.clear();
      await logout();
      navigate(routes.home());
    };

    reset();
  }, [logout, navigate]);

  return null;
};
