import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import { routes } from '@/lib/routes';
import { AdValidationEntry } from '@/store/ad';
import { useAdParams } from './useAdParams';
import { useAdSteps } from './useAdSteps';
import { useAdValidator } from './useAdValidator';

export const useAdNavigation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const { dataObjectId } = useAdParams();
  const { currentStepIndex, steps } = useAdSteps();
  const validationStatus = useAdValidator();

  /** navigates to the previous step */
  const gotoPrevStep = useCallback(() => {
    navigate(routes.ad(dataObjectId, steps[currentStepIndex - 1].key), { replace: false });
  }, [dataObjectId, currentStepIndex, steps, navigate]);

  /** navigates to the exact step */
  const gotoStep = useCallback(
    (stepKey: string, skipValidation = false, searchParams = {}) => {
      if (stepKey === steps[currentStepIndex]?.key) {
        return;
      }

      let entry: AdValidationEntry = { isValid: true };
      const newIndex = steps.findIndex((step) => step.key === stepKey);
      if (!skipValidation && newIndex > currentStepIndex) {
        const currentStep = steps[currentStepIndex];
        entry = validationStatus[currentStep.key];
      }

      if (entry.isValid) {
        const searchParamsString = new URLSearchParams(searchParams).toString();
        const adRoute = `${routes.ad(dataObjectId, steps[newIndex].key)}${searchParamsString ? `?${searchParamsString}` : ''}`;
        navigate(adRoute, { replace: false });
      } else {
        toast({ status: 'error', description: entry.message || t('ad.error.missingFields'), isClosable: true });
      }
    },
    [currentStepIndex, dataObjectId, navigate, steps, t, toast, validationStatus]
  );

  /** navigates to the next step */
  const gotoNextStep = useCallback(
    (skipValidation = false, stepId: number | undefined = undefined) => {
      let entry: AdValidationEntry = { isValid: true };
      if (!skipValidation) {
        const currentStep = steps[currentStepIndex];
        entry = validationStatus[currentStep.key];
      }
      if (entry.isValid) {
        navigate(routes.ad(dataObjectId, steps[currentStepIndex + 1].key, stepId), { replace: false });
      } else {
        toast({ status: 'error', description: entry.message || t('ad.error.missingFields'), isClosable: true });
      }
    },
    [currentStepIndex, dataObjectId, navigate, steps, t, toast, validationStatus]
  );

  return { gotoStep, gotoPrevStep, gotoNextStep };
};
