import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { api, initialize, queryErrorMiddleware } from '@netiva/classifieds-api';
import { getAcceptLanguage, localStorageSlice } from '@netiva/classifieds-common';

import { getAccessToken } from '@/authentication';
import { toast } from '@/components/ui';
import { ApiBaseUrl, Platform } from '@/environment';
import i18n from '@/i18n';

import { accountSlice } from './account';
import { adSlice } from './ad';
import { authSlice } from './auth';
import { globalSlice } from './global';
import { resetApiStateMiddleware } from './middlewares';

initialize({
  queryOptions: {
    baseUrl: ApiBaseUrl,
    prepareHeaders: async (headers, api) => {
      const accessToken = await getAccessToken();
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      const state = api.getState() as RootState;

      if (!headers.get('X-Platform')) {
        const requestPlatform = state.ad.platform || Platform;
        if (requestPlatform) {
          headers.set('X-Platform', requestPlatform);
        }
      }

      if (state.account.accountId && !headers.get('X-AccountId')) {
        headers.set('X-AccountId', state.account.accountId.toString());
      }

      if (i18n.language) {
        headers.set('Accept-Language', getAcceptLanguage(i18n.language));
      }

      return headers;
    },
  },
  errorHandler(error) {
    if (error.status === 'FETCH_ERROR') {
      console.log('Failed to access the API.', error);
      toast({
        status: 'error',
        description: 'Failed to access the API.',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    if (error.status >= 400 && error.status < 500) {
      // 4xx errors should be handled by the call site
      return;
    }

    toast({
      status: 'error',
      description: error.data?.message,
      duration: null,
      isClosable: true,
    });
  },
});

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [accountSlice.name]: accountSlice.reducer,
    [adSlice.name]: adSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [globalSlice.name]: globalSlice.reducer,
    [localStorageSlice.name]: localStorageSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware, queryErrorMiddleware.middleware)
      .prepend(resetApiStateMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
