import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { AddressRec } from '@netiva/classifieds-api';
import { useUpdateEffect } from '@netiva/classifieds-common';
import { ConfirmationDialog } from '@netiva/classifieds-ui';

import { useAppSelector } from '@/store';
import { AddressForm } from '../AddressForm';
import { useAddress } from '../../useAddress';

export type EditAddressModalProps = Omit<ModalProps, 'children'> & {
  address?: AddressRec;
};

export const EmptyAddressData: AddressRec = {
  id: 0,
  type: 'Contact',
  city: '',
  countryIso2: '',
  gender: 'Unknown',
  street: '',
  zip: '',
};

export const EditAddressModal: FC<EditAddressModalProps> = ({ address, isOpen, onClose, ...rest }) => {
  const { t } = useTranslation();
  const { saveAddress, deleteAddress, isLoading } = useAddress();
  const { hasValidAccount } = useAppSelector((state) => state.account);

  const form = useForm<AddressRec>({
    values: address,
    defaultValues: EmptyAddressData,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const isNew = (address?.id || 0) === 0;
  const isNewAccount = address?.type === 'Account' && !hasValidAccount;

  const {
    isOpen: isDeleteConfirmationOpen,
    onClose: closeDeleteConfirmation,
    onOpen: openDeleteConfirmation,
  } = useDisclosure();

  useUpdateEffect(() => {
    form.reset();
  }, [address, isOpen, form]);

  const handleSave = async (data: AddressRec) => {
    if (!address) {
      return;
    }

    if (await saveAddress(data)) {
      onClose();
    }
  };

  const handleDeleteConfirmed = async () => {
    if (!address) {
      return;
    }

    if (await deleteAddress(address.id)) {
      closeDeleteConfirmation();
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} {...rest}>
        <ModalOverlay />
        <ModalContent maxWidth="xl">
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSave)}>
              <ModalHeader>
                <Text>
                  {t(
                    isNewAccount
                      ? 'address.modal.title.newAccount'
                      : isNew
                        ? 'address.modal.title.create'
                        : 'address.modal.title.edit'
                  )}
                </Text>
                <ModalCloseButton />
              </ModalHeader>
              <ModalBody position="relative">
                {isNewAccount && (
                  <Alert status="info" marginBottom={4}>
                    <AlertIcon />
                    <AlertTitle>{t('account.newUserNotification')}</AlertTitle>
                  </Alert>
                )}
                <AddressForm />
              </ModalBody>
              <ModalFooter gap={4}>
                {!isNew && address?.type !== 'Account' && (
                  <Button
                    type="button"
                    onClick={openDeleteConfirmation}
                    colorScheme="neutral"
                    size="sm"
                    marginRight="auto"
                  >
                    {t('ui.buttons.delete')}
                  </Button>
                )}

                <Button type="button" onClick={onClose} colorScheme="neutral" size="sm">
                  {t('ui.buttons.cancel')}
                </Button>
                <Button type="submit" size="sm" isLoading={isLoading} isDisabled={isLoading}>
                  {t('ui.buttons.save')}
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>

      <ConfirmationDialog
        title={t('addresses.deleteConfirmation.title')}
        message={t('addresses.deleteConfirmation.message')}
        isOpen={isDeleteConfirmationOpen}
        onCancel={closeDeleteConfirmation}
        onConfirm={handleDeleteConfirmed}
        cancelText={t('ui.buttons.no')}
        okText={t('ui.buttons.yes')}
      />
    </>
  );
};
