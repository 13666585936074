import { useMemo } from 'react';
import { accountApi } from '@netiva/classifieds-api';

export const useAddresses = () => {
  const { data, isLoading } = accountApi.useGetAddresses({});

  const addresses = useMemo(() => data?.addresses || [], [data?.addresses]);
  const accountAddress = useMemo(() => addresses.find((address) => address.type === 'Account'), [addresses]);
  const billingAddresses = useMemo(() => addresses.filter((address) => address.type === 'Billing'), [addresses]);
  const contactAddresses = useMemo(() => addresses.filter((address) => address.type === 'Contact'), [addresses]);

  return {
    addresses,
    accountAddress,
    billingAddresses,
    contactAddresses,
    isLoading,
  };
};
