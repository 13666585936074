import { useEffect, useMemo } from 'react';
import { accountApi } from '@netiva/classifieds-api';

import { isPrintType } from '@/lib/utils';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { useAdParams } from './useAdParams';
import { InvoicePaymentProvider } from '../constants';

export const useAdPublishing = () => {
  const { dataObjectId } = useAdParams();
  const dispatch = useAppDispatch();
  const {
    categoryId,
    dataObjectType,
    selectedContractId,
    selectedProductId,
    selectedExtensionProductIds,
    paymentProvider,
    print,
  } = useAppSelector((state) => state.ad);

  // requires either an objectId > 0 or both, dataObjectType and categoryId
  const skipLoading =
    dataObjectId === undefined ||
    (dataObjectId === 0 && (!dataObjectType || !categoryId)) ||
    isPrintType(dataObjectType);
  const { data: publicationOptions, isLoading } = accountApi.useGetPublicationOptions(
    {
      dataObjectId: dataObjectId || undefined,
      categoryId: categoryId,
      dataObjectType,
    },
    {
      skip: skipLoading,
    }
  );

  const activePublication = useMemo(
    () => publicationOptions?.activePublication,
    [publicationOptions?.activePublication]
  );
  const availableContracts = useMemo(
    () => publicationOptions?.availableContracts || [],
    [publicationOptions?.availableContracts]
  );
  const availableProducts = useMemo(
    () => publicationOptions?.availableProducts || [],
    [publicationOptions?.availableProducts]
  );

  const selectedContract = useMemo(
    () => availableContracts.find((c) => c.id === selectedContractId),
    [availableContracts, selectedContractId]
  );
  const selectedProduct = useMemo(
    () => availableProducts.find((p) => p.id === selectedProductId),
    [availableProducts, selectedProductId]
  );

  const availableExtensionProducts = useMemo(
    () =>
      activePublication?.contract.availableExtensions ||
      selectedContract?.availableExtensions ||
      selectedProduct?.extensionProducts ||
      [],
    [
      activePublication?.contract.availableExtensions,
      selectedContract?.availableExtensions,
      selectedProduct?.extensionProducts,
    ]
  );

  const selectedExtensionProducts = useMemo(
    () => availableExtensionProducts.filter((ep) => selectedExtensionProductIds.includes(ep.id)),
    [availableExtensionProducts, selectedExtensionProductIds]
  );

  const requiresPublication = useMemo(() => {
    const hasPublication = !!activePublication;
    const hasSelectedContract = !!selectedContract;
    const hasSelectedProduct = !!selectedProduct;
    const hasSelectedExtensionProduct = selectedExtensionProducts.length > 0;
    return !hasPublication || hasSelectedContract || hasSelectedProduct || hasSelectedExtensionProduct;
  }, [activePublication, selectedContract, selectedExtensionProducts.length, selectedProduct]);

  const paymentProviders = useMemo(() => {
    return (
      (isPrintType(dataObjectType)
        ? print.paymentInfo?.paymentProviders
        : selectedContract?.product.paymentProviders || selectedProduct?.paymentProviders) || []
    );
  }, [
    dataObjectType,
    print.paymentInfo?.paymentProviders,
    selectedContract?.product.paymentProviders,
    selectedProduct?.paymentProviders,
  ]);

  useEffect(() => {
    // reset selected payment provider if it is not available (i.e. on product selection change)
    if (paymentProvider && paymentProvider !== InvoicePaymentProvider && !paymentProviders.includes(paymentProvider)) {
      dispatch(adActions.setPaymentProvider(undefined));
    }
  }, [dispatch, paymentProvider, paymentProviders]);

  return {
    isLoading,
    activePublication,
    availableContracts,
    availableProducts,
    availableExtensionProducts,
    selectedContractId,
    selectedContract,
    selectedProductId,
    selectedProduct,
    selectedExtensionProductIds,
    selectedExtensionProducts,
    requiresPublication,
    paymentProviders,
  };
};
