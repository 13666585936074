import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Flex, Switch } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type LandingPageSelectionProps = {
  pathname: string;
};

export const landingPageSelectionLocalStorageKey = 'landingPageSelection';

const allowedPathnames = ['/', '/ads'];

export const LandingPageSelection: FC<LandingPageSelectionProps> = ({ pathname }) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  const isAllowedPathname = useMemo(() => allowedPathnames.includes(pathname), [pathname]);

  useEffect(() => {
    if (!isAllowedPathname) return;

    const storedPathname = localStorage.getItem(landingPageSelectionLocalStorageKey) ?? allowedPathnames[0];
    setIsChecked(storedPathname === pathname);
  }, [isAllowedPathname, pathname]);

  const handleToggle = () => {
    if (!isChecked) {
      localStorage.setItem(landingPageSelectionLocalStorageKey, pathname);
      setIsChecked(!isChecked);
    }
  };

  if (!isAllowedPathname) return null;

  return (
    <Flex justifyContent="end" alignItems="center" gap="4">
      <Box as="span" fontSize="sm">
        {t('landingPageSelection')}
      </Box>
      <Switch isChecked={isChecked} onChange={handleToggle} />
    </Flex>
  );
};
