import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { publicApi } from '@netiva/classifieds-api';
import { useUpdateEffectDeepCompare } from '@netiva/classifieds-common';
import { FormInput, FormSelect, FormSwitch, SelectOption } from '@netiva/classifieds-ui';

import { AdListSearchParams } from '../types';
import { useAppSelector } from '@/store';
import { useAdListSearchParams } from '../hooks';

export const Filter: FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useAdListSearchParams();

  const { platforms } = useAppSelector((state) => state.global);
  const { isLoading: isLoadingPlatforms } = publicApi.useGetConfigurationPlatforms({});

  const form = useForm<AdListSearchParams>({
    values: searchParams,
  });

  const currentValues = form.watch();

  useUpdateEffectDeepCompare(() => {
    setSearchParams(currentValues);
  }, [currentValues]);

  const platformOptions = useMemo<SelectOption[]>(
    () => [
      { label: t('form.select.all'), value: '' },
      ...(platforms.map((p) => ({
        label: t(`platforms.${p.name.toLowerCase()}`, '') as string,
        value: p.name,
      })) || []),
    ],
    [platforms, t]
  );

  return (
    <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
      <FormProvider {...form}>
        <FormInput<AdListSearchParams> name="query" label={t('myads.filter.text')} />
        {platforms.length > 1 && (
          <FormSelect<AdListSearchParams>
            name="platform"
            label={t('myads.filter.platform')}
            options={platformOptions}
            isLoading={isLoadingPlatforms}
            isClearable
          />
        )}
        <FormSwitch<AdListSearchParams> name="topListed" label={t('myads.filter.topListed')} />
      </FormProvider>
    </Flex>
  );
};
