import {
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';

import { AuthConfig, getLoginRequest, getResetPasswordRequest } from './config';
import { CustomNavigationClient } from './CustomNavigationClient';

const ErrorCodes = {
  USER_CANCEL: 'AADB2C90091',
  PASSWORD_FORGOTTEN: 'AADB2C90118',
  GRANT_EXPIRED: 'AADB2C90080',
};

const navigationClient = new CustomNavigationClient();
let msalInstance: PublicClientApplication;

export const getInstance = () => {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(AuthConfig);
    msalInstance.setNavigationClient(navigationClient);

    msalInstance.addEventCallback((event) => {
      // console.log('MSAL', event);
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error?.message.includes(ErrorCodes.PASSWORD_FORGOTTEN)) {
          // we need to disable navigation because MSAL's internal error handling will run after this
          // and will redirect to the login page again if the sign-up/sign-in flow does not have self-service password reset enabled
          // (which is the case for tierwelt, which has a custom password reset page)
          // navigationClient.disableNavigation();
          // window.location.href = ResetPasswordUrl;
          msalInstance.loginRedirect(getResetPasswordRequest());
        }
      }

      if (event.error?.message.includes(ErrorCodes.USER_CANCEL)) {
        msalInstance.loginRedirect(getLoginRequest());
      }

      if (event.error?.message.includes(ErrorCodes.GRANT_EXPIRED)) {
        // refresh token expired - initiate manual login
        msalInstance.loginRedirect(getLoginRequest());
      }
    });
  }

  return msalInstance;
};

export const getAccessToken = async () => {
  const msalInstance = getInstance();
  await msalInstance.initialize();
  await msalInstance.handleRedirectPromise();
  const account = msalInstance.getAllAccounts()[0];
  if (!account) {
    return null;
  }
  const accessTokenRequest: SilentRequest | RedirectRequest = {
    ...getLoginRequest(),
    account: account,
  };
  return msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      return accessTokenResponse.accessToken;
    })
    .catch(function (error) {
      console.warn(error);

      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(accessTokenRequest).catch(function (innerError) {
          console.error(innerError);
          // if we encounter a second error this probably means that the auth cache is in an invalid state,
          // i.e. the handleRedirectPromise() function didn't run on a previous request,
          // so we clean the msal cache and try again.
          msalInstance.clearCache();
          msalInstance.acquireTokenRedirect(accessTokenRequest);
        });
      }
      return null;
    });
};

type DecodedAccessToken = {
  email?: string;
  emails?: string[];
};

export const getDecodedAccessToken = async () => {
  const token = await getAccessToken();
  if (!token) {
    return null;
  }
  return jwtDecode<DecodedAccessToken>(token as string);
};
