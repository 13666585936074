import { FC, useMemo } from 'react';
import { Box, BoxProps, ChakraProps, Flex } from '@chakra-ui/react';
import {
  DndContext,
  DragEndEvent,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdFile } from '@/store/ad/types';
import { Thumbnail } from './Thumbnail';
import { getIdOrFileName } from '@/lib/utils';

export type ImagePanelProps = BoxProps & {
  allowReorder?: boolean;
  maxImageSize?: ChakraProps['maxWidth'];
  onRemoveImage: (event: React.MouseEvent, image: AdFile) => void;
  onReplaceImage: (event: React.MouseEvent, image: AdFile) => void;
};

export const ImagePanel: FC<ImagePanelProps> = ({
  allowReorder,
  maxImageSize,
  onRemoveImage,
  onReplaceImage,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const { images } = useAppSelector((state) => state.ad);

  // wrap the images in an object with a unique id, otherwise reordering with dnd-kit does not work
  // (existing files have a unique integer ID, new files have a random file name)
  const sortableImages = useMemo(
    () =>
      images
        // sort by order, id
        .toSorted((a, b) => (a.order || 0) - (b.order || 0) || a.id - b.id)
        .map((img) => ({
          id: getIdOrFileName(img),
          image: img,
        })),
    [images]
  );

  const sensors = useSensors(
    useSensor('ontouchstart' in document.documentElement ? TouchSensor : PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = sortableImages.findIndex((img) => img.id === active.id);
      const newIndex = sortableImages.findIndex((img) => img.id === over.id);
      const newImages = arrayMove(images, oldIndex, newIndex);
      dispatch(adActions.setImages(newImages.map((img, i) => ({ ...img, order: i }))));
    }
  };

  return (
    <Box {...rest}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={sortableImages} strategy={rectSortingStrategy}>
          <Flex flexWrap="wrap" justify="center" align="stretch" gap={4}>
            {sortableImages.map((img, i) => (
              <Thumbnail
                key={img.id}
                image={img.image}
                allowReorder={allowReorder}
                isFirst={i === 0}
                onRemove={(e) => onRemoveImage(e, img.image)}
                onReplace={(e) => onReplaceImage(e, img.image)}
                maxImageSize={maxImageSize}
              />
            ))}
          </Flex>
        </SortableContext>
      </DndContext>
    </Box>
  );
};
