import { FC } from 'react';
import { Box, BoxProps, Text } from '@chakra-ui/react';

export type HomeSectionProps = BoxProps & {
  title: string;
};
export const HomeSection: FC<HomeSectionProps> = ({ title, children, ...rest }) => {
  return (
    <Box {...rest}>
      <Text as="h2" fontSize="lg" marginBottom={2}>
        {title}
      </Text>
      {children}
    </Box>
  );
};
