import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Heading, Wrap } from '@chakra-ui/react';
import { Loader, SelectableTag } from '@netiva/classifieds-ui';

import { useDate, usePrintIssueDates } from '@/hooks';
import { useAppDispatch } from '@/store';
import { adActions } from '@/store/ad';
import { useAdParams } from '@/pages/Ad/hooks';

export const IssueDateSelector: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { formatDate } = useDate();

  const { dataObjectId } = useAdParams();
  const { bookedIssueIds, futureIssueDates, isLoading, pastIssueDates, selectedIssueIds } =
    usePrintIssueDates(dataObjectId);

  const isActive = (issueId: number) => {
    return selectedIssueIds.includes(issueId);
  };

  const handleClick = (issueId: number) => {
    const newIssueIds = isActive(issueId)
      ? selectedIssueIds.filter((item) => item !== issueId)
      : [...selectedIssueIds, issueId];
    dispatch(adActions.setSelectedIssueIds(newIssueIds));
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Wrap spacing={3}>
        {futureIssueDates.map((issueDate) => (
          <SelectableTag
            key={issueDate.id}
            onClick={() => handleClick(issueDate.id)}
            isSelected={isActive(issueDate.id)}
          >
            {formatDate(issueDate.date)}
          </SelectableTag>
        ))}
      </Wrap>
      {!!bookedIssueIds.length && (
        <>
          <Divider my={4} />
          <Heading as="h2" size="sm" mb={2}>
            {t('ad.steps.issueDates.pastDates')}
          </Heading>
          <Wrap spacing={3}>
            {bookedIssueIds.map((issueId) => {
              const issueDate = pastIssueDates.find((d) => d.id === issueId);
              return (
                issueDate && (
                  <SelectableTag key={issueId} disabled>
                    {formatDate(issueDate.date)}
                  </SelectableTag>
                )
              );
            })}
          </Wrap>
        </>
      )}
    </>
  );
};
