import { FC } from 'react';
import { Navigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { SupportedLanguages } from '@/environment';

export const LanguageRoute: FC = () => {
  const params = useParams();
  const { i18n } = useTranslation();

  if (params.lng && SupportedLanguages.includes(params.lng)) {
    i18n.changeLanguage(params.lng);
  }

  return <Navigate to={`/${params['*'] || ''}`} />;
};
