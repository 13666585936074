import { FunctionComponent, useMemo } from 'react';
import { FormControl, FormLabel, InputLeftElement, ListItem } from '@chakra-ui/react';
import { publicApi, PublicGeoLocationListResponseItem } from '@netiva/classifieds-api';
import { useDebounce } from '@netiva/classifieds-common';
import { LocationIcon, Typeahead } from '@netiva/classifieds-ui';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdComponentProps } from '@/pages/Ad/types';

const getText = (loc: PublicGeoLocationListResponseItem) => `${loc.zipCode} ${loc.name}`.trim();

export const LocationTypeahead: FunctionComponent<AdComponentProps> = ({ attribute }) => {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.ad);

  const value = useMemo(() => (attribute && formData[attribute.id]?.value) || '', [attribute, formData]);
  const debouncedValue = useDebounce(value, 200);
  const { data: locationsData, isFetching } = publicApi.useGetGeoLocations(
    { filter: debouncedValue, maxCount: 5 },
    { skip: debouncedValue.length < 2 }
  );
  const locations = useMemo(() => locationsData?.items || [], [locationsData]);

  const handleOnChange = (value: string) => {
    if (attribute) {
      dispatch(adActions.updateFormData({ key: attribute.id, value: value }));
    }
  };

  const handleLocationSelect = (location: PublicGeoLocationListResponseItem) => {
    handleOnChange(getText(location));
  };

  if (!attribute) {
    return null;
  }

  return (
    <FormControl isRequired={attribute.mandatory} my={2}>
      <FormLabel>{attribute.name}</FormLabel>
      <Typeahead
        value={value}
        items={locations}
        onChange={(e) => handleOnChange(e.target.value)}
        onItemSelected={handleLocationSelect}
        isLoading={isFetching}
        maxLength={attribute.maxLength || undefined}
        renderItem={(item, onSelect, isHovered) => {
          const text = getText(item);
          return (
            <ListItem
              key={item.id}
              onClick={() => onSelect(item)}
              cursor="pointer"
              padding={2}
              fontWeight={text === value ? 'bold' : 'normal'}
              _hover={{
                backgroundColor: 'surface',
              }}
              backgroundColor={isHovered ? 'surface' : 'transparent'}
            >
              {text}
            </ListItem>
          );
        }}
      >
        <InputLeftElement>
          <LocationIcon />
        </InputLeftElement>
      </Typeahead>
    </FormControl>
  );
};
