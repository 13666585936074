import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CardBody, CardHeader, Flex, IconButton, Tooltip, VStack, useDisclosure } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { AddressRec } from '@netiva/classifieds-api';
import { Loader, SelectableCard } from '@netiva/classifieds-ui';

import { EditAddressModal, EmptyAddressData } from '@/pages/Addresses/components';
import { useAdAddresses } from '@/pages/Ad/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AddressLine } from './components';

export const AddressSelector: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { contactAddressId } = useAppSelector((state) => state.ad);

  const { addresses: allAddresses, isLoading } = useAdAddresses();

  const addresses = useMemo(
    () => allAddresses.filter((a) => ['Account', 'Contact'].includes(a.type)) || [],
    [allAddresses]
  );

  // preselect address
  useEffect(() => {
    if (addresses.length === 1) {
      dispatch(adActions.setContactAddressId(addresses[0].id));
    } else if (!contactAddressId) {
      const accountAddress = addresses.find((a) => a.type === 'Account');
      dispatch(adActions.setContactAddressId(accountAddress?.id));
    }
  }, [addresses, contactAddressId, dispatch]);

  const [editingAddress, setEditingAddress] = useState<AddressRec>();
  const { isOpen: isEditModalOpen, onOpen: openEditModal, onClose: closeEditModal } = useDisclosure();

  const handleCreate = () => {
    setEditingAddress({ ...EmptyAddressData, type: 'Contact' });
    openEditModal();
  };

  const handleSelect = (address: AddressRec) => {
    dispatch(adActions.setContactAddressId(address.id));
  };

  const handleEdit = (address: AddressRec) => {
    setEditingAddress(address);
    openEditModal();
  };

  return (
    <>
      <Loader isLoading={isLoading} />

      <Box>
        <Flex gap={4} wrap="wrap">
          {addresses.map((address) => (
            <SelectableCard
              key={address.id}
              isSelected={contactAddressId === address.id}
              onClick={() => handleSelect(address)}
              width={{ base: 'full', sm: 'auto' }}
              minWidth="200px"
              maxWidth={{ base: 'full', md: '300px' }}
            >
              <CardHeader padding={2}>
                {contactAddressId === address.id && <CheckCircleIcon position="absolute" top={2} right={2} />}
              </CardHeader>
              <CardBody padding={0}>
                <Flex>
                  <VStack align="stretch" spacing={0} flexShrink={1} overflow="hidden">
                    <AddressLine content={address.company} />
                    <AddressLine content={address.company2} />
                    <AddressLine content={[address.firstname, address.lastname]} />
                    <AddressLine content={[address.street, address.streetNo]} />
                    <AddressLine content={[address.zip, address.city]} />
                    <AddressLine content={address.phone} />
                    <AddressLine content={address.email} />
                  </VStack>
                  <Tooltip label={t('ui.buttons.edit')}>
                    <IconButton
                      colorScheme="neutral"
                      variant="outline"
                      icon={<FiEdit />}
                      aria-label={t('ui.buttons.edit')}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(address);
                      }}
                      marginLeft="auto"
                    />
                  </Tooltip>
                </Flex>
              </CardBody>
            </SelectableCard>
          ))}
        </Flex>
        <Button marginLeft="auto" onClick={handleCreate} colorScheme="secondary" marginTop={4}>
          {t('ad.steps.address.new')}
        </Button>
      </Box>

      <EditAddressModal address={editingAddress} isOpen={isEditModalOpen} onClose={closeEditModal} />
    </>
  );
};
