import React, { FunctionComponent, useCallback } from 'react';
import { Input } from '@chakra-ui/react';
import { useNumberInput } from '@netiva/classifieds-ui';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdAttribute } from '@/store/ad/types';

export type NumberInputProps = {
  attribute: AdAttribute;
};

export const NumberInput: FunctionComponent<NumberInputProps> = ({ attribute }) => {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.ad);

  const handleOnChange = useCallback(
    (value: string) => {
      dispatch(adActions.updateFormData({ key: attribute.id, value }));
    },
    [attribute.id, dispatch]
  );

  const inputProps = useNumberInput({
    onChange: handleOnChange,
  });

  return (
    <Input
      name={attribute.name || attribute.id.toString()}
      value={formData[attribute.id]?.value || ''}
      {...inputProps}
    />
  );
};
