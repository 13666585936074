import { FC } from 'react';
import { Button, Hide, Icon, IconButtonProps, Tooltip, useBreakpointValue } from '@chakra-ui/react';

export type ActionButtonProps = Omit<IconButtonProps, 'aria-label'> & {
  label: string;
  icon?: React.ReactElement;
  iconType?: React.ElementType;
  hideLabel?: boolean;
};

export const ActionButton: FC<ActionButtonProps> = ({ icon, iconType, label, hideLabel, ...rest }) => {
  const isTooltipDisabled = useBreakpointValue({ base: false, lg: true });
  return (
    <Tooltip label={label} hasArrow placement="top" isDisabled={isTooltipDisabled}>
      <Button
        leftIcon={iconType ? <Icon as={iconType} /> : icon}
        variant="ghost"
        colorScheme="neutral"
        aria-label={label}
        {...rest}
      >
        {!hideLabel && <Hide below="lg">{label}</Hide>}
      </Button>
    </Tooltip>
  );
};
