import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { CardProps, ChakraProps, Flex, IconButton, Image, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { DeleteIcon, DragHandleIcon, EditIcon } from '@chakra-ui/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FileInputClickBox, SelectableCard } from '@netiva/classifieds-ui';

import { getIdOrFileName } from '@/lib/utils';
import { AdFile } from '@/store/ad/types';
import { useAd } from '@/pages/Ad/hooks';

export interface ThumbnailProps extends CardProps {
  image: AdFile;
  onRemove: (e: React.MouseEvent) => void;
  onReplace: (e: React.MouseEvent) => void;
  allowReorder?: boolean;
  maxImageSize?: ChakraProps['maxWidth'];
  isFirst?: boolean;
}

export const Thumbnail: FunctionComponent<ThumbnailProps> = ({
  image,
  onRemove,
  onReplace,
  allowReorder,
  maxImageSize,
  isFirst,
  ...rest
}) => {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: getIdOrFileName(image),
  });
  const { uploadedFileData } = useAd();

  if (!image) return null;

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SelectableCard
      ref={setNodeRef}
      padding={0}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      shadow="around"
      cursor="default"
      _hover={{ bgColor: 'surface', shadow: 'heavy' }}
      overflow="hidden"
      sx={style}
      {...rest}
    >
      <Image
        borderTopRadius="default"
        objectFit="scale-down"
        width="100%"
        maxWidth={maxImageSize}
        maxHeight={maxImageSize}
        src={image.url || uploadedFileData.find((fd) => fd.fileName === image.fileName)?.dataUrl || ''}
        alt={t('ad.steps.files.images.label', { count: 1 })}
        fallback={<Spinner alignSelf="center" mt={2} />}
        fallbackStrategy="beforeLoadOrError"
      />
      {allowReorder && isFirst && (
        <Text textAlign="center" p={1}>
          {t('ad.steps.files.images.mainImage')}
        </Text>
      )}
      <Flex justify="center" borderBottomRadius="default" bgColor="surface" boxShadow="default">
        <Tooltip label={t('ad.steps.files.images.replace')}>
          <FileInputClickBox>
            <IconButton
              icon={<EditIcon />}
              variant="unstyled"
              onClick={onReplace}
              aria-label={t('ad.steps.files.images.replace')}
            />
          </FileInputClickBox>
        </Tooltip>
        <Tooltip label={t('ad.steps.files.images.remove')}>
          <IconButton
            icon={<DeleteIcon />}
            variant="unstyled"
            onClick={onRemove}
            aria-label={t('ad.steps.files.images.remove')}
          />
        </Tooltip>
        {allowReorder && (
          <Tooltip label={t('ad.steps.files.images.move')}>
            <IconButton
              icon={<DragHandleIcon />}
              variant="unstyled"
              {...attributes}
              {...listeners}
              cursor="grab"
              aria-label={t('ad.steps.files.images.move')}
            />
          </Tooltip>
        )}
      </Flex>
    </SelectableCard>
  );
};
