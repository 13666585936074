import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { accountApi } from '@netiva/classifieds-api';
import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListTerm,
  Loader,
  MissingImageIcon,
  SelectableTag,
} from '@netiva/classifieds-ui';

import { DataObjectTypes } from '@/constants';
import { useCurrencyFormatter, useDate } from '@/hooks';
import { AdListItem } from '../types';

export type UneditableModalProps = Omit<ModalProps, 'children'> & {
  item?: AdListItem;
};

export const UneditableModal: FC<UneditableModalProps> = ({ item, ...rest }) => {
  const { t } = useTranslation();
  const { formatDate } = useDate();

  const isPrintAdvert = item?.type === DataObjectTypes.EInserat || item?.type === DataObjectTypes.ChMediaPdf;
  const dataObjectId = item?.id;
  const { data: printAdvertData, isFetching: isLoadingPrintAdvert } = accountApi.useGetPrintAdvertById(
    { id: dataObjectId! },
    {
      skip: !dataObjectId || !isPrintAdvert,
    }
  );

  const { data: issueDatesData, isFetching: isLoadingIssueDates } = accountApi.useGetPrintAdvertIssueDates({
    dataObjectId,
  });
  const allIssues = useMemo(() => {
    const issueDatesMap = new Map(issueDatesData?.dates.map((x) => [x.id, x.date]));
    const allIds = [...(printAdvertData?.bookedIssueIds || []), ...(printAdvertData?.selectedIssueIds || [])];
    return allIds.map((id) => issueDatesMap.get(id)).filter((x) => x);
  }, [issueDatesData?.dates, printAdvertData?.bookedIssueIds, printAdvertData?.selectedIssueIds]);

  const { format: formatCurrency } = useCurrencyFormatter(printAdvertData?.priceSummary.currency);

  if (!item) {
    return null;
  }

  const alertContent =
    t(`myads.uneditableModal.alert.${printAdvertData?.dataObject.platform?.toLowerCase()}`, '') ||
    t('myads.uneditableModal.alert');

  return (
    <Modal isCentered size={['full', 'md']} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Text fontSize="xl" fontWeight="bold">
            {item.title}
          </Text>

          <Box mt={4}>
            {item.imageUrl ? (
              <Image src={item.imageUrl} alt={item.title} border="default" width="full" objectFit="contain" />
            ) : (
              <Flex height="full" flexShrink={0} marginBottom={2} justify="center" align="center">
                <Icon as={MissingImageIcon} boxSize={32} />
              </Flex>
            )}
          </Box>

          {item.description && <Text dangerouslySetInnerHTML={{ __html: item.description }} mt={4}></Text>}

          {isLoadingIssueDates || isLoadingPrintAdvert || printAdvertData ? (
            <Box position="relative" minHeight={50} mt={4}>
              <Loader isLoading={isLoadingPrintAdvert || isLoadingIssueDates} />
              {printAdvertData && (
                <>
                  <Text fontWeight="bold">{t('ad.steps.review.issueDates')}</Text>
                  <Wrap spacing={3} mt={2}>
                    {allIssues.map((issue) => (
                      <SelectableTag key={issue} isSelected disabled>
                        {formatDate(issue)}
                      </SelectableTag>
                    ))}
                  </Wrap>
                  <DescriptionList variant="alignRight" mt={4}>
                    <DescriptionListTerm>
                      {t('price.print.grossTotalFormat', { count: allIssues.length })}
                    </DescriptionListTerm>
                    <DescriptionListItem>
                      {formatCurrency(printAdvertData.priceSummary.grossAmount * allIssues.length)}
                    </DescriptionListItem>
                  </DescriptionList>
                </>
              )}
            </Box>
          ) : null}

          {alertContent && (
            <Alert status="info" mt={4}>
              <AlertIcon />
              <AlertDescription dangerouslySetInnerHTML={{ __html: alertContent }} />
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={rest.onClose} colorScheme="neutral">
            {t('ui.buttons.close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
