import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CardBody, CardHeader, chakra, Flex, Heading, StackDivider, Text, VStack } from '@chakra-ui/react';
import { publicApi, PublicConfigurationPlatformListResponseItem } from '@netiva/classifieds-api';
import { groupBy, hasFlag, sortBy } from '@netiva/classifieds-common';
import { Loader, SelectableCard } from '@netiva/classifieds-ui';

import { PlatformIcon } from '@/components/ui';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { useAdNavigation, useAdParams } from '@/pages/Ad/hooks';
import { isPrintType } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/lib/routes';
import { CommonStepKeys } from '@/pages/Ad/constants';

const Container = chakra(Flex, {
  baseStyle: {
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

export const PlatformSelector: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { account } = useAppSelector((state) => state.account);
  const { platform, dataObjectType } = useAppSelector((state) => state.ad);
  const { platforms: allPlatforms } = useAppSelector((state) => state.global);
  const platforms = useMemo(
    () =>
      sortBy(allPlatforms, (p) => p.sortOrder).filter((platform) => {
        return !(
          (hasFlag(account?.accountStatus, 'LockedClassifieds') &&
            platform.dataObjectTypes.some((type) => type.type === 'Default')) ||
          (hasFlag(account?.accountStatus, 'LockedPrint') &&
            platform.dataObjectTypes.some((type) => isPrintType(type.type)))
        );
      }),
    [account?.accountStatus, allPlatforms]
  );
  const groupedPlatforms = useMemo(() => [...groupBy(platforms, (p) => p.group)], [platforms]);
  const { gotoNextStep, gotoStep } = useAdNavigation();
  const [isHidden, setIsHidden] = useState(true);

  const { isLoading: isLoadingPlatforms } = publicApi.useGetConfigurationPlatforms({});
  const selectedPlatform = useMemo(() => platforms.find((p) => p.name === platform), [platform, platforms]);

  const { platform: platformParam, category: categoryParam } = useAdParams();

  useEffect(() => {
    if (!isLoadingPlatforms && platforms.length === 0) {
      navigate(routes.home());
    }
  }, [isLoadingPlatforms, navigate, platforms]);

  useEffect(() => {
    if (!isLoadingPlatforms && platforms.length === 0) {
      navigate(routes.home());
    }
  }, [isLoadingPlatforms, navigate, platforms]);

  const handlePlatformSelect = useCallback(
    (platform: PublicConfigurationPlatformListResponseItem) => {
      dispatch(adActions.setPlatform(platform.name));
      if (platform.dataObjectTypes.length === 1) {
        dispatch(adActions.setDataObjectType(platform.dataObjectTypes[0].id));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    let goNext = false;

    if (!isLoadingPlatforms && platforms.length === 1) {
      dispatch(adActions.setPlatform(platforms[0].name));

      if (platforms[0].dataObjectTypes.length === 1) {
        dispatch(adActions.setDataObjectType(platforms[0].dataObjectTypes[0].id));
        goNext = true;
      }
    }

    if (goNext) {
      setIsHidden(true);
      gotoNextStep(true);
    } else {
      setIsHidden(false);
    }

    // check url platform parameters
    if (platformParam) {
      const initialPlatform = platforms.find((p) => p.name.toLocaleLowerCase() === platformParam.toLocaleLowerCase());

      if (initialPlatform) {
        handlePlatformSelect(initialPlatform);
        gotoStep(CommonStepKeys.category, true, categoryParam ? { category: categoryParam } : {});
      }
    }
  }, [
    categoryParam,
    dispatch,
    gotoNextStep,
    gotoStep,
    handlePlatformSelect,
    isLoadingPlatforms,
    platformParam,
    platforms,
  ]);

  const handleTypeSelect = (type: number) => {
    dispatch(adActions.setDataObjectType(type));
  };

  if (isHidden) {
    return null;
  }

  return (
    <>
      <Loader isLoading={isLoadingPlatforms} />
      <VStack align="stretch" divider={<StackDivider />} spacing={4}>
        {groupedPlatforms.map(([key, groupPlatforms]) => {
          const groupName = t(`ad.steps.platform.groups.${key?.toLowerCase()}.name`, { defaultValue: '' }) as string;
          const groupDescription = t(`ad.steps.platform.groups.${key?.toLowerCase()}.description`, {
            defaultValue: '',
          }) as string;
          return (
            <Box key={key}>
              {groupName ? (
                <Text fontSize="lg" mb={1} fontWeight="bold">
                  {groupName}
                </Text>
              ) : null}
              {groupDescription ? <Text mb={2}>{groupDescription}</Text> : null}
              <Container my={2}>
                {groupPlatforms.map((p) => (
                  <SelectableCard
                    key={p.name}
                    onClick={() => handlePlatformSelect(p)}
                    isSelected={p.name === platform}
                    width={{ base: 'full', lg: '20%' }}
                  >
                    <CardHeader>
                      <Heading as="h3" size="sm" textAlign="center">
                        {t(`platforms.${p.name.toLowerCase()}`, { defaultValue: p.name })}
                      </Heading>
                    </CardHeader>
                    <CardBody>
                      <Flex justifyContent="center" alignItems="center">
                        <PlatformIcon name={p.name} />
                      </Flex>
                    </CardBody>
                  </SelectableCard>
                ))}
              </Container>
            </Box>
          );
        })}

        {selectedPlatform && selectedPlatform.dataObjectTypes.length > 1 && (
          <Container>
            {selectedPlatform.dataObjectTypes.map((type) => (
              <SelectableCard
                key={type.id}
                onClick={() => handleTypeSelect(type.id)}
                isSelected={type.id === dataObjectType}
                width={{ base: 'full', lg: '20%' }}
              >
                {t(`ad.steps.platform.types.${type.type.toLowerCase()}`, { defaultValue: type.type.toLowerCase() })}
              </SelectableCard>
            ))}
          </Container>
        )}
      </VStack>
    </>
  );
};
