import { QueryParams, getCulture, getQueryString } from '@netiva/classifieds-common';

import { paths } from '@/config';
import { ApiBaseUrl } from '@/environment';

export const routes = {
  account: () => paths.ACCOUNT,
  ad: (id?: number, step?: string, stepId?: number) =>
    paths.AD.replace(':id', (id || 0).toString())
      .replace(':step', step || '')
      .replace(':stepId?', stepId?.toString() || ''),
  addresses: () => paths.ADDRESSES,
  home: () => paths.HOME,
  invitations: () => paths.INVITATIONS,
  login: () => paths.LOGIN,
  logins: () => paths.LOGINS,
  logout: () => paths.LOGOUT,
  myAds: (params?: QueryParams) => paths.MYADS + getQueryString(params),
  myAdsPrint: (accountId: number, platform?: string, language?: string) => {
    return `${ApiBaseUrl}/v1/File/AdvertListPdf/${accountId}?platform=${platform || ''}&culture=${
      getCulture(language) || ''
    }`;
  },
  paymentOverview: (contractId: number, token: string | undefined) =>
    `${paths.PAYMENT}/${paths.PAYMENT_OVERVIEW.replace(':contractId', contractId.toString())}?token=${token || ''}`,
  paymentProcessing: (orderId: number, provider: string, token: string | undefined) =>
    `${paths.PAYMENT}/${paths.PAYMENT_PROCESSING.replace(':orderId', orderId.toString())}?provider=${provider}&token=${token || ''}`,
  printQuote: (dataObjectId: number, token: string | undefined) =>
    `${paths.PRINT_QUOTE.replace(':dataObjectId', dataObjectId.toString())}?token=${token}`,
  products: () => paths.PRODUCTS,
};
