import { FC, memo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, AlertIcon, AlertTitle, Box, Button, Text } from '@chakra-ui/react';

import { useAppSelector } from '@/store';
import { useAuth } from '@/hooks';
import { routes } from '@/lib/routes';

export const LogoutPage: FC = memo(function LogoutPage() {
  const { isLoading, isLocked } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  const handleLoginClick = () => {
    navigate(routes.home());
  };

  if (isAuthenticated && !isLoading && !isLocked) {
    return <Navigate to={routes.home()} />;
  }

  return (
    <Box>
      {isLocked ? (
        <Alert status="error" marginBottom={25}>
          <AlertIcon />
          <AlertTitle dangerouslySetInnerHTML={{ __html: t('logout.lockedMessage') }} />
        </Alert>
      ) : (
        <>
          <Text marginBottom={10}>{t('logout.message')}</Text>
          <Text marginBottom={10}>
            <Button onClick={handleLoginClick}>{t('logout.login')}</Button>
          </Text>
        </>
      )}
    </Box>
  );
});
