export const ProductGroups = {
  None: 'None',
  GuestPrefix: 'Guest:',
  GuestBase: 'Guest:Base',
  GuestExtension: 'Guest:Extension',
  MemberPrefix: 'Member:',
  MemberRent: 'Member:Rent',
  MemberStandard: 'Member:Standard',
  MemberTopPlacement: 'Member:TopPlacement',
};

// The following constants directly relate to the configured 'ProductGroup' product restriction
export const DefaultProductGroups = [ProductGroups.GuestBase, ProductGroups.None];
export const SubscriptionProductGroups = [ProductGroups.MemberStandard];
export const ExtensionProductGroups = [
  ProductGroups.GuestExtension,
  ProductGroups.MemberTopPlacement,
  ProductGroups.None,
];

export const DataObjectTypes = {
  Default: 1,
  EInserat: 4,
  ChMediaPdf: 8,
} as const;
