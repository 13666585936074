import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';
import { accountApi, AddressRec } from '@netiva/classifieds-api';
import { useAppSelector } from '@/store';

export const useAddress = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const { account } = useAppSelector((state) => state.account);

  const [createAddressApi, { isLoading: isCreating }] = accountApi.useCreateAddress();
  const [updateAddressApi, { isLoading: isUpdating }] = accountApi.useUpdateAddress();
  const [deleteAddressApi, { isLoading: isDeleting }] = accountApi.useDeleteAddress();
  const [createAccount, { isLoading: isCreatingAccount }] = accountApi.useCreateAccount();

  const isLoading = isCreating || isUpdating || isDeleting || isCreatingAccount;

  const saveAddress = useCallback(
    async (address: AddressRec) => {
      try {
        if (address.id > 0) {
          await updateAddressApi({
            id: address.id,
            accountAddressesUpdateAddressRequest: {
              address,
            },
          }).unwrap();
        } else {
          if (account) {
            await createAddressApi({
              accountAddressesCreateAddressRequest: { address },
            }).unwrap();
          } else {
            await createAccount({
              accountAccountCreateAccountRequest: { address },
            }).unwrap();
          }
        }

        toast({ status: 'success', description: t('address.save.success') });
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    [account, createAccount, createAddressApi, t, toast, updateAddressApi]
  );

  const deleteAddress = useCallback(
    async (id: number) => {
      try {
        await deleteAddressApi({ id }).unwrap();
        toast({ status: 'success', description: t('address.delete.success') });
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    [deleteAddressApi, t, toast]
  );

  return {
    saveAddress,
    deleteAddress,
    isLoading,
  };
};
