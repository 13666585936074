import { ReactNode, useCallback } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import { parseQueryValue, usePaginationCore, UsePaginationProps } from '@netiva/classifieds-common';

export const usePagination = ({ totalPages, maxPages }: Omit<UsePaginationProps, 'currentPage'>) => {
  const [searchParams] = useSearchParams();
  const currentPage = parseQueryValue(searchParams.get('p'), parseInt) || 1;

  const paginationResult = usePaginationCore({ currentPage, totalPages, maxPages });

  const renderLink = useCallback(
    (page: number, children: ReactNode) => {
      const params = new URLSearchParams(searchParams);
      params.set('p', page.toString());
      return (
        <Link as={RouterLink} key={page} to={`?${params.toString()}`}>
          {children}
        </Link>
      );
    },
    [searchParams]
  );

  return {
    ...paginationResult,
    renderLink,
  };
};
