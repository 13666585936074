import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '@/store';
import { AdStepItem, adActions } from '@/store/ad';

export type PropertyFormControlProps = {
  stepItem: AdStepItem;
};

export const PropertyFormControl: FC<PropertyFormControlProps> = ({ stepItem }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { propertyFormData } = useAppSelector((state) => state.ad);

  const key = stepItem.attributeKey!;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(adActions.updatePropertyFormData({ key, value: e.target.value }));
  };

  return (
    <FormControl my={2}>
      <FormLabel>
        {/* @ts-expect-error dynamic translation */}
        {t(`ad.property.${key.toLowerCase()}`)}
      </FormLabel>
      <Input name={key} value={propertyFormData[key]?.value || ''} onChange={handleOnChange} />
    </FormControl>
  );
};
