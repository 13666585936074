import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, FormControl, FormLabel, Text, useToast } from '@chakra-ui/react';
import { resizeImage } from '@netiva/classifieds-common';
import { FileInput, FileInputClickBox, Loader } from '@netiva/classifieds-ui';
import { v4 as uuid } from 'uuid';

import { getIdOrFileName } from '@/lib/utils';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdFile } from '@/store/ad/types';
import { AdComponentProps } from '@/pages/Ad/types';
import { EditModal, ImagePanel } from './components';
import { useAd } from '@/pages/Ad/hooks';
import { UploadedFile } from '@/pages/Ad/providers';

import { ImageUploadLimit } from '@/environment';

export type ImageSelectorProps = AdComponentProps & {
  multiple?: boolean;
  resizeWidth?: number;
  resizeHeight?: number;
};

export const ImageSelector: FC<ImageSelectorProps> = ({ multiple, resizeWidth = 1920, resizeHeight = 1080 }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const dispatch = useAppDispatch();
  const { images } = useAppSelector((state) => state.ad);
  const { setUploadedFiles } = useAd();
  const [uploadedImage, setUploadedImage] = useState<File>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [replacingImage, setReplacingImage] = useState<AdFile>();
  const [isResizing, setIsResizing] = useState(false);

  const hasImage = images.length > 0;

  const showInvalidFileToast = (fileName: string) => {
    toast({
      status: 'error',
      title: t('ad.steps.files.images.invalidFile.title'),
      description: t('ad.steps.files.images.invalidFile.description', { name: fileName }),
      duration: 10000,
      isClosable: true,
    });
  };

  const showFileLimitReachedNotification = (limit: number) => {
    toast({
      status: 'error',
      title: t('ad.steps.files.images.fileLimit.title'),
      description: t('ad.steps.files.images.fileLimit.description', { limit }),
      duration: 10000,
      isClosable: true,
    });
  };

  const createImageFromBlob = async (blob: File | Blob) => {
    const file = new File([blob], `${uuid()}-${'name' in blob ? blob.name : ''}`, { type: blob?.type });
    setIsResizing(true);
    const resizedFile = await resizeImage(file, resizeWidth, resizeHeight);
    setIsResizing(false);
    const newImage: AdFile = {
      id: 0,
      fileName: resizedFile.name,
    };
    const newFile: UploadedFile = {
      fileName: newImage.fileName,
      file: resizedFile,
    };

    return { newImage, newFile };
  };

  const handleSelectFiles = async (files: FileList) => {
    const totalImagesLength = files.length + images.length;

    if (totalImagesLength > ImageUploadLimit) {
      showFileLimitReachedNotification(ImageUploadLimit);
      return;
    }

    if (files.length === 1) {
      const file = files[0];
      if (file.type.startsWith('image/')) {
        setIsResizing(true);
        const resizedFile = await resizeImage(file, resizeWidth, resizeHeight);
        setIsResizing(false);
        setUploadedImage(resizedFile);
        setIsEditModalOpen(true);
      } else {
        showInvalidFileToast(file.name);
      }
    } else {
      // create a copy of the file list as it gets disposed during async execution
      const filesCopy = [...files];
      for (let i = 0; i < filesCopy.length; i++) {
        const file = filesCopy[i];
        if (file.type.startsWith('image/')) {
          const { newImage, newFile } = await createImageFromBlob(file);
          setUploadedFiles((prev) => [...prev, newFile]);
          dispatch(adActions.addImage(newImage));
        } else {
          showInvalidFileToast(file.name);
        }
      }
    }
  };

  const handlEditDone = async (blob?: Blob) => {
    setIsEditModalOpen(false);

    if (!blob) {
      return;
    }

    const { newImage, newFile } = await createImageFromBlob(blob);
    if (replacingImage || (!multiple && images.length)) {
      const oldImage = replacingImage || images[0];
      setUploadedFiles((prev) => prev.filter((f) => f.fileName !== oldImage.fileName).concat(newFile));
      dispatch(adActions.replaceImage({ oldImage, newImage }));
      setReplacingImage(undefined);
    } else {
      setUploadedFiles((prev) => [...prev, newFile]);
      dispatch(adActions.addImage(newImage));
    }
    setUploadedImage(undefined);
  };

  const handleRemove = (e: React.MouseEvent, image?: AdFile) => {
    e.preventDefault();
    e.stopPropagation();
    const img = image || images[0];
    if (img) {
      setUploadedFiles((prev) => prev.filter((f) => getIdOrFileName(f) !== getIdOrFileName(img)));
      dispatch(adActions.removeImage(img));
    }
  };
  const handleReplace = (e: React.MouseEvent, image: AdFile) => {
    setReplacingImage(image);
  };

  return (
    <>
      <FormControl position="relative">
        <Loader isLoading={isResizing} showOverlay />
        <FormLabel>{t('ad.steps.files.images.label', { count: multiple ? 2 : 1, limit: ImageUploadLimit })}</FormLabel>
        <FileInput onFilesSelected={handleSelectFiles} accept="image/*" multiple={multiple}>
          {!hasImage && <Text>{t('ad.steps.files.images.drag')}</Text>}
          <ImagePanel
            maxImageSize={multiple ? '150px' : '300px'}
            onRemoveImage={handleRemove}
            onReplaceImage={handleReplace}
            allowReorder={multiple}
            mb={4}
          />
          <Flex wrap="wrap" gap={2}>
            <FileInputClickBox>
              <Button type="button" size="sm" colorScheme="secondary">
                {t(!multiple && hasImage ? 'ad.steps.files.images.replace' : 'ad.steps.files.images.upload')}
              </Button>
            </FileInputClickBox>
          </Flex>
        </FileInput>
      </FormControl>

      <EditModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        file={uploadedImage}
        onDone={handlEditDone}
      />
    </>
  );
};
