import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getCulture } from '@netiva/classifieds-common';

// TODO translate currency label?
export const useCurrencyFormatter = (currency: string | null = 'CHF') => {
  const { i18n } = useTranslation();
  const culture = useMemo(() => getCulture(i18n.language), [i18n.language]);
  const formatter = useMemo(
    () => new Intl.NumberFormat(culture, { style: 'currency', currency: currency || 'CHF' }),
    [currency, culture]
  );
  return formatter;
};
