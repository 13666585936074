import { useCallback, useContext, useEffect, useMemo } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AuthContext } from 'react-oauth2-code-pkce';

import { AuthMode } from '@/authentication';
import { getLoginRequest, getResetPasswordRequest } from '@/authentication/msal';
import { resetPasswordUrl, setAccessToken } from '@/authentication/oauth';
import { routes } from '@/lib/routes';

export const useAuth = () => {
  const oauthContext = useContext(AuthContext);
  const msalContext = useMsal();
  const msalIsAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setAccessToken(oauthContext.token);
  }, [oauthContext.token]);

  const login = useCallback(async () => {
    switch (AuthMode) {
      case 'msal':
        await msalContext.instance.loginRedirect(getLoginRequest());
        break;
      case 'oauth':
        oauthContext.login();
        break;
      default:
        console.error('Invalid authentication mode');
        break;
    }
  }, [msalContext.instance, oauthContext]);

  const logout = useCallback(async () => {
    switch (AuthMode) {
      case 'msal':
        await msalContext.instance.logoutRedirect();
        break;
      case 'oauth':
        // TODO logout doesn't redirect to the logout page at the moment
        oauthContext.logOut();
        window.location.href = routes.logout();
        break;
      default:
        console.error('Invalid authentication mode');
        break;
    }
  }, [msalContext.instance, oauthContext]);

  const loginEmail = useMemo<string | null | undefined>(() => {
    switch (AuthMode) {
      case 'msal': {
        const account = msalContext.accounts[0];
        return account?.username || account?.idTokenClaims?.emails?.[0] || account?.idTokenClaims?.email;
      }
      case 'oauth': {
        return oauthContext.tokenData?.email;
      }
      default:
        console.error('Invalid authentication mode');
        return null;
    }
  }, [msalContext, oauthContext]);

  const changePassword = useCallback(async () => {
    switch (AuthMode) {
      case 'msal':
        await msalContext.instance.loginRedirect(getResetPasswordRequest(loginEmail));
        break;
      case 'oauth':
        window.location.href = resetPasswordUrl;
        break;
      default:
        console.error('Invalid authentication mode');
        break;
    }
  }, [loginEmail, msalContext.instance]);

  const isLoading = msalContext.inProgress !== InteractionStatus.None || oauthContext.loginInProgress;

  return {
    isAuthenticated: msalIsAuthenticated || !!oauthContext.token,
    isLoading,
    loginEmail,
    login,
    logout,
    changePassword,
  };
};
