import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

export const SidebarParts = ['container', 'section', 'list', 'listItem', 'listItemLink'] as const;

export const SidebarComponentName = 'Sidebar' as const;

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(SidebarParts);

const containerStyle = defineStyle({
  alignItems: 'stretch',
  bg: 'white',
  borderRight: 'default',
  padding: 4,
  transition: 'default',
  width: { base: 'full', md: '250px', lg: '300px' },
});

const sectionStyle = defineStyle({});

const listStyle = defineStyle({
  listStyleType: 'none',
});

const listItemStyle = defineStyle({
  marginBottom: 2,
});

const listItemLinkStyle = defineStyle({
  display: 'block',
  borderRadius: 'default',
  bgColor: 'surface',
  paddingX: 4,
  paddingY: 2,
  textDecoration: 'none',
  transition: '0.5s',
  fontWeight: 'bold',

  _hover: {
    bgColor: 'surfaceHover',
  },
});

const baseStyle = definePartsStyle({
  container: containerStyle,
  section: sectionStyle,
  list: listStyle,
  listItem: listItemStyle,
  listItemLink: listItemLinkStyle,
});

export const Sidebar = defineMultiStyleConfig({
  baseStyle,
});
