import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Heading, VStack, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { api } from '@netiva/classifieds-api';
import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListTerm,
  FormSelect,
  SelectOption,
} from '@netiva/classifieds-ui';

import { SupportedLanguages } from '@/environment';
import { useAuth } from '@/hooks';
import { useAppSelector } from '@/store';

type LoginSettings = {
  language?: string;
};

export const AccountPage: FC = memo(function AccountPage() {
  const { t } = useTranslation();
  const { loginEmail, changePassword } = useAuth();
  const { account } = useAppSelector((state) => state.account);

  const toast = useToast();
  const { data: loginSettings, isLoading } = api.useGetV1AccountAccountLoginSettingsQuery({});
  const [updateSettings, updateStatus] = api.usePutV1AccountAccountLoginSettingsMutation();
  const settingsForm = useForm<LoginSettings>({
    values: { language: loginSettings?.language || undefined },
  });

  const languageOptions = useMemo<SelectOption[]>(
    () =>
      SupportedLanguages.map((lng) => ({
        // @ts-expect-error dynamic translation
        label: t(`languages.${lng}`),
        value: lng,
      })),
    [t]
  );

  const handleSubmit = async (values: LoginSettings) => {
    try {
      await updateSettings({ accountAccountUpdateLoginSettingsRequest: values }).unwrap();
      toast({ status: 'success', description: t('account.settings.success') });
    } catch (err) {
      console.error(err);
      toast({ status: 'error', description: t('account.settings.error') });
    }
  };

  return (
    <VStack spacing={8} align="stretch">
      <Box width={{ base: 'full', md: '500px' }}>
        <Heading as="h2" size="sm" mb={3}>
          {t('account.info')}
        </Heading>
        <DescriptionList>
          <DescriptionListTerm>{t('account.login')}</DescriptionListTerm>
          <DescriptionListItem>{loginEmail}</DescriptionListItem>

          {account && (
            <>
              <DescriptionListTerm>{t('account.account')}</DescriptionListTerm>
              <DescriptionListItem>{account?.description}</DescriptionListItem>
            </>
          )}

          <DescriptionListTerm>{t('account.password')}</DescriptionListTerm>
          <DescriptionListItem>
            <Button onClick={changePassword}>{t('account.changePassword')}</Button>
          </DescriptionListItem>
        </DescriptionList>
      </Box>

      <Box width={{ base: 'full', md: '500px' }}>
        <Heading as="h2" size="sm" mb={3}>
          {t('account.settings')}
        </Heading>
        <FormProvider {...settingsForm}>
          <form {...settingsForm} onSubmit={settingsForm.handleSubmit(handleSubmit)}>
            <FormSelect<LoginSettings>
              name="language"
              label={t('account.settings.language')}
              options={languageOptions}
            />
            <Button type="submit" isLoading={isLoading || updateStatus.isLoading}>
              {t('ui.buttons.save')}
            </Button>
          </form>
        </FormProvider>
      </Box>
    </VStack>
  );
});
