import { useMemo } from 'react';
import { isBefore } from 'date-fns';
import { AccountPrintAdvertLoadIssueDatesResponseItem, accountApi } from '@netiva/classifieds-api';

import { useAppSelector } from '@/store';

export const usePrintIssueDates = (dataObjectId: number | undefined) => {
  const { bookedIssueIds, selectedIssueIds } = useAppSelector((state) => state.ad.print);

  const { data: issueDatesData, isLoading } = accountApi.useGetPrintAdvertIssueDates({
    dataObjectId: dataObjectId || undefined,
  });
  const issueDates = useMemo(() => issueDatesData?.dates || [], [issueDatesData]);
  const issueDatesMap = useMemo(() => new Map(issueDates.map((d) => [d.id, d])), [issueDates]);
  const bookedIssueDates = useMemo(
    () =>
      bookedIssueIds
        .map((id) => issueDatesMap.get(id))
        .filter((x): x is AccountPrintAdvertLoadIssueDatesResponseItem => !!x),
    [issueDatesMap, bookedIssueIds]
  );
  const selectedIssueDates = useMemo(
    () =>
      selectedIssueIds
        .map((id) => issueDatesMap.get(id))
        .filter((x): x is AccountPrintAdvertLoadIssueDatesResponseItem => !!x),
    [issueDatesMap, selectedIssueIds]
  );

  const futureIssueDates = useMemo(() => {
    const now = new Date();
    return issueDates.filter((d) => !isBefore(new Date(d.closeDateTime), now));
  }, [issueDates]);
  const pastIssueDates = useMemo(() => {
    const now = new Date();
    return issueDates.filter((d) => isBefore(new Date(d.closeDateTime), now));
  }, [issueDates]);

  return {
    bookedIssueDates,
    bookedIssueIds,
    selectedIssueDates,
    selectedIssueIds,
    isLoading,
    issueDates,
    futureIssueDates,
    pastIssueDates,
  };
};
