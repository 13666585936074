import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Image, StackDivider, Text, VStack, Wrap, chakra } from '@chakra-ui/react';
import { hasFlag } from '@netiva/classifieds-common';
import { SelectableTag } from '@netiva/classifieds-ui';

import { PrintPriceSummary } from '@/components/ui';
import { useDate } from '@/hooks';
import type { ContractDetailsProps } from './ContractDetails';
import { useAppSelector } from '@/store';

const Column = chakra(Box, {
  baseStyle: {
    minWidth: '300px',
    maxWidth: '500px',
    mb: 4,
    paddingRight: 4,
    borderRight: { base: undefined, lg: 'default' },
    '&:last-child': {
      paddingRight: 0,
      borderRight: 'none',
    },
  },
});

const ColumnTitle = chakra(Text, {
  baseStyle: {
    fontWeight: 'bold',
    mb: 2,
  },
});

export const PrintContractDetails: FC<ContractDetailsProps> = ({ contract }) => {
  const { t } = useTranslation();
  const { formatDate } = useDate();
  const { account } = useAppSelector((x) => x.account);

  const { printDetails } = contract;

  if (!printDetails) {
    return null;
  }

  const requiresPrepayment = hasFlag(account?.accountStatus, 'RequiresPrepayment');
  const payBefore = formatDate(contract.payBefore, true);
  const message = requiresPrepayment
    ? t('payment.message.print.requiresPrepayment', { date: payBefore })
    : t('payment.message.print', { date: payBefore });

  return (
    <VStack align="stretch" divider={<StackDivider />} spacing={4}>
      <Box>
        <Text dangerouslySetInnerHTML={{ __html: message }} />
      </Box>
      <Flex flexWrap={{ base: 'wrap', lg: 'nowrap' }} gap={4}>
        <Column>
          <ColumnTitle>{t('price.title')}</ColumnTitle>
          <PrintPriceSummary
            price={printDetails.price}
            issueCount={printDetails.issueDates.length}
            showMultipleIssues
            unified
          />
        </Column>
        <Column>
          <ColumnTitle>{t('ad.steps.review.issueDates')}</ColumnTitle>
          <Wrap spacing={3}>
            {printDetails.issueDates.map((issueDate) => (
              <SelectableTag key={issueDate} disabled>
                {formatDate(issueDate)}
              </SelectableTag>
            ))}
          </Wrap>
        </Column>
        <Column>
          <ColumnTitle>{t('preview.title')}</ColumnTitle>
          <Image src={printDetails.imageUrl} mt={2} alt={t('preview.title')} border="default" boxShadow="default" />
        </Column>
      </Flex>
    </VStack>
  );
};
