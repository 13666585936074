import { useEffect } from 'react';
import { accountApi } from '@netiva/classifieds-api';

import { useAppDispatch, useAppSelector } from '@/store';
import { accountActions } from '@/store/account';
import { getSelectedAccountId } from '@/lib/storage';
import { useAuth } from './useAuth';
import { useContracts } from './useContracts';
import { ProductGroups } from '@/constants';

export const useLoadAccountData = () => {
  const dispatch = useAppDispatch();
  const { accountId } = useAppSelector((s) => s.account);
  const { isAuthenticated } = useAuth();

  // set account id from url parameter for user impersonation or from local storage to use the previously selected account
  const searchParams = new URLSearchParams(window.location.search);
  const accountIdParam = searchParams.get('aid');
  useEffect(() => {
    if (accountIdParam) {
      const aid = Number(accountIdParam);
      if (aid && !isNaN(aid)) {
        dispatch(accountActions.setAccountId(aid));
      }
    } else {
      dispatch(accountActions.setAccountId(getSelectedAccountId()));
    }
  }, [accountIdParam, dispatch]);

  // fetch account list
  accountApi.useGetAccounts({}, { skip: !isAuthenticated });

  // fetch selected account
  accountApi.useGetAccountById({ id: accountId!, 'X-AccountId': accountId! }, { skip: !isAuthenticated || !accountId });

  // fetch account invitations
  accountApi.useGetAccountInvitations({}, { skip: !isAuthenticated });

  // fetch contracts
  const { contracts } = useContracts();
  useEffect(() => {
    const hasMemberContract = contracts.some((c) => c.product?.group?.startsWith(ProductGroups.MemberPrefix));
    dispatch(accountActions.setHasMemberContract(hasMemberContract));
  }, [contracts, dispatch]);
};
