import { useEffect, useMemo } from 'react';
import { accountApi } from '@netiva/classifieds-api';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';

export const useAdAddresses = () => {
  const dispatch = useAppDispatch();
  const { billingAddressId, contactAddressId, locationAddressId } = useAppSelector((state) => state.ad);

  const { data: addressData, isLoading } = accountApi.useGetAddresses({});
  const addresses = useMemo(() => addressData?.addresses || [], [addressData?.addresses]);
  const billingAddress = useMemo(() => addresses.find((a) => a.id === billingAddressId), [addresses, billingAddressId]);
  const contactAddress = useMemo(() => addresses.find((a) => a.id === contactAddressId), [addresses, contactAddressId]);
  const locationAddress = useMemo(
    () => addresses.find((a) => a.id === locationAddressId),
    [addresses, locationAddressId]
  );

  useEffect(() => {
    if (!billingAddress && addresses.length && !isLoading) {
      dispatch(adActions.setBillingAddressId(addresses.find((a) => a.type === 'Account')?.id));
    }
  }, [addresses, billingAddress, dispatch, isLoading]);

  return {
    addresses,
    isLoading,
    billingAddress,
    contactAddress,
    locationAddress,
  };
};
