import { Fragment, FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps } from '@chakra-ui/react';
import { AccountPrintAdvertPrintAdvertRecPrice } from '@netiva/classifieds-api';
import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListProps,
  DescriptionListTerm,
} from '@netiva/classifieds-ui';

export type PrintPriceSummaryProps = BoxProps & {
  price: AccountPrintAdvertPrintAdvertRecPrice | undefined;
  issueCount: number | undefined;
  showMultipleIssues?: boolean;
  unified?: boolean;
  transparent?: boolean;
};

const PriceList = (props: DescriptionListProps) => <DescriptionList variant="alignRight" {...props} />;

export const PrintPriceSummary: FC<PrintPriceSummaryProps> = memo(function Price({
  price,
  issueCount = 1,
  showMultipleIssues,
  unified,
  transparent,
  ...rest
}: PrintPriceSummaryProps) {
  const { t } = useTranslation();

  if (!price) {
    return null;
  }

  const netAmount = price.amount;
  const vatAmount = price.vatAmount;
  const grossAmount = price.grossAmount;
  const roundingAmount = grossAmount - (netAmount + vatAmount);

  const topContent = (
    <>
      {price.positions.map((p, i) => (
        <Fragment key={i}>
          <DescriptionListTerm>{p.description}</DescriptionListTerm>
          <DescriptionListItem>
            {p.currency} {p.amount.toFixed(2)}
          </DescriptionListItem>
          {p.isBasedOnHeight && (
            <>
              <DescriptionListTerm variant="small">{t('price.print.height')}</DescriptionListTerm>
              <DescriptionListItem variant="small">{price.advertHeight}mm</DescriptionListItem>
            </>
          )}
        </Fragment>
      ))}
    </>
  );

  const bottomContent = (
    <>
      <DescriptionListTerm>{t('price.print.netTotalFormat', { count: 1 })}</DescriptionListTerm>
      <DescriptionListItem>
        {price.currency} {netAmount.toFixed(2)}
      </DescriptionListItem>
      <DescriptionListTerm variant="small">
        {t('price.vatFormat', { percent: price.vatPercentage.toFixed(1) })}
      </DescriptionListTerm>
      <DescriptionListItem variant="small">
        {price.currency} {vatAmount.toFixed(2)}
      </DescriptionListItem>
      <DescriptionListTerm variant="small">{t('price.roundingDifference')}</DescriptionListTerm>
      <DescriptionListItem variant="small">
        {price.currency} {roundingAmount.toFixed(2)}
      </DescriptionListItem>
      <DescriptionListTerm>{t('price.print.grossTotalFormat', { count: 1 })}</DescriptionListTerm>
      <DescriptionListItem>
        {price.currency} {grossAmount.toFixed(2)}
      </DescriptionListItem>
      {showMultipleIssues && issueCount > 1 && (
        <>
          <DescriptionListTerm>{t('price.print.grossTotalFormat', { count: issueCount })}</DescriptionListTerm>
          <DescriptionListItem>
            {price.currency} {(grossAmount * issueCount).toFixed(2)}
          </DescriptionListItem>
        </>
      )}
    </>
  );

  return (
    <Box marginBottom={4} {...rest}>
      {unified ? (
        <PriceList bgColor={transparent ? 'transparent' : undefined} marginBottom={4}>
          {topContent}
          {bottomContent}
        </PriceList>
      ) : (
        <>
          <PriceList bgColor="transparent" marginBottom={4}>
            {topContent}
          </PriceList>
          <PriceList bgColor={transparent ? 'transparent' : undefined} marginBottom={4}>
            {bottomContent}
          </PriceList>
        </>
      )}
    </Box>
  );
});
