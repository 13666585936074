import { DataObjectType } from '@netiva/classifieds-api';

import { BaseUrl } from '@/environment';
import { DataObjectTypes } from '@/constants';

export const enforceBaseUrl = () => {
  if (BaseUrl && !window.location.href.startsWith(BaseUrl)) {
    window.location.href = BaseUrl;
  }
};

export const getIdOrFileName = (file: { id?: number; fileName?: string }) => {
  if ('id' in file) {
    return `${file.id || file.fileName}`;
  }
  return file.fileName || '';
};

export const isPrintType = (type: DataObjectType | number | null | undefined) => {
  if (typeof type === 'string') {
    return type === 'EInserat' || type === 'ChMediaPdf';
  }
  if (typeof type === 'number') {
    return type === DataObjectTypes.EInserat || type === DataObjectTypes.ChMediaPdf;
  }
  return false;
};
