import { useMemo } from 'react';
import { accountApi } from '@netiva/classifieds-api';

import { AdStep } from '@/store/ad/types';
import { useAdParams } from './useAdParams';
import { useAppSelector } from '@/store';
import { CommonSteps } from '../constants';

export const useAdSteps = () => {
  const { categoryId, dataObjectType } = useAppSelector((state) => state.ad);
  const { currentStepKey } = useAdParams();

  const { data: stepsData, isLoading } = accountApi.useGetConfigurationProcess(
    {
      categoryId: categoryId,
      dataObjectType: dataObjectType,
    },
    { skip: !categoryId || !dataObjectType }
  );

  const steps = useMemo<AdStep[]>(() => [...CommonSteps, ...(stepsData?.steps || [])], [stepsData?.steps]);
  const currentStepIndex = useMemo(
    () => steps.findIndex((step) => step.key === currentStepKey),
    [currentStepKey, steps]
  );
  const currentStep = useMemo<AdStep | undefined>(() => steps[currentStepIndex], [steps, currentStepIndex]);

  return { currentStep, currentStepIndex, isLoading, steps };
};
