import { FC } from 'react';
import { LinkProps } from 'react-router-dom';
import { As, Badge, CardBody, CardHeader, CardProps, Icon, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { SelectableCard } from '@netiva/classifieds-ui';

import { RouterLink } from '@/components/global';

export type HomeCardProps = CardProps & {
  link: LinkProps['to'];
  header: string;
  icon: As;
  badgeCount?: number;
};

export const HomeCard: FC<HomeCardProps> = ({ link, header, icon, badgeCount, ...rest }) => (
  <LinkBox as={SelectableCard} width={{ base: 'full', sm: '50%', md: '250px' }} {...rest}>
    <LinkOverlay as={RouterLink} textDecoration="none" to={link}>
      <CardHeader fontSize="lg" textAlign="center">
        {header}
        <Badge position="absolute" top={2} right={2} colorScheme="primary" fontSize="lg">
          {badgeCount}
        </Badge>
      </CardHeader>
      <CardBody display="flex" alignItems="center" justifyContent="center">
        <Icon as={icon} boxSize={8} color="secondary" />
      </CardBody>
    </LinkOverlay>
  </LinkBox>
);
