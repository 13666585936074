import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, useToast } from '@chakra-ui/react';
import { AccountPublicationPublicationOptionListResponseContract } from '@netiva/classifieds-api';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { CardSection } from './CardSection';
import { ProductCard } from './ProductCard';

export type ContractListProps = {
  contracts: AccountPublicationPublicationOptionListResponseContract[];
};

export const ContractList: FC<ContractListProps> = ({ contracts }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedContractId } = useAppSelector((state) => state.ad);
  const toast = useToast();

  const handleContractClick = (id: number) => {
    const contract = contracts.find((c) => c.id === id);
    if (!contract) {
      return;
    }
    if (contract.available > 0) {
      dispatch(adActions.setSelectedContractId(id));
    } else {
      toast({ description: t('ad.steps.publishing.contracts.noSlotsAvailable') });
    }
  };

  useEffect(() => {
    if (!selectedContractId && contracts.length === 1 && contracts[0].available > 0) {
      dispatch(adActions.setSelectedContractId(contracts[0].id));
    }
  }, [contracts, dispatch, selectedContractId]);

  if (!contracts.length) {
    return null;
  }

  return (
    <CardSection>
      {contracts.map((contract) => {
        const isSelected = contract.id === selectedContractId;
        return (
          <ProductCard
            key={contract.id}
            title={contract.product.name}
            isSelected={isSelected}
            onClick={() => handleContractClick(contract.id)}
          >
            <Box dangerouslySetInnerHTML={{ __html: contract.product.description || '' }} mb={4} />
            {contract.available > 0 ? (
              <Text>
                {contract.quantity - contract.available} / {contract.quantity}
              </Text>
            ) : (
              <Text>{t('ad.steps.publishing.contracts.noSlotsAvailable')}</Text>
            )}
          </ProductCard>
        );
      })}
    </CardSection>
  );
};
