import { FC, Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AccountPublicationPublicationOptionListResponseProduct } from '@netiva/classifieds-api';

import { useCurrencyFormatter, useProductGroups } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { CardSection } from './CardSection';
import { ProductCard } from './ProductCard';

type Product = AccountPublicationPublicationOptionListResponseProduct;

export type ProductListProps = {
  products: Product[];
};

export const ProductList: FC<ProductListProps> = ({ products }) => {
  const { t } = useTranslation();
  const currencyFormatter = useCurrencyFormatter();
  const dispatch = useAppDispatch();
  const { selectedProductGroupIndex, selectedProductId } = useAppSelector((state) => state.ad);

  const { defaultProductGroups, subscriptionProductGroups, otherProductGroups } = useProductGroups(products);

  useEffect(() => {
    if (products.length === 1 && !selectedProductId) {
      dispatch(adActions.setSelectedProductId(products[0].id));
    }
  }, [products, dispatch, selectedProductId]);

  const handleAccordionChange = useCallback(
    (index: number) => {
      dispatch(adActions.setSelectedProductGroupIndex(index));
      // reset selection on when accordion changes
      dispatch(adActions.setSelectedProductId(undefined));
      dispatch(adActions.setSelectedExtensionProductIds([]));
    },
    [dispatch]
  );

  const handleProductClick = useCallback(
    (id: number) => {
      dispatch(adActions.setSelectedProductId(id));
    },
    [dispatch]
  );

  const renderProductGroup = useCallback(
    (group: string, products: Product[]) => {
      return (
        <AccordionItem>
          <AccordionButton>
            <VStack align="stretch" textAlign="start" py={4}>
              <Heading size="md">{t(`ad.steps.publishing.productGroups.${group}.title`, '')}</Heading>
              <Text>{t(`ad.steps.publishing.productGroups.${group}.description`, '')}</Text>
            </VStack>
            <AccordionIcon ml="auto" boxSize={8} />
          </AccordionButton>
          <AccordionPanel p={4}>
            <CardSection>
              {products.map((product) => {
                const isSelected = product.id === selectedProductId;
                return (
                  <ProductCard
                    key={product.id}
                    title={product.name}
                    subTitle={currencyFormatter.format(product.price)}
                    isSelected={isSelected}
                    onClick={() => handleProductClick(product.id)}
                  >
                    {product.description && !product.description.startsWith('#') && (
                      <Box dangerouslySetInnerHTML={{ __html: product.description || '' }} />
                    )}
                  </ProductCard>
                );
              })}
            </CardSection>
          </AccordionPanel>
        </AccordionItem>
      );
    },
    [currencyFormatter, handleProductClick, selectedProductId, t]
  );

  if (!products.length) {
    return null;
  }

  return (
    <>
      <Accordion defaultIndex={0} index={selectedProductGroupIndex} onChange={handleAccordionChange}>
        {defaultProductGroups.map(([group, products]) => (
          <Fragment key={group}>{renderProductGroup(group, products)}</Fragment>
        ))}
        {subscriptionProductGroups.map(([group, products]) => (
          <Fragment key={group}>{renderProductGroup(group, products)}</Fragment>
        ))}
        {otherProductGroups.map(([group, products]) => (
          <Fragment key={group}>{renderProductGroup(group, products)}</Fragment>
        ))}
      </Accordion>
    </>
  );
};
