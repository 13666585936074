import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading, Tooltip } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { AccountPublicationPublicationOptionListResponsePublication } from '@netiva/classifieds-api';
import { DescriptionList, DescriptionListItem, DescriptionListTerm } from '@netiva/classifieds-ui';

import { useCurrencyFormatter, useDate } from '@/hooks';
import { useAd } from '@/pages/Ad/hooks';
import { useAppDispatch } from '@/store';
import { adActions } from '@/store/ad';

export type ActivePublicationProps = {
  publication?: AccountPublicationPublicationOptionListResponsePublication;
};

export const ActivePublication: FC<ActivePublicationProps> = ({ publication: activePublication }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDate();
  const { dataObject } = useAd();

  useEffect(() => {
    if (activePublication?.contract) {
      dispatch(adActions.setSelectedContractId(activePublication.contract.id));
    }
  }, [activePublication?.contract, dispatch]);

  if (!activePublication) {
    return null;
  }

  return (
    <>
      <Heading size="sm" my={4}>
        {t('ad.steps.publishing.currentPublication')}
      </Heading>
      <DescriptionList>
        <DescriptionListTerm>{t('ad.steps.publishing.product')}</DescriptionListTerm>
        <DescriptionListItem>
          <Flex align="center" gap={2}>
            {activePublication.contract.product.name} ({currencyFormatter.format(activePublication.contract.price)})
            {!!activePublication.contract.product.description && (
              <Tooltip
                label={
                  <Box
                    padding={6}
                    dangerouslySetInnerHTML={{ __html: activePublication.contract.product.description }}
                  />
                }
              >
                <InfoIcon tabIndex={0} />
              </Tooltip>
            )}
          </Flex>
        </DescriptionListItem>

        <DescriptionListTerm>{t('ad.steps.publishing.duration')}</DescriptionListTerm>
        <DescriptionListItem>
          {t('ad.steps.publishing.dateFrom')} {dateFormatter.formatDate(activePublication.startDate)}{' '}
          {activePublication.endDate
            ? t('ad.steps.publishing.dateTo') + ' ' + dateFormatter.formatDate(activePublication.endDate)
            : t('ad.steps.publishing.noEndDate')}
        </DescriptionListItem>

        <DescriptionListTerm>{t('ad.steps.publishing.status')}</DescriptionListTerm>
        <DescriptionListItem>
          {/* @ts-expect-error dynamic translation */}
          {t(`ad.status.${dataObject?.status.toLowerCase()}`)}
        </DescriptionListItem>

        {activePublication.contract.activeExtensions.length ? (
          <>
            <DescriptionListTerm>{t('ad.steps.publishing.extensionProducts')}</DescriptionListTerm>
            {activePublication.contract.activeExtensions.map((ext) => (
              <DescriptionListItem key={ext.id}>
                {ext.product.name} ({currencyFormatter.format(ext.price)})
              </DescriptionListItem>
            ))}
          </>
        ) : null}
      </DescriptionList>
    </>
  );
};
