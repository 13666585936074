import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { AuthMode } from './AuthenticationProvider';
import { getLoginRequest } from './msal';

const OAuthAuthenticationTemplate: FC<PropsWithChildren> = ({ children }) => {
  const { login, loginInProgress, token } = useContext(AuthContext);

  useEffect(() => {
    if (!loginInProgress && !token) {
      login();
    }
  }, [login, loginInProgress, token]);

  return token ? <>{children}</> : null;
};

export const AuthenticationTemplate: FC<PropsWithChildren> = ({ children }) => {
  switch (AuthMode) {
    case 'msal':
      return (
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={getLoginRequest()}
        >
          {children}
        </MsalAuthenticationTemplate>
      );
    case 'oauth':
      return <OAuthAuthenticationTemplate>{children}</OAuthAuthenticationTemplate>;
    default:
      return null;
  }
};
