import { FC, useEffect, useState } from 'react';
import { Button, CardHeader, Flex, FlexProps, Icon, chakra } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCreditCard } from 'react-icons/fa';
import type { AccountPaymentsGetContractDetailsResponse } from '@netiva/classifieds-api';
import { Alert, SelectableCard } from '@netiva/classifieds-ui';

type ContractDetails = AccountPaymentsGetContractDetailsResponse;

export type PaymentPanelProps = FlexProps & {
  contract: ContractDetails;
  onCreatePayment: (contract: ContractDetails, provider: string) => void;
  isLoading?: boolean;
};

const PaymentProviderCardHeader = chakra(CardHeader, {
  baseStyle: {
    p: 1,
    display: 'flex',
    flexDir: 'column',
    alignItems: 'center',
    gap: 2,
    minWidth: '150px',
  },
});

export const PaymentPanel: FC<PaymentPanelProps> = ({ contract, isLoading, onCreatePayment, ...rest }) => {
  const { t } = useTranslation();

  const [paymentProvider, setPaymentProvider] = useState(contract.selectedPaymentProvider);

  useEffect(() => {
    contract.availablePaymentProviders.length === 1 && setPaymentProvider(contract.availablePaymentProviders[0]);
  }, [contract.availablePaymentProviders]);

  if (contract.paymentStatus === 'Complete') {
    return <Alert alert={{ status: 'success', description: t('payment.status.complete') }} />;
  }

  if (contract.paymentStatus === 'Refunded') {
    return <Alert alert={{ status: 'info', description: t('payment.status.refunded') }} />;
  }

  return (
    <Flex direction="column" align="flex-start" gap={8} {...rest}>
      {contract.availablePaymentProviders.length > 1 && (
        <Flex gap={8}>
          {contract.availablePaymentProviders.map((p) => (
            <SelectableCard
              key={p}
              isSelected={p.toLowerCase() === paymentProvider?.toLowerCase()}
              onClick={() => setPaymentProvider(p)}
            >
              <PaymentProviderCardHeader>
                <Icon as={FaCreditCard} boxSize={12} />
                {/* @ts-expect-error dynamic translation */}
                {t(`payment.providers.${p.toLowerCase()}`)}
              </PaymentProviderCardHeader>
            </SelectableCard>
          ))}
        </Flex>
      )}
      <Button
        size="lg"
        minWidth="200px"
        isLoading={isLoading}
        isDisabled={isLoading || !paymentProvider}
        onClick={() => onCreatePayment(contract, paymentProvider!)}
      >
        {t('payment.payButton')}
      </Button>
    </Flex>
  );
};
