export const NodeEnv = process.env.NODE_ENV;

export const AuthMode = ((import.meta.env.VITE_AUTH_MODE as string) || 'msal').toLowerCase();
export const BaseUrl = import.meta.env.VITE_BASE_URL as string;
export const ApiBaseUrl = import.meta.env.VITE_API_URL as string;
export const ConsentManagerId = import.meta.env.VITE_CONSENT_MANAGER_ID as string;
export const GoogleTagManagerId = import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID as string;
export const Instance = ((import.meta.env.VITE_INSTANCE as string) || 'default').toLowerCase();
export const Platform = import.meta.env.VITE_API_PLATFORM as string;
export const SupportedLanguages = ((import.meta.env.VITE_SUPPORTED_LANGUAGES as string) || 'de').split(',');
export const DocumentUploadLimit = import.meta.env.VITE_DOCUMENT_UPLOAD_LIMIT as number;
export const ImageUploadLimit = import.meta.env.VITE_IMAGE_UPLOAD_LIMIT as number;
