import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getQueryValue, parseQueryValue } from '@netiva/classifieds-common';

import { CommonSteps } from '../constants';

export const useAdParams = () => {
  // parse request params
  const { id: idParam, step: stepParam, stepId: stepIdParam } = useParams();
  // search params
  const [searchParams] = useSearchParams();

  const dataObjectId = useMemo(() => parseInt(idParam || '0'), [idParam]);
  const currentStepKey = useMemo(() => stepParam || CommonSteps[0].key, [stepParam]);
  const stepId = useMemo(() => (stepIdParam ? parseInt(stepIdParam) : undefined), [stepIdParam]);
  const isNew = dataObjectId === 0;
  const platform = getQueryValue(searchParams.get('platform'));
  const categoryValue = getQueryValue(searchParams.get('category'));
  const category = parseQueryValue(categoryValue, parseInt) || categoryValue;

  return {
    dataObjectId,
    currentStepKey,
    stepId,
    isNew,
    platform,
    category,
  };
};
