import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Button, SkeletonText } from '@chakra-ui/react';
import { AddressRec } from '@netiva/classifieds-api';
import { DescriptionList, DescriptionListItem } from '@netiva/classifieds-ui';

import { Country, Item } from './components';

export type AddressProps = BoxProps & {
  address?: AddressRec;
  onEdit: VoidFunction;
};

export const Address: FunctionComponent<AddressProps> = ({ address, onEdit, ...rest }) => {
  const { t } = useTranslation();

  if (!address) {
    return (
      <Box {...rest}>
        <SkeletonText width={{ base: 'full', md: '500px' }} noOfLines={5} skeletonHeight={6} />
      </Box>
    );
  }

  return (
    <Box width={{ base: 'full', md: '500px' }} {...rest}>
      <DescriptionList width="full" flexShrink={1}>
        <Item label={t('address.form.name')} value={[address.firstname, address.lastname]} />
        <Item label={t('address.form.company')} value={address.company} />
        <Item label={t('address.form.company2')} value={address.company2} />
        <Item label={t('address.form.street')} value={[address.street, address.streetNo]} />
        <Item label={t('address.form.street2')} value={address.street2} />
        <Item label={t('address.form.city')} value={[address.zip, address.city]} />
        <Item label={t('address.form.email')} value={address.email} />
        <Item label={t('address.form.phone')} value={address.phone} />
        <Item label={t('address.form.mobile')} value={address.mobile} />
        <Country label={t('address.form.country')} value={address.countryIso2} />
        <Item label={t('address.form.homepageUrl')} value={address.homepageUrl} />
        <DescriptionListItem display="flex">
          <Button onClick={onEdit} size="sm" colorScheme="neutral" marginLeft="auto">
            {t('ui.buttons.edit')}
          </Button>
        </DescriptionListItem>
      </DescriptionList>
    </Box>
  );
};
