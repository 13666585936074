import { useMemo } from 'react';
import { Select, SelectOption, SelectValue } from '@netiva/classifieds-ui';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdAttributeEntry } from '@/store/ad/types';

export type SelectionInputProps<IsMulti extends boolean> = {
  id: number;
  name: string;
  placeholder?: string;
  options: AdAttributeEntry[];
  isMulti?: IsMulti;
};

export function SelectionInput<IsMulti extends boolean>({
  id,
  name,
  placeholder,
  options,
  isMulti,
}: SelectionInputProps<IsMulti>) {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.ad);

  const selectOptions = useMemo<SelectOption<number>[]>(
    () => options.map((o) => ({ label: o.name || '', value: o.id })),
    [options]
  );

  const handleOnChange = (value: SelectValue<number, IsMulti>) => {
    dispatch(
      adActions.updateFormData({
        key: id,
        entries: value ? (typeof value === 'number' ? [value] : value) : [],
      })
    );
  };

  return (
    <Select
      name={name}
      options={selectOptions}
      placeholder={placeholder}
      isMulti={isMulti}
      isClearable
      onChange={handleOnChange}
      value={isMulti ? formData[id]?.entries : formData[id]?.entries?.[0]}
    />
  );
}
