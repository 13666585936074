import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

export const StepsParts = [
  'container',
  'steps',
  'step.inactive',
  'step.active',
  'step.completed',
  'step.invalid',
] as const;

export const StepsComponentName = 'Steps' as const;

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(StepsParts);

const baseStyleContainer = defineStyle({
  bgColor: 'surface',
  padding: '2',
  borderRadius: '40px',
  marginX: 'auto',
  marginBottom: 2,
});

const baseStyleSteps = defineStyle({
  display: 'flex',
  gap: 8,
  justifyContent: 'center',
  margin: 0,
  padding: 0,
  position: 'relative',
});

const baseStyleStep = defineStyle({
  borderRadius: '50%',
  background: 'white',
  color: 'inherit',
  listStyle: 'none',
  fontSize: 'sm',
  fontWeight: 'bold',
  margin: 0,
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  zIndex: 1,
  cursor: 'pointer',
});

const baseStyleStepInactive = defineStyle({
  ...baseStyleStep,
});

const baseStyleStepActive = defineStyle({
  ...baseStyleStep,
  background: 'primary',
});

const baseStyleStepCompleted = defineStyle({
  ...baseStyleStep,
  background: 'gray.300',
});

const baseStyleStepInvalid = defineStyle({
  ...baseStyleStep,
  background: 'red.300',
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
  steps: baseStyleSteps,
  'step.inactive': baseStyleStepInactive,
  'step.active': baseStyleStepActive,
  'step.completed': baseStyleStepCompleted,
  'step.invalid': baseStyleStepInvalid,
});

export const Steps = defineMultiStyleConfig({
  baseStyle,
});
