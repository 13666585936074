import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FiDelete, FiEdit } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';
import { AccountLoginsListLoginsResponseItem, accountApi } from '@netiva/classifieds-api';
import { parseQueryValue, useDebouncedState } from '@netiva/classifieds-common';
import { ConfirmationDialog, Loader, Pagination } from '@netiva/classifieds-ui';

import { usePagination } from '@/hooks';
import { useAppSelector } from '@/store';
import { EditLoginModal } from './components';

const PageSize = 20;

export const LoginsPage: FC = memo(function LoginsPage() {
  const { t } = useTranslation();
  const toast = useToast();
  const { account, isLoaded } = useAppSelector((state) => state.account);

  const [searchParams] = useSearchParams();
  const currentPage = parseQueryValue(searchParams.get('p'), parseInt) || 1;
  const [filter, setFilter, debouncedFilter] = useDebouncedState(500, '');

  const [removingLogin, setRemovingLogin] = useState<AccountLoginsListLoginsResponseItem>();
  const {
    isOpen: isRemoveConfirmationOpen,
    onOpen: openRemoveConfirmation,
    onClose: closeRemoveConfirmation,
  } = useDisclosure();

  const [editingLogin, setEditingLogin] = useState<AccountLoginsListLoginsResponseItem>();
  const { isOpen: isEditModalOpen, onOpen: openEditModal, onClose: closeEditModal } = useDisclosure();

  const { data: loginsList, isLoading: isLoadingLogins } = accountApi.useGetLogins({
    filter: debouncedFilter,
    pageIndex: currentPage - 1,
    pageSize: PageSize,
  });
  const [removeLogin, { isLoading: isRemovingLogin }] = accountApi.useRemoveLogin();
  const isLoading = !isLoaded || isLoadingLogins || isRemovingLogin;

  const totalPages = Math.ceil((loginsList?.totalCount || 0) / PageSize);
  const paginationProps = usePagination({ totalPages });

  const handleRemove = (login: AccountLoginsListLoginsResponseItem) => {
    setRemovingLogin(login);
    openRemoveConfirmation();
  };
  const handleRemoveConfirm = async () => {
    if (!removingLogin) {
      return;
    }

    try {
      await removeLogin({ id: removingLogin.id }).unwrap();
      toast({ status: 'success', description: t('logins.remove.success') });
    } catch (err) {
      console.error(err);
      toast({ status: 'error', description: t('logins.remove.error') });
    }
    setRemovingLogin(undefined);
    closeRemoveConfirmation();
  };
  const handleRemoveCancel = () => {
    setRemovingLogin(undefined);
    closeRemoveConfirmation();
  };

  const handleAdd = () => {
    setEditingLogin(undefined);
    openEditModal();
  };

  const handleEdit = (login: AccountLoginsListLoginsResponseItem) => {
    setEditingLogin(login);
    openEditModal();
  };

  return (
    <>
      <Loader isLoading={isLoading} showOverlay />
      <VStack align="stretch" spacing={4}>
        <Flex>
          <Text>{t('logins.heading', { account: account?.description })}</Text>
          <Button marginLeft="auto" onClick={handleAdd}>
            {t('logins.add')}
          </Button>
        </Flex>
        <FormControl>
          <FormLabel>{t('logins.filter.text')}</FormLabel>
          <Input type="text" value={filter} onChange={(e) => setFilter(e.target.value)} />
        </FormControl>
        <Flex direction="column" gap={4}>
          {loginsList?.items.map((login) => (
            <Card key={login.id} background="surface">
              <CardBody padding={4}>
                <Flex align="center" gap={4}>
                  <Text fontWeight="bold">{login.email}</Text>
                  {login.role && (
                    <Flex gap={1}>
                      <Text>{t('logins.role')}</Text>
                      <Text>
                        {/* @ts-expect-error dynamic translation */}
                        {t(`logins.roles.${login.role.toLowerCase()}`)}
                      </Text>
                    </Flex>
                  )}
                  {login.isInvited && <Text fontStyle="italic">{t('logins.pendingInvitation')}</Text>}
                  {login.role !== 'Owner' && (
                    <ButtonGroup marginLeft="auto">
                      <Tooltip label={t('ui.buttons.delete')}>
                        <IconButton
                          variant="ghost"
                          icon={<FiDelete />}
                          aria-label={t('ui.buttons.delete')}
                          onClick={() => handleRemove(login)}
                        />
                      </Tooltip>
                      <Tooltip label={t('ui.buttons.edit')}>
                        <IconButton
                          variant="ghost"
                          icon={<FiEdit />}
                          aria-label={t('ui.buttons.edit')}
                          onClick={() => handleEdit(login)}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  )}
                </Flex>
              </CardBody>
            </Card>
          ))}
        </Flex>
        <Pagination {...paginationProps} />
      </VStack>

      <ConfirmationDialog
        title={t('logins.removeConfirmation.title')}
        message={t('logins.removeConfirmation.message', { login: removingLogin?.email })}
        okText={t('ui.buttons.yes')}
        cancelText={t('ui.buttons.cancel')}
        onConfirm={handleRemoveConfirm}
        onCancel={handleRemoveCancel}
        isOpen={isRemoveConfirmationOpen}
      />

      <EditLoginModal login={editingLogin} isOpen={isEditModalOpen} onClose={closeEditModal} />
    </>
  );
});
