import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { ChakraProps, Flex, VStack } from '@chakra-ui/react';
import { AddressRec, GenderType } from '@netiva/classifieds-api';
import { FormEmailInput, FormInput, FormSelect, SelectOption } from '@netiva/classifieds-ui';

import { SupportedCountries } from '@/config';

const AddressInput = FormInput<AddressRec>;
const AddressEmailInput = FormEmailInput<AddressRec>;
const AddressSelect = FormSelect<AddressRec>;

export type AddressFormProps = ChakraProps;

export const AddressForm: FC<AddressFormProps> = ({ ...rest }) => {
  const { t } = useTranslation();
  const [isHomepageUrlVisible, setisHomepageUrlVisible] = useState(false);

  const { watch, trigger, setValue } = useFormContext<AddressRec>();
  const { company, firstname, lastname, mobile, phone, type } = watch();

  // revalidate company if name changes
  useEffect(() => {
    if (firstname || lastname) {
      trigger('company');
    }
  }, [firstname, lastname, trigger]);
  // revalidate name fields if company changes
  // toggle the visibility of the homepage URL field
  useEffect(() => {
    if (company) {
      trigger('firstname');
      trigger('lastname');
      setisHomepageUrlVisible(true);
    } else {
      setisHomepageUrlVisible(false);
      setValue('homepageUrl', '');
    }
  }, [company, setValue, trigger]);
  // revalidate phone fields
  useEffect(() => {
    if (mobile) {
      trigger('phone');
    }
    if (phone) {
      trigger('mobile');
    }
  }, [mobile, phone, trigger]);

  const genderOptions = useMemo<SelectOption<GenderType>[]>(
    () => [
      { value: 'Unknown', label: t('address.form.gender.unkown') },
      { value: 'Male', label: t('address.form.gender.male') },
      { value: 'Female', label: t('address.form.gender.female') },
    ],
    [t]
  );

  const { t: countries } = useTranslation('countries');
  const countryOptions = useMemo(() => {
    const options = SupportedCountries.map<SelectOption>((code) => ({ value: code, label: countries(code) }));
    // sort: ch at the top, rest alphabetically
    options.sort((a, b) => (a.value === 'ch' ? -1 : b.value === 'ch' ? 1 : a.label.localeCompare(b.label)));
    return options;
  }, [countries]);

  const nameOrCompanyMessage = t('address.form.validation.nameOrCompany');

  return (
    <VStack maxWidth="500px" spacing={2} align="stretch" {...rest}>
      <AddressSelect
        name="gender"
        label={t('address.form.gender')}
        options={genderOptions}
        isRequired={type === 'Account'}
      />
      <Flex gap={2}>
        <AddressInput
          name="company"
          label={t('address.form.company')}
          isRequired={!firstname || !lastname}
          validate={(value, object) => !!value || (!!object.firstname && !!object.lastname) || nameOrCompanyMessage}
          flexBasis="50%"
        />
        <AddressInput name="company2" label={t('address.form.company2')} flexBasis="50%" />
      </Flex>

      <Flex gap={2}>
        <AddressInput
          name="firstname"
          label={t('address.form.firstName')}
          isRequired={!company}
          validate={(value, object) => !!value || !!object.company || nameOrCompanyMessage}
          flexBasis="50%"
        />
        <AddressInput
          name="lastname"
          label={t('address.form.lastName')}
          isRequired={!company}
          validate={(value, object) => !!value || !!object.company || nameOrCompanyMessage}
          flexBasis="50%"
        />
      </Flex>
      <Flex gap={2}>
        <AddressInput name="street" label={t('address.form.street')} isRequired />
        <AddressInput name="streetNo" label={t('address.form.streetNo')} maxWidth="110px" />
      </Flex>
      <AddressInput name="street2" label={t('address.form.street2')} />
      <Flex gap={2}>
        <AddressInput name="zip" label={t('address.form.zipCode')} isRequired maxWidth="90px" />
        <AddressInput name="city" label={t('address.form.city')} isRequired />
      </Flex>
      <AddressEmailInput name="email" label={t('address.form.email')} isRequired={type === 'Account'} />
      <AddressInput name="phone" label={t('address.form.phone')} isRequired={type === 'Account' && !mobile} />
      <AddressInput name="mobile" label={t('address.form.mobile')} isRequired={type === 'Account' && !phone} />
      {isHomepageUrlVisible && <AddressInput name="homepageUrl" label={t('address.form.homepageUrl')} />}
      <AddressSelect
        name="countryIso2"
        label={t('address.form.country')}
        placeholder={t('address.form.selectCountry')}
        options={countryOptions}
        isRequired
        ignoreValueCase
      />
    </VStack>
  );
};
