import { AdAttribute, AdFormData } from '@/store/ad/types';
import { AccountSharedDataObjectPropertyRec, AccountSharedDataObjectValueRec } from '@netiva/classifieds-api';

/** Converts ad form data to values request objects */
export const getAttributeFormDataValues = (
  attributes: AdAttribute[],
  formData: AdFormData
): AccountSharedDataObjectValueRec[] => {
  return attributes.map<AccountSharedDataObjectValueRec>((attr) => ({
    attributeId: attr.id,
    ...formData[attr.id],
  }));
};

/** Converts ad property form data to property request objects */
export const getPropertyFormDataValues = (propertyFormData: AdFormData): AccountSharedDataObjectPropertyRec[] => {
  const values = Object.entries(propertyFormData).reduce<AccountSharedDataObjectPropertyRec[]>(
    (values, [key, entry]) => {
      values.push({ key, value: entry.value });
      return values;
    },
    []
  );

  return values;
};
