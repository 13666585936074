import { NavigationClient, NavigationOptions } from '@azure/msal-browser';
import { NavigateFunction } from 'react-router-dom';

/** Extended MSAL navigation client that supports a flag for disabling navigation. */
export class CustomNavigationClient extends NavigationClient {
  private isNavigationDisabled: boolean = false;
  private navigate?: NavigateFunction;

  constructor(navigate?: NavigateFunction) {
    super();
    this.navigate = navigate;
  }

  async navigateInternal(url: string, options: NavigationOptions) {
    if (this.isNavigationDisabled) {
      return false;
    }

    if (this.navigate) {
      const relativePath = url.replace(window.location.origin, '');
      if (options.noHistory) {
        this.navigate(relativePath, { replace: true });
      } else {
        this.navigate(relativePath);
      }

      return false;
    }

    return await super.navigateInternal(url, options);
  }

  async navigateExternal(url: string, options: NavigationOptions) {
    if (this.isNavigationDisabled) {
      return false;
    }

    return await super.navigateExternal(url, options);
  }

  setNavigate(navigate?: NavigateFunction) {
    this.navigate = navigate;
  }

  disableNavigation() {
    this.isNavigationDisabled = true;
  }

  enableNavigation() {
    this.isNavigationDisabled = false;
  }
}
