import { FC, memo, useEffect } from 'react';

import { Loader } from '@netiva/classifieds-ui';

import { useAuth } from '@/hooks';

export const LoginPage: FC = memo(function LoginPage() {
  const { login } = useAuth();

  useEffect(() => {
    // login();
  }, [login]);

  return <Loader isLoading />;
});
