import { FC } from 'react';
import { Checkbox } from '@chakra-ui/react';
import type {
  AccountAttributeAttributeListResponseAttribute,
  AccountSharedDataObjectValueRec,
} from '@netiva/classifieds-api';
import { DescriptionListItem, DescriptionListTerm } from '@netiva/classifieds-ui';

import { useCurrencyFormatter } from '@/hooks';

export type AttributeDisplayProps = {
  attribute: AccountAttributeAttributeListResponseAttribute;
  value: AccountSharedDataObjectValueRec;
};

const AttributeValues: FC<AttributeDisplayProps> = ({ attribute, value }) => {
  const currencyFormatter = useCurrencyFormatter();

  switch (attribute.type) {
    case 'Boolean':
      return (
        <DescriptionListItem>
          <Checkbox isChecked={value.value?.toLowerCase() === 'true'} isReadOnly />
        </DescriptionListItem>
      );

    case 'HTML':
      return <DescriptionListItem dangerouslySetInnerHTML={{ __html: value.value || '' }} />;

    case 'Price':
      return <DescriptionListItem>{currencyFormatter.format(Number(value.value || 0))}</DescriptionListItem>;

    default:
      if (value.entries?.length) {
        return (
          <>
            {value.entries.map((entry) => {
              const attributeItem = attribute.items.find((x) => x.id === entry);
              return attributeItem && <DescriptionListItem key={entry}>{attributeItem.name}</DescriptionListItem>;
            })}
          </>
        );
      }
      return <DescriptionListItem>{value.value}</DescriptionListItem>;
  }
};

export const AttributeDisplay: FC<AttributeDisplayProps> = ({ attribute, value }) => {
  if (attribute.type === 'Boolean' && value.value?.toLowerCase() !== 'true') {
    return null;
  }

  return value.value || value.entries?.length ? (
    <>
      <DescriptionListTerm>{attribute.name}</DescriptionListTerm>
      <AttributeValues attribute={attribute} value={value} />
    </>
  ) : null;
};
