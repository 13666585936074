import { DataObjectStatus } from '@netiva/classifieds-api';
import { FilterStatus } from './types';

export const getFilterCounts = (countsData?: Record<string, number> | null) => {
  const result = {} as Record<FilterStatus, number>;

  if (countsData) {
    for (const key in countsData) {
      if (Object.prototype.hasOwnProperty.call(countsData, key)) {
        const dataObjectStatus = key as DataObjectStatus;
        const count = countsData[dataObjectStatus];
        const filterStatus = getFilterStatus(dataObjectStatus);
        if (!result[filterStatus]) {
          result[filterStatus] = count;
        } else {
          result[filterStatus] += count;
        }
      }
    }
  }

  return result;
};

export const getFilterStatus = (dataObjectStatus?: DataObjectStatus): FilterStatus => {
  switch (dataObjectStatus) {
    case 'Active':
    case 'Approved':
    case 'ToApprove':
      return 'active';
    case 'Draft':
      return 'draft';
    case 'Deleted':
      return 'deleted';
    case 'Disabled':
    case 'Expired':
      return 'inactive';
    default:
      return 'active';
  }
};

export const getDataObjectStatus = (filterStatus?: FilterStatus): DataObjectStatus[] => {
  switch (filterStatus) {
    case 'active':
      return ['Active', 'Approved', 'ToApprove'];
    case 'draft':
      return ['Draft'];
    case 'deleted':
      return ['Deleted'];
    case 'inactive':
      return ['Disabled', 'Expired'];
    default:
      return ['Active', 'Approved', 'ToApprove'];
  }
};
