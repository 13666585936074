import { Configuration as MsalConfiguration, RedirectRequest } from '@azure/msal-browser';
import { getI18n } from 'react-i18next';

const clientId = import.meta.env.VITE_AZURE_AD_B2C_CLIENT_ID;
const tenantId = import.meta.env.VITE_AZURE_AD_B2C_TENANT_ID;
const authority = import.meta.env.VITE_AZURE_AD_B2C_AUTHORITY;
const scopesAuthority = import.meta.env.VITE_AZURE_AD_B2C_SCOPES_AUTHORITY;
const defaultUserFlow = import.meta.env.VITE_AZURE_AD_B2C_DEFAULT_USER_FLOW;
const resetPasswordUserFlow = import.meta.env.VITE_AZURE_AD_B2C_RESET_PASSWORD_USER_FLOW;
const defaultPlatform = import.meta.env.VITE_AZURE_AD_B2C_DEFAULT_PLATFORM;

const getAuthority = (userFlow: string) => `https://${authority}/${tenantId}/${userFlow}`;

export const AuthConfig: MsalConfiguration = {
  auth: {
    clientId: clientId!,
    authority: getAuthority(defaultUserFlow!),
    knownAuthorities: [authority!],
    redirectUri: '/auth/callback',
    postLogoutRedirectUri: '/logout',
  },
  cache: {
    cacheLocation: 'localStorage',
    secureCookies: true,
    storeAuthStateInCookie: true,
  },
};

export const ApiScopes = [`https://${scopesAuthority}/classifieds-api/Public.Read`];

export const getLoginRequest = (language?: string, platform?: string): RedirectRequest => ({
  scopes: ApiScopes,
  extraQueryParameters: {
    platform: platform || defaultPlatform || '',
    ui_locales: language || getI18n().language || '',
  },
});

export const getResetPasswordRequest = (
  email?: string | null,
  language?: string,
  platform?: string
): RedirectRequest => ({
  scopes: ApiScopes,
  authority: getAuthority(resetPasswordUserFlow!),
  extraQueryParameters: {
    email: email || '',
    platform: platform || defaultPlatform || '',
    ui_locales: language || getI18n().language || '',
  },
});
