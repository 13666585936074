import { FC, memo, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Loader } from '@netiva/classifieds-ui';

import { AuthMode } from '@/authentication';
import { useAuth } from '@/hooks';
import { routes } from '@/lib/routes';

export const AuthPage: FC = memo(function AuthPage() {
  const { isAuthenticated, isLoading } = useAuth();
  const msalContext = useMsal();

  // MSAL
  useEffect(() => {
    if (AuthMode === 'msal') {
      let cancelRedirect = false;

      const handleMsal = async () => {
        await msalContext.instance.initialize();
        await msalContext.instance.handleRedirectPromise();
        // there are cases when MSAL doesn't handle the redirect correctly,
        // so we redirect to the home page after a short delay
        setTimeout(() => {
          if (!cancelRedirect) {
            window.location.replace(window.location.origin);
          }
        }, 1000);
      };

      handleMsal();

      return () => {
        cancelRedirect = true;
      };
    }
    return undefined;
  }, [msalContext.instance]);

  // Generic OAuth
  if (AuthMode === 'oauth') {
    if (isAuthenticated && !isLoading) {
      // TODO handle return URL
      return <Navigate to={routes.home()} />;
    }
  }

  // Render an empty page during authentication.
  return <Loader isLoading />;
});
