import { FC, useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { readFileAsDataURL } from '@netiva/classifieds-common';

import 'react-image-crop/dist/ReactCrop.css';
import { FaRotateLeft, FaRotateRight } from 'react-icons/fa6';

export type EditModalProps = Omit<ModalProps, 'children'> & {
  file?: File;
  onDone: (blob?: Blob) => void;
};

export const EditModal: FC<EditModalProps> = ({ file, onDone, isOpen, ...rest }) => {
  const { t } = useTranslation();

  const imgRef = useRef<HTMLImageElement>(null);
  const resultCanvasRef = useRef<HTMLCanvasElement>(null);
  const [dataUrl, setDataUrl] = useState<string>('');
  useEffect(() => {
    if (!file) {
      return;
    }
    let unmounted = false;
    const loadDataUrl = async () => {
      const dataUrl = await readFileAsDataURL(file);
      if (!unmounted) {
        setDataUrl(dataUrl);
      }
    };
    loadDataUrl();
    return () => {
      unmounted = true;
    };
  }, [file]);

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<Crop>();
  const [isRotated, setIsRotated] = useState(false);

  useEffect(() => {
    // reset crop when opening the modal
    if (isOpen) {
      setIsRotated(false);
      setCrop(undefined);
      setCompletedCrop(undefined);
    }
  }, [isOpen]);

  const handleCropComplete = (crop: Crop) => {
    if (!resultCanvasRef.current || !imgRef.current) {
      return;
    }

    const imgElement = imgRef.current;
    const canvas = resultCanvasRef.current;
    const scaleX = imgElement.naturalWidth / imgElement.width;
    const scaleY = imgElement.naturalHeight / imgElement.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    const pixelRatio = window.devicePixelRatio;
    const width = crop.width || 0;
    const height = crop.height || 0;

    canvas.width = width * pixelRatio;
    canvas.height = height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      imgElement,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    setCompletedCrop(crop);
  };

  const handleDone = () => {
    if ((completedCrop || isRotated) && resultCanvasRef.current) {
      resultCanvasRef.current.toBlob((blob) => onDone(blob || undefined));
    } else {
      if (file) {
        // create a new file instance to change its name
        onDone(file);
      } else {
        onDone();
      }
    }
  };

  const handleRotate = (factor: number) => {
    // reset crop
    setCrop(undefined);
    setCompletedCrop(undefined);

    const canvas = resultCanvasRef.current;
    const ctx = canvas?.getContext('2d');
    const img = imgRef.current;
    if (!canvas || !ctx || !img) {
      return;
    }
    // swap width and height since we always rotate by 90 degrees
    canvas.width = img.naturalHeight;
    canvas.height = img.naturalWidth;
    ctx.reset();
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((factor * (90 * Math.PI)) / 180);
    ctx.drawImage(imgRef.current, -img.naturalWidth / 2, -img.naturalHeight / 2);
    setDataUrl(canvas.toDataURL());
    setIsRotated(true);
    canvas.remove();
  };

  return (
    <Modal isOpen={isOpen} size={['full', 'xl']} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('ad.steps.files.images.modal.title')}</ModalHeader>
        <ModalBody pb={6}>
          <Flex justify="space-between" my={2}>
            <Tooltip label={t('ad.steps.files.images.modal.rotateLeft')} placement="top">
              <IconButton
                icon={<Icon as={FaRotateLeft} />}
                onClick={() => handleRotate(-1)}
                colorScheme="neutral"
                aria-label={t('ad.steps.files.images.modal.rotateLeft')}
              />
            </Tooltip>
            <Tooltip label={t('ad.steps.files.images.modal.rotateRight')} placement="top">
              <IconButton
                icon={<Icon as={FaRotateRight} />}
                onClick={() => handleRotate(1)}
                colorScheme="neutral"
                aria-label={t('ad.steps.files.images.modal.rotateRight')}
              />
            </Tooltip>
          </Flex>
          <Box minHeight="300px" maxHeight="70vh" overflow="auto">
            <ReactCrop crop={crop} onChange={setCrop} onComplete={handleCropComplete}>
              <img ref={imgRef} src={dataUrl} alt="Crop" />
            </ReactCrop>
            <canvas
              ref={resultCanvasRef}
              style={{
                width: Math.round(crop?.width || 0),
                height: Math.round(crop?.height || 0),
                display: 'none',
              }}
            />
          </Box>
          <Flex>
            <Button ml="auto" type="button" onClick={handleDone} isDisabled={!dataUrl}>
              {t('ui.buttons.done')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
