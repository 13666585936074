import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Flex, Icon, IconButton, Text, Tooltip, useToast } from '@chakra-ui/react';
import { ArrowUpIcon, DeleteIcon } from '@chakra-ui/icons';
import { FiSave } from 'react-icons/fi';
import { accountApi, createMultipartRequestBody } from '@netiva/classifieds-api';
import { readFileAsDataURL } from '@netiva/classifieds-common';
import { FileInput, FileInputClickBox, Loader, MissingImageIcon, UserLogo } from '@netiva/classifieds-ui';

import { ApiBaseUrl } from '@/environment';
import { useAppSelector } from '@/store';

export type LogoUploadProps = BoxProps & {
  manualSave?: boolean;
};

export const LogoUpload: FunctionComponent<LogoUploadProps> = ({ manualSave, ...rest }) => {
  const { t } = useTranslation();
  const { accountId } = useAppSelector((state) => state.account);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [dataUrl, setDataUrl] = useState<string>();
  const [cacheIndex, setCacheIndex] = useState(0);
  const [hasImage, setHasImage] = useState(false);
  const [saveLogo, { isLoading: isSaving }] = accountApi.useSaveLogo();
  const [deleteLogo, { isLoading: isDeleting }] = accountApi.useDeleteLogo();
  const toast = useToast();

  const save = async (file: File) => {
    const saveResponse = await saveLogo({
      body: createMultipartRequestBody((formData) => formData.append('file', file)),
    }).unwrap();

    if (saveResponse.fileId) {
      setSelectedFile(undefined);
      setDataUrl(undefined);

      toast({ status: 'success', description: t('addresses.logo.saveSuccess') });
    } else {
      toast({ status: 'error', description: t('addresses.logo.saveError') });
    }
  };

  const handleRemove = async () => {
    await deleteLogo({});
    setCacheIndex((i) => i + 1);

    toast({ status: 'success', description: t('addresses.logo.deleteSuccess') });
  };

  const handleSave = async () => {
    if (!selectedFile) {
      return;
    }

    await save(selectedFile);
  };

  const handleSelectFile = async (files: FileList) => {
    const file = files[0];
    setSelectedFile(file);
    const dataUrl = await readFileAsDataURL(file);
    setDataUrl(dataUrl);

    if (!manualSave) {
      save(file);
    }
  };

  const isLoading = isSaving || isDeleting;

  return (
    <Box position="relative" {...rest}>
      <Loader isLoading={isLoading} />
      <FileInput onFilesSelected={handleSelectFile} accept="image/*" p={0}>
        <FileInputClickBox width="full" cursor="pointer" textAlign="center" padding={4}>
          <Flex direction="column" align="center" gap={2}>
            <Text>{t('addresses.logo.drag.upload')}</Text>
            <UserLogo
              src={dataUrl || `${ApiBaseUrl}/v1/Account/Logo/${accountId}?v=${cacheIndex}`}
              fallback={<Icon as={MissingImageIcon} boxSize={16} />}
              onLoaded={setHasImage}
              showOnError
            />
            <Flex justify="center" gap={2} mt={2}>
              <Tooltip label={t(hasImage ? 'addresses.logo.replace' : 'addresses.logo.upload')}>
                <IconButton
                  icon={<ArrowUpIcon />}
                  colorScheme="neutral"
                  aria-label={t(hasImage ? 'addresses.logo.replace' : 'addresses.logo.upload')}
                />
              </Tooltip>
              {hasImage && (
                <Tooltip label={t('addresses.logo.remove')}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove();
                    }}
                    icon={<DeleteIcon />}
                    colorScheme="neutral"
                    aria-label={t('addresses.logo.remove')}
                  />
                </Tooltip>
              )}
              {!!selectedFile && manualSave && (
                <Tooltip label={t('addresses.logo.save')}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSave();
                    }}
                    icon={<FiSave />}
                    colorScheme="primary"
                    aria-label={t('addresses.logo.save')}
                  />
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </FileInputClickBox>
      </FileInput>
    </Box>
  );
};
