import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from '@netiva/classifieds-api';
import { hasFlag } from '@netiva/classifieds-common';

import { getSelectedAccountId, setSelectedAccountId } from '@/lib/storage';
import { AccountState } from './types';

export * from './types';

const initialState: AccountState = {
  accountId: getSelectedAccountId(),
  accounts: [],
  hasValidAccount: false,
  isLoaded: false,
  isNewUser: false,
  showAccountSelection: false,
  invitations: [],
  hasMemberContract: false,
  allowCreateObjects: false,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountId: (state, action: PayloadAction<number | undefined>) => {
      state.accountId = action.payload;
      setSelectedAccountId(action.payload);
    },
    showAccountSelection: (state) => {
      state.showAccountSelection = true;
    },
    hideAccountSelection: (state) => {
      state.showAccountSelection = false;
    },
    setHasMemberContract: (state, { payload }: PayloadAction<boolean>) => {
      state.hasMemberContract = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getV1AccountAccountById.matchFulfilled, (state, { payload }) => {
      const account = payload.account;
      state.account = account;
      state.accountId = account.accountId;
      setSelectedAccountId(account.accountId);
      state.isNewUser = account.isNew;
      state.isLoaded = true;
      state.hasValidAccount = !state.isNewUser;
      state.allowCreateObjects = state.hasValidAccount && !hasFlag(account.accountStatus, 'LockedClassifieds');
    });
    builder.addMatcher(api.endpoints.getV1AccountAccountById.matchRejected, (state, action) => {
      // reset the selected account in case of a forbidden error
      if (action.payload?.status === 403) {
        if (state.accounts.length) {
          state.accountId = state.accounts[0].id;
        } else {
          state.accountId = undefined;
          state.isNewUser = true;
          state.isLoaded = true;
          state.hasValidAccount = false;
          state.allowCreateObjects = false;
        }
      }
    });
    builder.addMatcher(api.endpoints.getV1AccountAccount.matchFulfilled, (state, { payload }) => {
      state.accounts = payload.accounts;
      if (payload.accounts?.length) {
        if (!state.accountId) {
          // set account id if not set
          state.accountId = payload.accounts[0].id;
        }
      } else {
        // user does not have an account
        state.isNewUser = true;
        state.isLoaded = true;
        state.hasValidAccount = false;
      }
    });
    builder.addMatcher(api.endpoints.getV1AccountLoginsInvitations.matchFulfilled, (state, { payload }) => {
      state.invitations = payload.items;
    });
  },
});

export const accountActions = accountSlice.actions;
