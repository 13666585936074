import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { NodeEnv, SupportedLanguages } from './environment';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //fallbackLng: SupportedLanguages[0],
    fallbackLng: false,
    supportedLngs: SupportedLanguages,
    debug: NodeEnv !== 'production',
    defaultNS: 'translation',
    parseMissingKeyHandler: (key, defaultValue) => {
      return typeof defaultValue !== 'undefined' ? defaultValue : `#${key}#`;
    },
    // we need to preload namespaces because somehow when they are loaded on demand, redux stops working correctly (state updates won't rerender anymore.. :/ )
    ns: ['translation', 'countries'],
  });

export default i18n;
