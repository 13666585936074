import { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  CardHeader,
  Divider,
  Flex,
  Icon,
  Text,
  chakra,
} from '@chakra-ui/react';
import { FaCreditCard, FaFileInvoice } from 'react-icons/fa';
import { DescriptionList, DescriptionListItem, DescriptionListTerm, SelectableCard } from '@netiva/classifieds-ui';

import { PrintPriceSummary } from '@/components/ui';
import { useDate, usePrintIssueDates } from '@/hooks';
import { InvoicePaymentProvider } from '@/pages/Ad/constants';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { useAdParams, useAdPublishing } from '@/pages/Ad/hooks';

const PaymentProviderCardHeader = chakra(CardHeader, {
  baseStyle: {
    p: 1,
    display: 'flex',
    flexDir: 'column',
    alignItems: 'center',
    gap: 2,
    minWidth: '150px',
  },
});

export const EInseratReview: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const {
    paymentProvider,
    platform,
    print: { paymentInfo, price },
  } = useAppSelector((state) => state.ad);
  const { paymentProviders } = useAdPublishing();
  const { dataObjectId } = useAdParams();
  const { t } = useTranslation();
  const { formatDate } = useDate();
  const { selectedIssueDates } = usePrintIssueDates(dataObjectId);

  const setPaymentProvider = useCallback(
    (name: string) => {
      dispatch(adActions.setPaymentProvider(name));
    },
    [dispatch]
  );

  // preselect payment provider
  useEffect(() => {
    if (paymentProviders.length && !paymentProvider) {
      setPaymentProvider(paymentProviders[0]);
    }
  }, [paymentProvider, paymentProviders, setPaymentProvider]);

  const guidelinesTitle =
    (t(`ad.steps.review.guidelinesMessage.title.${platform?.toLowerCase()}`, '') as string) ||
    t('ad.steps.review.guidelinesMessage.title');
  const guidelinesText =
    (t(`ad.steps.review.guidelinesMessage.text.${platform?.toLowerCase()}`, '') as string) ||
    t('ad.steps.review.guidelinesMessage.text');

  return (
    <>
      <DescriptionList variant="alignRight">
        <DescriptionListTerm>{t('ad.steps.review.issueDates')}</DescriptionListTerm>
        {selectedIssueDates.map((date) => (
          <DescriptionListItem key={date.id}>{formatDate(date.date)}</DescriptionListItem>
        ))}
      </DescriptionList>
      <Divider my={4} />
      <PrintPriceSummary price={price} issueCount={selectedIssueDates.length} showMultipleIssues unified />
      {guidelinesText && (
        <>
          <Divider my={4} />
          <Alert status="info" flexDirection="column">
            {guidelinesTitle && <AlertTitle>{guidelinesTitle}</AlertTitle>}
            <AlertDescription dangerouslySetInnerHTML={{ __html: guidelinesText }} />
          </Alert>
        </>
      )}
      {paymentInfo && paymentInfo.paymentType !== 'None' && paymentInfo.paymentType !== 'AfterVerification' && (
        <Box>
          <Divider my={4} />

          <Text fontSize="md" fontWeight="bold" marginBottom={4}>
            {t('ad.steps.review.payment.title')}
            <chakra.span color="red" ml={1}>
              *
            </chakra.span>
          </Text>
          <Flex gap={8}>
            {paymentInfo.paymentProviders.map((p) => (
              <SelectableCard
                key={p}
                isSelected={p.toLowerCase() === paymentProvider?.toLowerCase()}
                onClick={() => setPaymentProvider(p)}
              >
                <PaymentProviderCardHeader>
                  <Icon as={FaCreditCard} boxSize={12} />
                  {/* @ts-expect-error dynamic translation */}
                  {t(`ad.steps.review.payment.providers.${p.toLowerCase()}`)}
                </PaymentProviderCardHeader>
              </SelectableCard>
            ))}
            {paymentInfo.paymentType !== 'ImmediateRequired' && (
              <SelectableCard
                isSelected={paymentProvider?.toLowerCase() === InvoicePaymentProvider.toLowerCase()}
                onClick={() => setPaymentProvider(InvoicePaymentProvider)}
              >
                <PaymentProviderCardHeader>
                  <Icon as={FaFileInvoice} boxSize={12} />
                  {t('ad.steps.review.payment.providers.none')}
                </PaymentProviderCardHeader>
              </SelectableCard>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};
