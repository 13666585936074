import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressRec } from '@netiva/classifieds-api';
import { DescriptionListItem, DescriptionListTerm } from '@netiva/classifieds-ui';

export type AddressDisplayType = 'billing' | 'contact' | 'location';

export type AddressDisplayProps = {
  address?: AddressRec;
  type: AddressDisplayType;
};

const join = (...values: (string | null | undefined)[]) =>
  values
    .filter((v) => !!v)
    .join(' ')
    .trim();

export const AddressDisplay: FC<AddressDisplayProps> = ({ address, type }) => {
  const { t } = useTranslation();

  const addressDescription = useMemo(() => {
    if (!address) {
      return '';
    }

    return [
      address.company,
      address.company2,
      join(address.firstname, address.lastname),
      join(address.street, address.streetNo),
      join(address.zip, address.city),
      address.email,
      address.phone,
    ]
      .filter((x) => !!x)
      .join(', ');
  }, [address]);

  return address ? (
    <>
      <DescriptionListTerm>{t(`ad.steps.address.${type}`)}</DescriptionListTerm>
      <DescriptionListItem>{addressDescription}</DescriptionListItem>
    </>
  ) : null;
};
