import { Contract, Product } from './types';

export const canCreate = (product: Product, activeContracts: Contract[]) => {
  return (
    !product.isDisabled &&
    product.isCreatable &&
    // could be made configurable (IsUnique / IsUniquePerGroup)
    !activeContracts.some((c) => c.productId === product.id) &&
    !activeContracts.some((c) => c.product?.group === product.group)
  );
};

export const canUpgradeTo = (currentProduct: Product, targetProduct: Product) => {
  if (!targetProduct.isDisabled && !targetProduct.isHidden && targetProduct.id !== currentProduct.id) {
    // upgrade within the same group is only allowed to bigger products
    if (targetProduct.group === currentProduct.group) {
      return !targetProduct.numberOfAds || targetProduct.numberOfAds > (currentProduct.numberOfAds || 0);
    }

    // upgrade to other groups is allowed to equal size and bigger products
    if (targetProduct.group && currentProduct.upgradeToGroups.includes(targetProduct.group)) {
      return !targetProduct.numberOfAds || targetProduct.numberOfAds >= (currentProduct.numberOfAds || 0);
    }
  }

  return false;
};
