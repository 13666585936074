import { FC, useMemo } from 'react';
import { hasFlag } from '@netiva/classifieds-common';
import { Alert, AlertData } from '@netiva/classifieds-ui';

import { useAppSelector } from '@/store';
import { useTranslation } from 'react-i18next';

export const AccountStatus: FC = () => {
  const { t } = useTranslation();
  const { account } = useAppSelector((state) => state.account);

  const alert = useMemo<AlertData | null>(() => {
    const lockedClassifieds = hasFlag(account?.accountStatus, 'LockedClassifieds');
    const lockedPrint = hasFlag(account?.accountStatus, 'LockedPrint');

    if (lockedClassifieds || lockedPrint) {
      return {
        status: 'warning',
        title: t('account.lockedAlert.title'),
        description: t('account.lockedAlert.description'),
      };
    }
    return null;
  }, [account?.accountStatus, t]);

  if (!alert) {
    return null;
  }

  return <Alert alert={alert} />;
};
