import { FC, useEffect } from 'react';
import { ParseKeys } from 'i18next';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthenticationTemplate } from '@/authentication';
import { PageTitle } from '@/components/global';
import { routes } from '@/lib/routes';
import { useAppSelector } from '@/store';

export type PageRouteProps = {
  title: ParseKeys;
  component: FC;
  allowAnonymous?: boolean;
  requiresAccount?: boolean;
};

export const PageRoute: FC<PageRouteProps> = ({ title, allowAnonymous, requiresAccount, component: Component }) => {
  const { pathname } = useLocation();

  const { hasValidAccount, isLoaded } = useAppSelector((state) => state.account);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <PageTitle titleRes={title} />
      {allowAnonymous ? (
        <Component />
      ) : (
        <AuthenticationTemplate>
          {requiresAccount && isLoaded && !hasValidAccount && <Navigate to={routes.home()} />}
          <Component />
        </AuthenticationTemplate>
      )}
    </>
  );
};
