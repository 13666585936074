import { FC, PropsWithChildren, useMemo } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

import { CustomNavigationClient } from './CustomNavigationClient';
import { getInstance } from './instance';

export const CustomMsalProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const msalInstance = useMemo(() => {
    const result = getInstance();
    result.setNavigationClient(new CustomNavigationClient(navigate));
    return result;
  }, [navigate]);

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};
