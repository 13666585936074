import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@/store';
import { AdValidationStatus, adActions } from '@/store/ad';
import { useAdAttributes } from './useAdAttributes';
import { useAdPublishing } from './useAdPublishing';
import { useAdSteps } from './useAdSteps';

export const useAdValidator = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { attributes } = useAdAttributes();
  const {
    categoryId,
    dataObjectType,
    status,
    contactAddressId,
    formData,
    selectedProductId,
    selectedContractId,
    selectedExtensionProductIds,
    paymentProvider,
    print: { selectedIssueIds },
  } = useAppSelector((state) => state.ad);
  const { paymentProviders } = useAdPublishing();
  const { steps } = useAdSteps();

  const validationStatus = useMemo(() => {
    const newValidationStatus: AdValidationStatus = {};
    steps.forEach((step) => {
      let isValid = true;
      let message: string | undefined;

      step.items?.forEach((item) => {
        // check for the attributes in the current step
        if (item.type === 'Attribute' && attributes) {
          const attribute = attributes.find((attribute) => attribute.id === item.attributeId);
          if (attribute && attribute.mandatory) {
            if (!formData[attribute.id]) isValid = false;
            if (!formData[attribute.id]?.value && !formData[attribute.id]?.entries?.length) isValid = false;
          }
        }
        // check for values on components
        if (item.type === 'Component') {
          switch (item.componentName) {
            case 'IssueDateSelector':
              // check print issues
              if (selectedIssueIds.length === 0) {
                isValid = false;
              }
              break;
            case 'CategorySelector':
              if (!categoryId) {
                isValid = false;
                message = t('ad.error.missingCategory');
              }
              break;
            case 'DataObjectTypeSelector':
              if (!dataObjectType) {
                isValid = false;
              }
              break;
            case 'Publishing':
              if (status !== 'Active' && status !== 'ToApprove') {
                isValid = !!selectedContractId || !!selectedProductId || selectedExtensionProductIds.length > 0;
              }
              break;
            case 'AddressSelector':
              if (!contactAddressId) {
                isValid = false;
              }
              break;
            case 'Review':
              if (paymentProviders.length && !paymentProvider) {
                isValid = false;
                message = t('ad.error.missingPaymentProvider');
              }
              break;
          }
        }
      });

      newValidationStatus[step.key] = { isValid, message };
    });

    return newValidationStatus;
  }, [
    attributes,
    categoryId,
    contactAddressId,
    dataObjectType,
    formData,
    paymentProvider,
    paymentProviders.length,
    selectedContractId,
    selectedExtensionProductIds.length,
    selectedIssueIds.length,
    selectedProductId,
    status,
    steps,
    t,
  ]);

  useEffect(() => {
    dispatch(adActions.updateValidationStatus(validationStatus));
  }, [dispatch, validationStatus]);

  return validationStatus;
};
