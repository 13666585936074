import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  CardBody,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { accountApi } from '@netiva/classifieds-api';
import { SelectableCard } from '@netiva/classifieds-ui';

import { useCurrencyFormatter, useProductGroups } from '@/hooks';
import { Product } from '../types';

export type CreateModalProps = Omit<ModalProps, 'children'> & {
  products: Product[];
};

export const CreateModal: FC<CreateModalProps> = ({ products, isOpen, onClose, ...rest }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const currencyFormatter = useCurrencyFormatter();

  const { orderedProductGroups } = useProductGroups(products);

  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [createContract, { isLoading: isUpgradingContract }] = accountApi.useCreateContract();

  useEffect(() => {
    setSelectedProduct(undefined);
  }, [isOpen]);

  const handleSubmit = async () => {
    if (!selectedProduct) {
      return;
    }

    try {
      await createContract({
        accountContractsCreateContractRequest: { productId: selectedProduct.id },
      }).unwrap();
      toast({ status: 'success', description: t('products.createModal.success') });
      onClose();
    } catch (err) {
      console.error(err);
      toast({ status: 'error', description: t('products.createModal.error') });
    }
  };

  return (
    <Modal isCentered scrollBehavior="inside" size="xl" isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{t('products.createModal.title')}</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Accordion defaultIndex={orderedProductGroups.length === 1 ? 0 : undefined} allowToggle>
            {orderedProductGroups.map(([group, products]) => (
              <AccordionItem key={group}>
                <AccordionButton>
                  <VStack align="stretch" textAlign="start" py={1}>
                    <Heading size="md">{t(`ad.steps.publishing.productGroups.${group}.title`, '')}</Heading>
                    <Text>{t(`ad.steps.publishing.productGroups.${group}.description`, '')}</Text>
                  </VStack>
                  <AccordionIcon ml="auto" boxSize={8} />
                </AccordionButton>
                <AccordionPanel>
                  <VStack align="stretch" spacing={3}>
                    {products.map((p) => {
                      return (
                        <SelectableCard
                          key={p.id}
                          isSelected={p.id === selectedProduct?.id}
                          onClick={() => setSelectedProduct(p)}
                        >
                          <CardBody p={0}>
                            <Flex justifyContent="space-between" p={2}>
                              <Text fontSize="lg" fontWeight="bold">
                                {p.name}
                              </Text>
                              <Text>{currencyFormatter.format(p.price)}</Text>
                            </Flex>
                            {p.description && !p.description.startsWith('#') && (
                              <Text dangerouslySetInnerHTML={{ __html: p.description }} mt={2} />
                            )}
                          </CardBody>
                        </SelectableCard>
                      );
                    })}
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </ModalBody>
        <ModalFooter display="block">
          <Text mb={4}>{t('products.createModal.footer')}</Text>
          <Flex>
            <Button onClick={onClose} colorScheme="neutral">
              {t('ui.buttons.cancel')}
            </Button>
            <Button onClick={handleSubmit} isLoading={isUpgradingContract} isDisabled={!selectedProduct} ml="auto">
              {t('products.createModal.submit')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
