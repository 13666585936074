import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { ConsentManagerId } from '@/environment';

export const ConsentManager: FC = () => {
  return (
    ConsentManagerId && (
      <Helmet>
        <script
          type="text/javascript"
          data-cmp-ab="1"
          src={`https://cdn.consentmanager.net/delivery/autoblocking/${ConsentManagerId}.js?ver=v3`}
          data-cmp-host="b.delivery.consentmanager.net"
          data-cmp-cdn="cdn.consentmanager.net"
          data-cmp-codesrc="1"
        ></script>
      </Helmet>
    )
  );
};
