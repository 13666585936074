import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HtmlEditor, HtmlEditorConverters } from '@netiva/classifieds-ui';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';

export type HtmlInputProps = {
  id: number;
  isEinserat?: boolean;
};

export const HtmlInput: FunctionComponent<HtmlInputProps> = ({ id, isEinserat }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.ad);

  const handleChange = (value: string) => {
    dispatch(adActions.updateFormData({ key: id, value: value }));
  };

  const converter = useMemo(() => {
    if (isEinserat) {
      return HtmlEditorConverters.einserat;
    }
    return HtmlEditorConverters.default;
  }, [isEinserat]);

  return (
    <HtmlEditor
      value={formData[id]?.value || ''}
      onChange={handleChange}
      singleFormat={isEinserat}
      converter={converter}
      toolbarTexts={{
        bold: t('ui.editor.toolbar.bold'),
        italic: t('ui.editor.toolbar.italic'),
        underline: t('ui.editor.toolbar.underline'),
        undo: t('ui.editor.toolbar.undo'),
        redo: t('ui.editor.toolbar.redo'),
      }}
    />
  );
};
