import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from './types';
import { accountApi } from '@netiva/classifieds-api';

export * from './types';

const initialState: AuthState = {
  isLoading: true,
  isLocked: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(accountApi.getAccounts.matchFulfilled, (state) => {
      state.isLoading = false;
      state.isLocked = false;
    });
    builder.addMatcher(accountApi.getAccounts.matchRejected, (state, action) => {
      state.isLoading = false;
      state.isLocked = action.payload?.status === 423;
    });
  },
});

export const authActions = authSlice.actions;
