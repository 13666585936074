import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Card, CardBody, Flex, Text } from '@chakra-ui/react';
import { accountApi } from '@netiva/classifieds-api';
import { Loader } from '@netiva/classifieds-ui';

import { useAppSelector } from '@/store';

export const InvitationsPage: FC = () => {
  const { t } = useTranslation();
  const { isLoading: isLoadingList } = accountApi.useGetAccountInvitations({});
  const [acceptInvitation, { isLoading: isAccepting }] = accountApi.useAcceptAccountInvitation();
  const [rejectInvitation, { isLoading: isRejecting }] = accountApi.useRejectAccountInvitation();
  const isLoading = isLoadingList || isAccepting || isRejecting;

  const { invitations } = useAppSelector((s) => s.account);

  const handleAccept = async (accountId: number) => {
    await acceptInvitation({ accountId });
  };

  const handleReject = async (accountId: number) => {
    await rejectInvitation({ accountId });
  };

  return (
    <>
      <Loader isLoading={isLoading} showOverlay />
      <Flex direction="column" gap={4}>
        {invitations.map((invitation) => (
          <Card key={invitation.accountId} background="surface">
            <CardBody padding={4}>
              <Flex align="center" gap={4}>
                <Text fontWeight="bold">{invitation.accountName}</Text>
                <ButtonGroup marginLeft="auto">
                  <Button colorScheme="neutral" onClick={() => handleReject(invitation.accountId)}>
                    {t('invitations.reject')}
                  </Button>
                  <Button onClick={() => handleAccept(invitation.accountId)}>{t('invitations.accept')}</Button>
                </ButtonGroup>
              </Flex>
            </CardBody>
          </Card>
        ))}
      </Flex>
    </>
  );
};
