import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { AccountLoginsListLoginsResponseItem, AccountRole, accountApi } from '@netiva/classifieds-api';
import { useUpdateEffect } from '@netiva/classifieds-common';
import { FormInput, FormSelect, SelectOption } from '@netiva/classifieds-ui';

export type EditLoginModalProps = Omit<ModalProps, 'children'> & {
  login?: AccountLoginsListLoginsResponseItem;
};

type EditLoginForm = {
  email: string;
  role: AccountRole;
};

export const EditLoginModal: FC<EditLoginModalProps> = ({ login, isOpen, onClose, ...rest }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const isNew = !login?.id;

  const form = useForm<EditLoginForm>({
    values: {
      email: login?.email || '',
      role: login?.role || 'Member',
    },
    defaultValues: { email: '', role: 'Member' },
  });
  useUpdateEffect(() => {
    form.reset();
  }, [login, isOpen, form]);

  const roleOptions = useMemo<SelectOption<AccountRole>[]>(
    () => [
      // { value: 'Guest', label: t('logins.roles.guest') },
      { value: 'Member', label: t('logins.roles.member') },
      { value: 'Admin', label: t('logins.roles.admin') },
    ],
    [t]
  );

  const [addLogin, { isLoading: isAdding }] = accountApi.useAddLogin();
  const [setLoginRole, { isLoading: isSaving }] = accountApi.useSetLoginRole();
  const isLoading = isAdding || isSaving;

  const handleSubmit = async (values: EditLoginForm) => {
    try {
      if (isNew) {
        await addLogin(values).unwrap();
        toast({ status: 'success', description: t('logins.add.success') });
      } else {
        await setLoginRole({ accountLoginsSetLoginRoleRequest: { id: login.id, role: values.role } }).unwrap();
        toast({ status: 'success', description: t('logins.edit.success') });
      }
      onClose();
    } catch (err) {
      console.error(err);
      toast({ status: 'error', description: isNew ? t('logins.add.error') : t('logins.edit.error') });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader>
              {t(isNew ? 'logins.editModal.addTitle' : 'logins.editModal.editTitle')}
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody gap={4}>
              <VStack align="stretch" spacing={4}>
                <FormInput<EditLoginForm>
                  name="email"
                  type="email"
                  label={t('logins.editModal.email')}
                  description={t('logins.editModal.emailDescription')}
                  isRequired={isNew}
                  isReadOnly={!isNew}
                  autoFocus={isNew}
                  validate={(v) => !!v || t('form.validation.required')}
                />
                <FormSelect<EditLoginForm>
                  name="role"
                  label={t('logins.editModal.role')}
                  description={<span dangerouslySetInnerHTML={{ __html: t('logins.editModal.roleDescriptionHtml') }} />}
                  options={roleOptions}
                  isRequired
                  autoFocus={!isNew}
                />
              </VStack>
            </ModalBody>
            <ModalFooter gap={4}>
              <Button colorScheme="neutral" onClick={onClose}>
                {t('ui.buttons.cancel')}
              </Button>
              <Button type="submit" isLoading={isLoading} isDisabled={isLoading}>
                {isNew ? t('ui.buttons.add') : t('ui.buttons.save')}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
