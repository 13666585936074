import {
  AddressSelector,
  CategorySelector,
  ColumnCountSelector,
  DocumentSelector,
  EInseratReview,
  ImageSelector,
  IssueDateSelector,
  LocationTypeahead,
  Payment,
  PlatformSelector,
  Publishing,
  Review,
  StyleSelector,
} from './components';
import type { AdComponentType } from '@/pages/Ad/types';

export const StepComponents: Record<string, AdComponentType> = {
  AddressSelector,
  CategorySelector,
  ColumnCountSelector,
  DocumentSelector,
  EInseratReview,
  IssueDateSelector,
  Payment,
  PlatformSelector,
  Publishing,
  Review,
  StyleSelector,
  SingleImageSelector: (props) => <ImageSelector {...props} />,
  MultiImageSelector: (props) => <ImageSelector multiple {...props} />,
  LocationTypeahead,
};
