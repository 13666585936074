import { Image, ImageProps } from '@chakra-ui/react';
import { FC } from 'react';

export type PlatformIconProps = ImageProps & {
  name?: string;
};

export const PlatformIcon: FC<PlatformIconProps> = ({ name, ...rest }) => {
  return name ? (
    <Image src={`/assets/platforms/${name.toLowerCase()}/icon.svg`} width="16" height="16" fallback={<></>} {...rest} />
  ) : null;
};
