import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './types';
import { api } from '@netiva/classifieds-api';

export * from './types';

const initialState: GlobalState = {
  platforms: [],
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    setPageTitle: (state, { payload }: PayloadAction<string | undefined>) => {
      state.pageTitle = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getV1ConfigurationPlatforms.matchFulfilled, (state, { payload }) => {
      state.platforms = payload.items;
    });
  },
});

export const globalActions = globalSlice.actions;
