import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { AddressRec } from '@netiva/classifieds-api';

import { Address } from './Address';

export type AddressTypeSectionProps = {
  label: string;
  addresses: AddressRec[];
  onEdit: (address: AddressRec) => void;
  onCreate: () => void;
};

export const AddressTypeSection: FC<AddressTypeSectionProps> = ({ addresses, label, onEdit, onCreate }) => {
  const { t } = useTranslation();

  return (
    <AccordionItem>
      <AccordionButton>
        <Text fontWeight="bold">{label}</Text>
        <Badge ml={2} fontSize="md">
          {addresses.length}
        </Badge>
        <AccordionIcon marginLeft="auto" />
      </AccordionButton>
      <AccordionPanel>
        <Button onClick={onCreate} marginBottom={2} colorScheme="secondary">
          {t('addresses.newAddress')}
        </Button>

        <Flex gap={8} wrap="wrap">
          {addresses.map((address) => (
            <Address key={address.id} address={address} onEdit={() => onEdit(address)} />
          ))}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
};
