import { FC } from 'react';
import { Badge, Box, Flex, Heading, Icon, Text, chakra } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { RxComponentPlaceholder } from 'react-icons/rx';
import { FaCheckCircle, FaCopy, FaEdit, FaTimesCircle, FaTrashAlt } from 'react-icons/fa';
import { AccountDataObjectListDataObjectsResponseItem } from '@netiva/classifieds-api';

import { useDate } from '@/hooks';
import { routes } from '@/lib/routes';
import { CommonStepKeys } from '@/pages/Ad/constants';
import { AdCard, AdCardContent, AdCardImage, AdCardLink } from './AdCard';
import { ActionButton } from '../../ActionButton';
import { StatusBadge } from '../../StatusBadge';
import { AdListItemActionFunc } from '../../../types';
import { VerificationStatus } from '../../VerificationStatus';
import { TopListingStatus } from '../../TopListingStatus';

type AdItem = AccountDataObjectListDataObjectsResponseItem;

export interface AdProps {
  item: AdItem;
  onActionExecuted: AdListItemActionFunc;
}

const StatusIcons = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    position: 'absolute',
    top: 1,
    width: 'full',
  },
});
const StatusIconsInner = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    gap: 1,
    backgroundColor: 'white',
    borderRadius: 'default',
  },
});

export const AdGridItem: FC<AdProps> = ({ item, onActionExecuted }) => {
  const { formatDate } = useDate();
  const { t } = useTranslation();

  const handleEditClick = (e: React.MouseEvent) => {
    if (item.allowEdit) {
      return;
    }

    e.preventDefault();
    onActionExecuted(item, 'showUneditableModal');
  };

  return (
    <AdCard key={item.id}>
      <AdCardLink to={item.allowEdit ? routes.ad(item.id, CommonStepKeys.edit) : '#'} onClick={handleEditClick}>
        <StatusBadge status={item.status} />
        <StatusIcons>
          <StatusIconsInner>
            {item.status === 'Active' && <VerificationStatus status={item.addressStatus} />}
            <TopListingStatus isTopListed={item.isTopListed} />
          </StatusIconsInner>
        </StatusIcons>
        {item.imageUrl ? (
          <AdCardImage src={item.imageUrl} alt={item.title} />
        ) : (
          <Flex height={150} flexShrink={0} marginBottom={2} justify="center" align="center">
            <Icon as={RxComponentPlaceholder} boxSize={12} />
          </Flex>
        )}
        <AdCardContent>
          <Heading as="h3" size="md" mb={4} wordBreak="break-all">
            {item.title}
          </Heading>
          <Badge colorScheme="secondary" fontSize="sm" mb={4} whiteSpace="normal">
            {item.category}
          </Badge>
          <Text fontSize="sm">{formatDate(item.created)}</Text>
        </AdCardContent>
        {item.allowEdit && (
          <Box
            position="absolute"
            top={3}
            right={3}
            overflow="hidden"
            p={4}
            border="default"
            borderRadius="full"
            background="neutral.50"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Icon as={FaEdit} />
          </Box>
        )}
      </AdCardLink>
      <Flex justify="center" align="center" gap={4} paddingBottom={2}>
        {item.allowDelete && (
          <ActionButton
            iconType={FaTrashAlt}
            label={t('myads.actions.delete')}
            onClick={() => onActionExecuted(item, 'delete')}
            hideLabel
          />
        )}
        {item.allowEnable && (
          <ActionButton
            iconType={FaCheckCircle}
            label={t('myads.actions.activate')}
            onClick={() => onActionExecuted(item, 'enable')}
            hideLabel
          />
        )}
        {item.allowDisable && (
          <ActionButton
            iconType={FaTimesCircle}
            label={t('myads.actions.deactivate')}
            onClick={() => onActionExecuted(item, 'disable')}
            hideLabel
          />
        )}
        <ActionButton
          iconType={FaCopy}
          label={t('myads.actions.duplicate')}
          onClick={() => onActionExecuted(item, 'duplicate')}
          hideLabel
        />
      </Flex>
    </AdCard>
  );
};
