import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

export const FooterParts = ['container'] as const;

export const FooterComponentName = 'Footer' as const;

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(FooterParts);

const baseStyleContainer = defineStyle({
  bg: 'surface',
  marginTop: 'auto',
  p: 4,
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
});

export const Footer = defineMultiStyleConfig({
  baseStyle,
});
