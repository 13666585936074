import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { isAfter } from 'date-fns';
import { Loader } from '@netiva/classifieds-ui';

import { ProductGroups } from '@/constants';
import { normalizeProductGroupName, useContracts } from '@/hooks';
import { ContractListItem, CreateModal, UpgradeModal } from './components';
import { Contract } from './types';
import { canCreate } from './utils';

export const ProductsPage: FC = () => {
  const { t } = useTranslation();

  const [selectedContract, setSelectedContract] = useState<Contract>();
  const createModalDisclosure = useDisclosure({ onClose: () => setSelectedContract(undefined) });
  const upgradeModalDisclosure = useDisclosure({ onClose: () => setSelectedContract(undefined) });

  const { contracts, products, isLoading } = useContracts();
  const visibleContracts = useMemo(
    () =>
      contracts.filter((c) => {
        return (
          !c.product?.isHidden &&
          c.product?.group &&
          normalizeProductGroupName(c.product?.group).startsWith(normalizeProductGroupName(ProductGroups.MemberPrefix))
        );
      }),
    [contracts]
  );
  const activeContracts = useMemo(
    () =>
      visibleContracts.filter(
        (x) => x.status !== 'Suspended' && (!x.endDate || isAfter(new Date(x.endDate), new Date()))
      ),
    [visibleContracts]
  );
  const futureContracts = useMemo(
    () => activeContracts.filter((x) => isAfter(new Date(x.startDate), new Date())),
    [activeContracts]
  );
  const pastContracts = useMemo(
    () => visibleContracts.filter((x) => !activeContracts.includes(x)),
    [activeContracts, visibleContracts]
  );

  const creatableProducts = useMemo(
    () => products.filter((p) => canCreate(p, activeContracts)),
    [activeContracts, products]
  );

  const showCreateModal = () => {
    createModalDisclosure.onOpen();
  };

  const showUpgradeModal = (contract: Contract) => {
    setSelectedContract(contract);
    upgradeModalDisclosure.onOpen();
  };

  return (
    <Box position="relative">
      <Loader isLoading={isLoading} />

      <Tabs isLazy>
        <TabList>
          <Tab>{t('products.active.title')}</Tab>
          <Tab>{t('products.past.title')}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <VStack align="stretch" spacing={4} divider={<StackDivider />}>
              {!isLoading && !activeContracts.length ? <Text>{t('products.emptyList')}</Text> : null}
              {creatableProducts.length > 0 && (
                <Button onClick={showCreateModal} alignSelf="flex-start">
                  {t('products.create')}
                </Button>
              )}
              {activeContracts.map((c) => (
                <ContractListItem
                  key={c.id}
                  contract={c}
                  products={products}
                  futureContracts={futureContracts}
                  onUpgrade={showUpgradeModal}
                />
              ))}
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack align="stretch" spacing={4} divider={<StackDivider />}>
              {!isLoading && !pastContracts.length ? <Text>{t('products.emptyList')}</Text> : null}
              {pastContracts.map((c) => (
                <ContractListItem key={c.id} contract={c} products={products} />
              ))}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <CreateModal {...createModalDisclosure} products={creatableProducts} />
      <UpgradeModal {...upgradeModalDisclosure} contract={selectedContract} products={products} />
    </Box>
  );
};
