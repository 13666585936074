import { FunctionComponent } from 'react';

import { DescriptionListItem, DescriptionListTerm } from '@netiva/classifieds-ui';

export type ItemValue = string | null | undefined;

export type ItemProps = {
  label: string;
  value: ItemValue | ItemValue[];
};

export const Item: FunctionComponent<ItemProps> = ({ label, value }) => {
  const parsedValue = typeof value === 'string' ? value : value?.join(' ')?.trim();

  if (!parsedValue) {
    return null;
  }

  return (
    <>
      <DescriptionListTerm>{label}</DescriptionListTerm>
      <DescriptionListItem>{parsedValue}</DescriptionListItem>
    </>
  );
};
