import { FC } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';

import { AdAttribute, AdStepItem } from '@/store/ad/types';
import { AttributeInput } from './components';

export type AttributeFormControlProps = {
  attribute: AdAttribute;
  stepItem: AdStepItem;
};

export const AttributeFormControl: FC<AttributeFormControlProps> = ({ attribute }) => {
  if (!attribute) return null;

  const showLabel = attribute.type !== 'Boolean';

  return (
    <FormControl isRequired={attribute.mandatory} my={2}>
      {showLabel && <FormLabel>{attribute.name}</FormLabel>}
      <AttributeInput attribute={attribute} />
    </FormControl>
  );
};
