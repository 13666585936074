import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Text } from '@chakra-ui/react';

import { useDate } from '@/hooks';
import { canUpgradeTo } from '../utils';
import { Contract, Product } from '../types';

export type ContractListItemProps = {
  contract: Contract;
  futureContracts?: Contract[];
  products: Product[];
  onUpgrade?: (contract: Contract) => void;
};

export const ContractListItem: FC<ContractListItemProps> = ({ contract, products, futureContracts, onUpgrade }) => {
  const { t } = useTranslation();
  const { formatRange } = useDate();

  const dates = useMemo(() => formatRange(contract.startDate, contract.endDate), [contract, formatRange]);
  const canUpgrade = useMemo(() => {
    return (
      contract.product &&
      products.some((p) => canUpgradeTo(contract.product!, p)) &&
      (!futureContracts || !futureContracts.some((fc) => fc.product?.group === contract.product?.group))
    );
  }, [contract.product, futureContracts, products]);

  return (
    <Flex key={contract.id}>
      <Flex direction="column">
        <Text fontWeight="bold">{contract.product?.name || contract.productId}</Text>
        <Text>{dates}</Text>
      </Flex>

      <Flex direction="column" ml="auto">
        {canUpgrade && onUpgrade ? (
          <Button onClick={() => onUpgrade(contract)}>{t('products.contractListItem.upgrade')}</Button>
        ) : null}
      </Flex>
    </Flex>
  );
};
